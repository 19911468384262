// src/pages/SignIn/index.jsx

import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import { useMutation } from "@apollo/client";
import Icons from "../../components/shared/Icons";
import Layout from "./components/Layout";
import Welcome from "./components/steps/Welcome";
import EmailVerification from "./components/steps/EmailVerification";
import TwoFactor from "./components/steps/TwoFactor";
import { useMetamaskAuth } from "../../myHooks/useMetamaskAuth";
import { useCookies } from "react-cookie";
import config from "../../config";
import { usePasskey } from '../../myHooks/usePasskey'
import { toaster } from '../../utils'
import { useEmailVerification } from "../../myHooks/useEmailVerification";
import {
  ModalWide,
  DummyConnectCoinBase,
} from "../../components/shared/Modals";
import { UseIsMax480 } from '../../components/shared/MediaQueries'
import Web3 from 'web3';

// eslint-disable-next-line
import { useAppKit, useAppKitAccount, useAppKitProvider, useDisconnect } from '@reown/appkit/react'
import { providers } from 'ethers'
import { useCoinbaseAuth } from "../../myHooks/useCoinbaseAuth"
import { SINGPASS_LOGIN } from "../../queriesAndMutations";
import { getNetworkName } from "../../myHooks/useWeb3Provider";

const STEPS = {
  WELCOME: "welcome",
  EMAIL_VERIFICATION: "email-verification",
  TWO_FACTOR: "two-factor",
};

const SignIn = () => {
  const { t } = useTranslation()
  const isMobile = UseIsMax480()

  const [currentStep, setCurrentStep] = useState(STEPS.WELCOME);
  const [signInMethod, setSignInMethod] = useState(null);

  // Modal states
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [isCoinbaseModalOpen, setIsCoinbaseModalOpen] = useState(false);
  const { connect, loginWithSignature, metamaskLogin, requestNonce } = useMetamaskAuth();
  const [, setCookie] = useCookies();
  const { authenticateWithPasskey } = usePasskey();
  const { verifyCode, sendVerificationCode, loading: isLoading, checkUser } = useEmailVerification(false);
  const [emailCode, setEmailCode] = useState(null);
  const [wcInfoModalOpen, setWcInfoModalOpen] = useState(null);
  const { open: openAppKitModal } = useAppKit()
  const { address: wcAddress, isConnected: isWcConnected } = useAppKitAccount()
  const { walletProvider } = useAppKitProvider('eip155')
  const { disconnect } = useDisconnect();
  const [wcSignature, setWcSignature] = useState(null);
  const { connectWallet, loginWithSignature: coinbaseLogin } = useCoinbaseAuth()

  // Step navigation handlers
  const handleBack = () => {
    switch (currentStep) {
      case STEPS.EMAIL_VERIFICATION:
        setCurrentStep(STEPS.WELCOME);
        break;
      case STEPS.TWO_FACTOR:
        setCurrentStep(STEPS.WELCOME);
        break;
      default:
        break;
    }
  };

  async function wcSignAndLogin(twoFaToken) {
    try {
      // Login with signature
      const { data: loginData } = await metamaskLogin({
        variables: {
          input: {
            address: wcAddress,
            signature: wcSignature,
            twoFaToken,
            loginProvider: 'walletconnect'
          }
        }
      });

      return loginData;
    } catch (error) {
      console.log(error)
      disconnect();
      // toaster.error(error.message);
      return null;
    }
  }

  // Handle continue button click based on current step
  const handleContinue = async (method, data) => {
    console.log("handleContinue", method, data);

    switch (currentStep) {
      case STEPS.WELCOME:
        if (method === "email") {
          setSignInMethod({ type: "email", data });
          const result = await sendVerificationCode(data.email);
          console.log("result", result);
          if (result) {
            setCurrentStep(STEPS.EMAIL_VERIFICATION);
          }
        } else if (method === "wallet") {
          setSignInMethod({ type: "wallet" });
          setIsWalletModalOpen(true);
        } else if (method === "passkey") {
          const result = await authenticateWithPasskey();

          if (result.verified) {
            setCookie(config.jwtCookie, result.token.accessToken, { path: '/' })
            localStorage.setItem('email', result.user.email)

            window.location.href = "/dashboard";
          } else {
            toaster.error('Authentication failed.');
          }
        } else if (method === "singpass") {
          window.location.href = "/dashboard";
        }
        break;
      case STEPS.EMAIL_VERIFICATION:
        try {
          console.log("verifyCode", data);
          const result = await verifyCode(signInMethod.data.email, data.emailCode, data.twoFaToken);
          if (result.requireTwoFactor) {
            setEmailCode(data.emailCode);
            setCurrentStep(STEPS.TWO_FACTOR, data);
          } else {
            setCookie(config.jwtCookie, result.token.accessToken, { path: '/' });
            window.location.href = "/dashboard";
          }
        } catch (error) {
          toaster.error('Failed to verify code');
        }
        break;
      case STEPS.TWO_FACTOR:
        if (signInMethod.type === "wallet" && signInMethod.walletType === "metamask") {
          console.log("loginWithSignature", data.twoFaToken);
          const result = await loginWithSignature(data.twoFaToken);
          setCookie(config.jwtCookie, result.metamaskLogin.accessToken, {
            path: "/",
          });
          window.location.href = "/dashboard";
        }

        if (signInMethod.type === "wallet" && signInMethod.walletType === "coinbase") {
          console.log("coinbaseLogin", data.twoFaToken);
          const result = await coinbaseLogin(data.twoFaToken);
          setCookie(config.jwtCookie, result.metamaskLogin.accessToken, {
            path: "/",
          });
          window.location.href = "/dashboard";
        }

        if (signInMethod.type === "wallet" && signInMethod.walletType === "wallet-connect") {
          console.log("loginWithSignature", data.twoFaToken);
          const result = await wcSignAndLogin(data.twoFaToken);
          setCookie(config.jwtCookie, result.metamaskLogin.accessToken, {
            path: "/",
          });
          window.location.href = "/dashboard";
        }

        if (signInMethod.type === "email") {
          const result = await verifyCode(signInMethod.data.email, emailCode, data.twoFaToken);

          setCookie(config.jwtCookie, result.token.accessToken, { path: '/' });
          window.location.href = "/dashboard";
        }
        break;
      default:
        break;
    }
  };

  const handleMetamaskLogin = async () => {
    try {
      const { account } = await connect();

      const { data: existsData } = await checkUser({
        variables: { wallet: account }
      });

      if (existsData && !existsData.checkUserExists) {
        toaster.error('Wallet not exists. Please sign up first');
        return;
      }

      setSignInMethod({
        type: "wallet",
        walletType: "metamask",
        address: account
      });
      setCurrentStep(STEPS.TWO_FACTOR);
    } catch (err) {
      console.error("Login failed:", err);
    }
  };

  const handleCoinbaseLogin = async () => {
    try {
      console.log('handleCoinbaseLogin');

      const { address } = await connectWallet();

      const { data: existsData } = await checkUser({
        variables: { wallet: address }
      });

      if (existsData && !existsData.checkUserExists) {
        toaster.error('Wallet not exists. Please sign up first');
        return;
      }

      setSignInMethod({
        type: "wallet",
        walletType: "coinbase",
        address: address
      });
      setCurrentStep(STEPS.TWO_FACTOR);
    } catch (err) {
      console.error("Login failed:", err);
    }
  };

  const handleWalletConnectLogin = async () => {
    try {
      if (!isWcConnected) {
        toaster.error('User disconnected');
        return
      }

      setWcInfoModalOpen(true);

      const ethersProvider = new providers.Web3Provider(walletProvider)

      console.log('wcAddress', wcAddress);

      const { data: nonceData } = await requestNonce({
        variables: { address: wcAddress }
      });

      // Create message to sign
      const message = `Welcome to Investhub.io!\n\nTo access features related to security tokens, simply click the "Sign" button—no username or password is required, and this action will not incur any gas fees.\n\nBy clicking "Sign," you agree to Investhub Genossenschaft LVC's Terms and Conditions and confirm that you have read and understood their Privacy Policy.\n\nURI:\napp.investhub.io\n\nNonce: ${nonceData.requestMetamaskNonce}`;
      const hexMessage = `0x${Buffer.from(message, "utf8").toString("hex")}`

      let isGnosisSafe = false;
      let safePopup = null;

      const web3 = new Web3(walletProvider);
      const code = await web3.eth.getCode(wcAddress)
      const chainId = await web3.eth.getChainId()

      const network = getNetworkName(chainId)

      if (code !== '0x') {
        isGnosisSafe = true;
      }

      if (isGnosisSafe) {
        const safeUrl = `https://app.safe.global/home?safe=${network}:${wcAddress}`
        safePopup = window.open(safeUrl, 'SafeTransaction', 'width=600,height=800');
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }

      // Request signature from user
      const signature = await ethersProvider.provider.request({
        method: 'personal_sign',
        params: [
          hexMessage,
          wcAddress,
        ]
      });

      console.log('signature', signature);

      if (safePopup) {
        safePopup.close();
      }

      if (!signature) {
        toaster.error('Personal sign failed');
        return
      }

      const { data: existsData } = await checkUser({
        variables: { wallet: wcAddress }
      });

      if (existsData && !existsData.checkUserExists) {
        toaster.error('Wallet not exists. Please sign up first');
        disconnect();
        return;
      }

      setWcSignature(signature);
      setWcInfoModalOpen(false);
      setIsWalletModalOpen(false);
      setSignInMethod({ type: "wallet", walletType: "wallet-connect" });
      setCurrentStep(STEPS.TWO_FACTOR);
    } catch (err) {
      console.error("Login failed:", err);
      setWcInfoModalOpen(false);
      disconnect();
    }
  };

  // Modal handlers
  const handleWalletConnect = (walletType) => {
    setIsWalletModalOpen(false);
    if (walletType === "metamask") {
      handleMetamaskLogin();
    } else if (walletType === "coinbase") {
      handleCoinbaseLogin();
    } else {
      handleWalletConnectLogin();
    }
  };

  const [isFetchingSingpass, setIsFetchingSingpass] = useState(false);
  const [singpassLogin] = useMutation(SINGPASS_LOGIN);

  useEffect(() => {
    const handleSingpassCallback = async (receivedQueryParams) => {
      try {
        setIsFetchingSingpass(true);

        const { data } = await singpassLogin({
          variables: {
            input: {
              receivedQueryParams,
              nonce: localStorage.getItem('singpass_nonce'),
            }
          }
        });

        const { accessToken, refreshToken } = data.singpassLogin;
        console.log('accessToken', accessToken);
        console.log('refreshToken', refreshToken);

        if (accessToken && refreshToken) {
          setCookie(config.jwtCookie, accessToken, { path: '/' })

          // Handle the mapped data for signup flow
          handleContinue('singpass', {
            accessToken,
            refreshToken,
          });
        }
      } catch (error) {
        console.error('Error fetching singpass callback:', error);
        toaster.error(error.message);
      } finally {
        setIsFetchingSingpass(false);
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const singpass = urlParams.get('singpass');

    if (singpass) {
      // get url query params except singpass
      const receivedQueryParams = {};
      urlParams.forEach((value, key) => {
        if (key !== 'singpass') {
          receivedQueryParams[key] = value;
        }
      });

      console.log('receivedQueryParams', receivedQueryParams);
      handleSingpassCallback(receivedQueryParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singpassLogin]);

  useEffect(() => {
    if (wcAddress && !wcSignature) {
      handleWalletConnectLogin();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wcAddress, wcSignature])

  // Determine title and description based on current step
  const getStepContent = () => {
    switch (currentStep) {
      case STEPS.WELCOME:
        return {
          title: "Welcome Back",
          description:
            "Enter the associated account with your Investhub account",
        };
      case STEPS.EMAIL_VERIFICATION:
        return {
          title: "Email Verification",
          description: "Enter the verification code sent to your email",
        };
      case STEPS.TWO_FACTOR:
        return {
          title: "Two-Factor Authentication",
          description:
            "Enter the verification code from your authenticator app",
        };
      default:
        return {
          title: "",
          description: "",
        };
    }
  };

  const { title, description } = getStepContent();

  console.log("signInMethod", signInMethod);
  console.log("isWcConnected", isWcConnected)

  const baseButtonClass = `
    pw:inline-flex pw:justify-center pw:items-center
    pw:bg-black-light hover:pw:bg-gray-lighter hover:pw:opacity-90
    pw:transition-colors pw:duration-[0.2s]
    pw:font-medium pw:text-white pw:cursor-pointer
  `

  const modalButtonClass = `
    ${baseButtonClass}
    pw:shadow-[#6F737E_0_0_0_1px_inset]
    pw:m-0 pw:px-6 pw:rounded-[40px]
    pw:w-full pw:max-767:w-[initial] pw:!h-12
    pw:text-sm
  `

  return (
    <Layout
      title={title}
      description={description}
      onBack={currentStep !== STEPS.WELCOME ? handleBack : undefined}
    >
      {currentStep === STEPS.WELCOME && <Welcome onContinue={handleContinue} isLoading={isLoading} />}

      {currentStep === STEPS.EMAIL_VERIFICATION && (
        <EmailVerification
          onContinue={(data) => handleContinue("email-verification", data)}
          email={((signInMethod || {}).data || {}).email}
          setEmailCode={setEmailCode}
        />
      )}

      {currentStep === STEPS.TWO_FACTOR && (
        <TwoFactor
          onContinue={(data) => handleContinue("2fa", data)}
          email={((signInMethod || {}).data || {}).email}
          walletAddress={(signInMethod && signInMethod.address) || wcAddress} // Add wallet address
          signInMethod={signInMethod} // Pass signin method
        />
      )}

      {/* Wallet Connection Modal */}
      <ModalWide
        isOpen={isWalletModalOpen}
        onClose={() => setIsWalletModalOpen(false)}
        title="Connect a Wallet"
        subtitle="Choose your wallet to sign in"
      >
        { isWcConnected && wcInfoModalOpen && (
          <>
            <div className="pw:max-767:flex pw:max-767:flex-row pw:max-767:flex-wrap pw:justify-center pw:max-767:justify-center pw:items-stretch pw:max-767:items-center pw:gap-x-4 pw:gap-y-4 pw:mx-auto pw:my-0 pw:p-0 pw:px-2 pw:border-0 pw:align-baseline">
              <h1 className="pw:m-0 pw:font-semibold pw:text-[28px] pw:text-white pw:text-center pw:leading-9 pw:transition-[color] pw:duration-[0.2s] pw:ease-[ease-out]">
                {t('Please sign the message in wallet app!')}
              </h1>
            </div>

            <div className='mx-auto mt-4 pw:max-w-72'>
              <button className={modalButtonClass} onClick={disconnect}>
                <Icons
                  nameIcon="walletconnect"
                  className="pw:mr-2 pw:rounded-full pw:w-6 pw:h-6"
                />
                <span className="pw:inline-block">{t('Disconnect')}</span>
              </button>
            </div>

            <div className="pw:mt-6 pw:w-full pw:text-[13px] pw:text-gray-lighter pw:text-center">
              {t('Need help?')}{' '}
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
              <a href="#" className="hover:pw:opacity-80 pw:font-semibold pw:text-white pw:transition-opacity pw:duration-[0.2s]">{t('Click here')}</a>.
            </div>
          </>
        )}

        { (!isWcConnected || !wcInfoModalOpen) && (
          <div className="max-767:flex max-767:flex-row max-767:flex-wrap justify-center max-767:justify-center items-stretch max-767:items-center gap-x-4 gap-y-4 grid grid-cols-[1fr_1fr_1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto my-0 p-0 px-2 border-0 align-baseline">
            { !isMobile && (
              <button
                onClick={() => handleWalletConnect("metamask")}
                className="inline-flex justify-center items-center bg-black-light hover:bg-gray-lighter shadow-[#6F737E_0_0_0_1px_inset] m-0 px-6 rounded-[40px] w-full max-767:w-[initial] !h-12 overflow-visible font-medium text-[100%] text-white text-sm normal-case leading-none transition-colors duration-[0.2s] backface-visibility-visible cursor-pointer"
              >
                <Icons nameIcon="metamask" className="mr-2 rounded-full w-6 h-6" />
                <span className="inline-block">MetaMask</span>
              </button>
            )}

            <button
              onClick={() => {
                if (isWcConnected) {
                  handleWalletConnect("walletconnect");
                } else {
                  openAppKitModal({
                    enableExplorer: false,
                  })
                }
              }}
              className="inline-flex justify-center items-center bg-black-light hover:bg-gray-lighter shadow-[#6F737E_0_0_0_1px_inset] m-0 px-6 rounded-[40px] w-full max-767:w-[initial] !h-12 overflow-visible font-medium text-[100%] text-white text-sm normal-case leading-none transition-colors duration-[0.2s] backface-visibility-visible cursor-pointer"
            >
              <Icons
                nameIcon="walletconnect"
                className="mr-2 rounded-full w-6 h-6"
              />
              <span className="inline-block">WalletConnect</span>
            </button>
            <button
              onClick={() => handleWalletConnect("coinbase")}
              className="inline-flex justify-center items-center bg-black-light hover:bg-gray-lighter shadow-[#6F737E_0_0_0_1px_inset] m-0 px-6 rounded-[40px] w-full max-767:w-[initial] !h-12 overflow-visible font-medium text-[100%] text-white text-sm normal-case leading-none transition-colors duration-[0.2s] backface-visibility-visible cursor-pointer"
            >
              <Icons nameIcon="coinbase" className="mr-2 rounded-full w-6 h-6" />
              <span className="inline-block">Coinbase</span>
            </button>
          </div>
        )}
      </ModalWide>

      <DummyConnectCoinBase
        isOpenModal={isCoinbaseModalOpen}
        toggleModal={() => setIsCoinbaseModalOpen(!isCoinbaseModalOpen)}
      />
    </Layout>
  );
};

export default SignIn;
