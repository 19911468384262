/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from "react";
import Avatar from 'react-avatar';
import tokens from "../../../../../services/listDataToken"

const Buy = ({ buyDetails, tokenBalances }) => {
  const getTokenImage = (tokenSymbol) => {
    const token = tokenBalances.find(token => token.symbol.toLowerCase() === tokenSymbol.toLowerCase());
    return token ? token.image : null;
  };

  const getNetworkImage = (network) => {
    try {
      const networks = {
        'polygon': require('../../../../../assets/images/tokenSymbol/polygon.png'),
        'base': require('../../../../../assets/images/tokenSymbol/base.svg'),
        'ethereum': require('../../../../../assets/images/tokenSymbol/ethereum.png'),
        'mainnet': require('../../../../../assets/images/tokenSymbol/ethereum.png'),
      };
      return networks[network] || null;
    } catch (err) {
      return null;
    }
  };

  const NetworkIcon = ({ network }) => {
    console.log('network', network);
    const imgSrc = getNetworkImage(network);
    console.log('imgSrc', imgSrc);
    if (!imgSrc) {
      return (
        <Avatar
          name={network}
          size="12"
          round={true}
          className="right-[-2px] bottom-[-2px] z-10 absolute"
        />
      );
    }

    return (
      <img
        src={imgSrc.default || imgSrc}
        alt={network}
        className="right-[-2px] bottom-[-2px] z-10 absolute rounded-full w-4 h-4"
        onError={(e) => {
          e.target.onerror = null;
          e.target.style.display = 'none';
          const avatarEl = document.createElement('div');
          avatarEl.className = "right-[-2px] bottom-[-2px] z-10 absolute";
          const avatar = document.createElement('div');
          avatarEl.appendChild(avatar);
          e.target.parentNode.appendChild(avatarEl);

          // const root = createRoot(avatar);
          // root.render(
          //   <Avatar
          //     name={network}
          //     size="12"
          //     round={true}
          //   />
          // );
        }}
      />
    );
  };

  const getImage = (type, imgSrc, name) => {
    try {
      // Handle null/undefined src
      if (!imgSrc) {
        return (
          <Avatar
            name={name}
            size="40"
            round={true}
            className="relative rounded-full w-full h-full object-cover"
          />
        );
      }

      // Check if src contains default broken image path
      if (imgSrc.includes('default-icon-day-v3')) {
        return (
          <Avatar
            name={name}
            size="40"
            round={true}
            className="relative rounded-full w-full h-full object-cover"
          />
        );
      }

      return (
        <img
          src={imgSrc}
          alt={name}
          className="w-full h-full object-cover"
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = 'none';
            const avatar = <Avatar
              name={name}
              size="40"
              round={true}
              className="relative rounded-full w-full h-full object-cover"
            />;
            e.target.parentNode.appendChild(avatar);
          }}
        />
      );
    } catch (err) {
      return (
        <Avatar
          name={name}
          size="40"
          round={true}
          className="relative rounded-full w-full h-full object-cover"
        />
      );
    }
  };

  // const getImageUrl = (type, imageName) => {
  //   try {
  //     return require(`../../../../../assets/images/${type}/${imageName}`);
  //   } catch (err) {
  //     return require(`../../../../../assets/images/issuer/default-icon-day-v3.svg`)
  //   }
  // }

  return (
    <div className="relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-transparent before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none">
      <div className="text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">
        Buy
      </div>

      <div className="flex flex-row flex-wrap justify-between items-center mt-2">
        <div className="flex flex-col flex-wrap flex-1 justify-start items-start">
          <div className="relative flex-auto bg-transparent opacity-100 p-0 border-none w-full max-h-11 text-4xl text-ellipsis text-left text-white placeholder:text-[#6F737E] tracking-[-0.02em] transition-opacity duration-[250ms] overflow-hidden pointer-events-auto ease-[ease-in-out] outline-none">
            {+buyDetails.amountOut.toFixed(6)} {buyDetails.to.symbol.toUpperCase()}
          </div>

          <div className="mt-[6px] text-[13px] text-white/70 leading-[1.2] tracking-[-0.02em]">
            -
          </div>
        </div>

        <div className="flex flex-col justify-start items-start">
          <div className="relative mb-6 rounded-full w-10 h-10">
            {getImage('tokenSymbol', buyDetails.to.image, buyDetails.to.symbol.toUpperCase())}
            <NetworkIcon network={buyDetails.to.network} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buy;
