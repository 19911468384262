// Deprecated
import React from 'react'
// Shared
import Icons from '../../../shared/Icons'

const PageTitle = ({ title, description }) => {
  return (
    <div className="pt-4 pb-6 w-full">
      {title && (
        <div className="relative m-0 p-0 border-0 font-sans font-semibold text-white max-767:text-[2rem] text-4xl align-baseline leading-[1.2] tracking-[-0.02em]">{title}</div>
      )}

      {description && (
        <div className="mt-2 p-0 border-0 text-[#6f767e] align-baseline">{description}</div>
      )}
    </div>
  )
}

export default PageTitle
