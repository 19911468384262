import React from 'react'
import Avatar from 'react-avatar'

const Issuer = ({
  imgSrc, issuerName, issuerText, issuerNetwork, onClick, coinName, networkIconElement
}) => {
  return (
    <div
      className="flex flex-row flex-wrap justify-start items-center gap-x-3 gap-y-3 hover:opacity-60 m-0 px-0 py-2 border-0 rounded-2xl transition-all duration-[0.2s] cursor-pointer"
      onClick={onClick}
    >
      <div className="m-0 p-0 border-0 rounded-[50%] w-10 h-10 relative align-baseline">
        {imgSrc ? (
          <img src={imgSrc} alt={issuerName} className="w-full h-full object-cover" />
        ) : (
          <Avatar name={coinName} size="40" round={true} />
        )}

        <span className='inline-flex right-0 bottom-0 z-10 absolute rounded-full w-4 h-4'>
          {networkIconElement}
        </span>
      </div>

      <div className="flex flex-col flex-wrap flex-[1_1_0] justify-start items-start m-0 p-0 border-0 font-semibold text-white align-baseline">
        <span className="flex-1 font-sans">{issuerName}</span>
        <span className="m-0 p-0 border-0 font-[600] font-sans text-[13px] text-gray-lighter text-style-1lines align-baseline">{issuerText}</span>
      </div>
    </div>
  )
}

export default Issuer
