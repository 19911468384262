import React from "react";
import PageHeading from "../../../../components/layouts/InstantInvestment/PageTitle";

// Inner
import Buy from "./Buy";
import Sell from "./Sell";
import Processing from "./Processing";

const StepReviewProcessOrder = ({ setStep, buyDetails, tokenBalances, maxSlippage }) => {
  return (
    <>
      <Sell buyDetails={buyDetails} tokenBalances={tokenBalances} />
      <div className="w-full h-4" />
      <Buy buyDetails={buyDetails} tokenBalances={tokenBalances} />
      <div className="w-full h-4" />

      <div className="w-full h-4" />
      <Processing setStep={setStep} buyDetails={buyDetails} tokenBalances={tokenBalances} maxSlippage={maxSlippage} />
    </>
  );
};

export default StepReviewProcessOrder;
