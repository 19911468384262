import React from 'react'
import { motion } from 'framer-motion'
import Skeleton from "react-loading-skeleton"

const MyAssets = ({ totalValue, loading }) => {
  return (
    <div className="flex flex-col justify-start items-start gap-1 w-full">
      {loading ? (
        <Skeleton height={32} width={120} className="!bg-gray-custom/25 opacity-25 rounded-xl" />
      ) : (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <div className="m-0 font-sans font-extrabold text-white text-4xl leading-[44px] tracking-[-0.01em]">
            {(+totalValue).toFixed(2)} USD
          </div>
        </motion.div>
      )}
    </div>
  )
}

export default MyAssets
