/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { useQuery, useLazyQuery } from '@apollo/client';
import cn from "classnames"
import { GET_USER_WALLETS, GET_TOKEN_BALANCES_BY_ADDRESS, GET_HISTORICAL_PRICE } from '../../../queriesAndMutations';
import { createRoot } from 'react-dom/client';
import Avatar from 'react-avatar';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// import VolumeFilter from '../../DashboardTokens/VolumeFilter';
import AddWallet from '../../DashboardTokens/AddWallet';
import AdvancedFilter from '../../DashboardTokens/AdvancedFilter';
import ViewModeToggle from './ViewModeToggle';
import AssetsTable from "./AssetsTable";
import WalletAssetsView from './WalletAssetsView';
import useViewMode from "./hooks/useViewMode"
import { NETWORK_IMAGES } from '../../../constant/images';
import tokenList from "../../../services/listDataToken";
import styles from './MyAssets.module.scss';

const motionConfig = {
  initial: { opacity: 0, y: 4 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -4 },
  transition: { duration: 0.2 }
};

const MyAssets = ({ setTotalBalance, timeRange }) => {
  const { t } = useTranslation();
  const { viewMode, toggleViewMode, isCombinedView, isByWalletView } = useViewMode();
  const { loading: walletsLoading, data: walletsData } = useQuery(GET_USER_WALLETS);
  const [getTokenBalances] = useLazyQuery(GET_TOKEN_BALANCES_BY_ADDRESS);
  const [getHistoricalPrice] = useLazyQuery(GET_HISTORICAL_PRICE);

  const [assets, setAssets] = useState([]);
  const [assetsChange, setAssetsChange] = useState([]);
  const [walletViews, setWalletViews] = useState([]);
  const [walletViewsChange, setWalletViewsChange] = useState([]);
  const [loading, setLoading] = useState(true);
  const [volumeFilter, setVolumeFilter] = useState(timeRange);

  useEffect(() => {
    setVolumeFilter(timeRange);
  }, [timeRange]);

  const getNetworkName = (chainIndex) => {
    const networks = {
      '137': 'Polygon',
      '8453': 'Base',
      '1': 'Ethereum',
      '11155111': 'Ethereum',
      '56': 'BNB Chain',
      '43114': 'Avalanche C',
      '10': 'Optimism',
      '42161': 'Arbitrum One',
      '100': 'Gnosis',
    };
    return networks[chainIndex] || 'Unknown';
  };

  const getTokenImage = (tokenSymbol) => {
    try {
      const token = tokenList.find(token => token.symbol.toLowerCase() === tokenSymbol.toLowerCase());
      if (!token) return null;

      // Get the actual image file
      const imgSrc = require(`../../../assets/images/tokenSymbol/${token.image}`);
      return imgSrc.default || imgSrc; // Handle both ESM and CommonJS modules
    } catch (err) {
      return NETWORK_IMAGES.defaultToken;
    }
  };

  const getTimeRange = (filter) => {
    const now = Date.now();
    switch (filter) {
      case '1M':
        return {
          begin: now - (30 * 24 * 60 * 60 * 1000), // 30 days ago
          period: '1d'
        };
      case '6M':
        return {
          begin: now - (180 * 24 * 60 * 60 * 1000), // 180 days ago
          period: '1d'
        };
      case '12M':
        return {
          begin: now - (360 * 24 * 60 * 60 * 1000), // 360 days ago
          period: '1d'
        };
      case '24M':
        return {
          begin: now - (720 * 24 * 60 * 60 * 1000), // 720 days ago
          period: '1d'
        };
      default:
        return {
          begin: now - (30 * 24 * 60 * 60 * 1000), // default to 30 days
          period: '1d'
        };
    }
  };

  const calculatePriceChange = async (token) => {
    try {
      const timeRange = getTimeRange(volumeFilter);

      const { data } = await getHistoricalPrice({
        variables: {
          input: {
            tokenAddress: token.contract_address || "",
            chainIndex: parseInt(token.chain_index),
            limit: 1,
            begin: timeRange.begin.toString(),
            end: (timeRange.begin + 24 * 60 * 60 * 1000).toString(),
            period: timeRange.period
          }
        }
      });

      if (data && data.getHistoricalPrice && data.getHistoricalPrice.historicalPrice) {
        const price = data.getHistoricalPrice.historicalPrice;
        if (price) {
          const oldPrice = parseFloat(price);

          // new pric is current price
          const newPrice = token.price;
          return ((newPrice - oldPrice) / oldPrice) * 100;
        }
      }
      return 0;
    } catch (error) {
      console.error('Error fetching historical price:', error);
      return 0;
    }
  };

  const processWalletData = async (wallets, tokenPrices) => {
    // First process stored tokens from DB
    const storedTokens = wallets.reduce((acc, wallet) => {
      wallet.tokens.forEach(token => {
        const key = `${token.symbol.replaceAll(/[\s\W]/g, '')}-${token.chain_index}-${token.contract_address}`;
        acc[key] = {
          entry_price: parseFloat(token.entry_price || 0),
          current_price: parseFloat(token.current_price || 0),
        };
      });
      return acc;
    }, {});

    // Then group tokens by wallet address with real-time prices and stored prices
    const walletGroups = Object.entries(tokenPrices).reduce((acc, [key, data]) => {
      const { wallet_address, price, balance } = data;
      const [symbol, chainIndex, contractAddress] = key.split('-');
      const storedToken = storedTokens[key];

      if (!acc[wallet_address]) {
        acc[wallet_address] = {
          wallet_address,
          totalValue: 0,
          assets: new Map()
        };
      }

      const tokenValue = balance * price;
      acc[wallet_address].assets.set(key, {
        id: key,
        name: symbol,
        symbol,
        chain_index: chainIndex,
        contract_address: contractAddress,
        icon: getTokenImage(symbol),
        balance: {
          amount: tokenValue,
          units: `${parseFloat(balance).toFixed(6)} ${symbol}`
        },
        price: {
          entry_price: storedToken ? storedToken.entry_price : 0,
          amount: price,
          change: 0 // Use pre-calculated price change
        },
        hasPrice: price > 0,
        allocation: 0
      });

      if (price > 0) {
        acc[wallet_address].totalValue += tokenValue;
      }

      return acc;
    }, {});

    // Calculate allocations and sort assets for each wallet
    const processedWalletViews = Object.values(walletGroups).map(wallet => {
      const sortedAssets = Array.from(wallet.assets.values())
        .sort((a, b) => {
          if (a.hasPrice && b.hasPrice) {
            return b.balance.amount - a.balance.amount;
          }
          if (a.hasPrice) return -1;
          if (b.hasPrice) return 1;
          return a.name.localeCompare(b.name);
        })
        .map(asset => ({
          ...asset,
          allocation: wallet.totalValue > 0 ? ((asset.balance.amount / wallet.totalValue) * 100) : 0
        }));

      return {
        wallet_address: wallet.wallet_address,
        totalValue: wallet.totalValue,
        assets: sortedAssets
      };
    });

    setWalletViews(processedWalletViews);
  };

  const transformTokensToAssets = async (wallets, tokenPrices) => {
    if (!wallets) return [];

    // Create a map to accumulate token quantities across wallets
    const tokenMap = new Map();

    // First, process stored tokens from all wallets
    wallets.forEach(wallet => {
      wallet.tokens.forEach(token => {
        // replace all spaces with empty string, all special characters with empty string
        const key = `${token.symbol.replaceAll(/[\s\W]/g, '')}-${token.chain_index}-${token.contract_address}`;
        if (!tokenMap.has(key)) {
          tokenMap.set(key, {
            symbol: token.symbol,
            chain_index: token.chain_index,
            contract_address: token.contract_address,
            quantity: 0,
            current_price: parseFloat(token.current_price || 0),
            entry_price: parseFloat(token.entry_price || 0),
            wallet_addresses: new Set(),
            hasPrice: parseFloat(token.current_price || 0) > 0
          });
        }
      });
    });

    // Then, process real-time token balances
    Object.entries(tokenPrices).forEach(([key, priceData]) => {
      const [symbol, chainIndex, contractAddress] = key.split('-');
      const mapKey = `${symbol}-${chainIndex}-${contractAddress}`;

      if (!tokenMap.has(mapKey)) {
        tokenMap.set(mapKey, {
          symbol,
          chain_index: chainIndex,
          contract_address: contractAddress,
          quantity: 0,
          current_price: parseFloat(priceData.price || 0),
          wallet_addresses: new Set(),
          hasPrice: parseFloat(priceData.price || 0) > 0
        });
      }

      const existingToken = tokenMap.get(mapKey);
      existingToken.quantity += parseFloat(priceData.balance || 0);
      existingToken.current_price = parseFloat(priceData.price || existingToken.current_price || 0);
      existingToken.wallet_addresses.add(priceData.wallet_address);
      existingToken.hasPrice = existingToken.hasPrice || parseFloat(priceData.price || 0) > 0;
    });

    // Convert map to array and format for display
    const assetsArray = Array.from(tokenMap.values())
      .map(token => {
        const tokenValue = token.quantity * token.current_price;
        const icon = getTokenImage(token.symbol);

        return {
          id: `${token.symbol.replaceAll(/[\s\W]/g, '')}-${token.chain_index}-${token.contract_address}`,
          name: token.symbol,
          symbol: token.symbol,
          chain_index: token.chain_index,
          contract_address: token.contract_address,
          icon,
          balance: {
            amount: tokenValue,
            units: `${token.quantity.toFixed(6)} ${token.symbol}`
          },
          price: {
            entry_price: token.entry_price,
            amount: token.current_price,
            change: 0 // Will be updated later
          },
          allocation: 0, // Will be calculated after
          wallet_count: token.wallet_addresses.size,
          hasPrice: token.hasPrice
        };
      });

    // Calculate total value and allocations
    const totalValue = assetsArray.reduce((sum, asset) => sum + asset.balance.amount, 0);
    setTotalBalance(totalValue);

    // Sort assets: first by value (if has price), then by name
    const sortedAssets = assetsArray.sort((a, b) => {
      if (a.hasPrice && b.hasPrice) {
        return b.balance.amount - a.balance.amount;
      }
      if (a.hasPrice) return -1;
      if (b.hasPrice) return 1;
      return a.name.localeCompare(b.name);
    });

    return sortedAssets.map(asset => ({
      ...asset,
      allocation: totalValue > 0 ? ((asset.balance.amount / totalValue) * 100) : 0
    }));
  };

  const AssetImage = ({ icon, name }) => (
    <div className="flex-shrink-0 rounded-full w-6 h-6">
      {icon && !String(icon).includes('default-icon-day-v3.svg') ? (
        <img
          src={typeof icon === 'string' ? icon : (icon.default || icon)}
          alt={name}
          className="rounded-full w-full h-full object-cover"
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = 'none';
            const avatar = document.createElement('div');
            e.target.parentNode.appendChild(avatar);
            const root = createRoot(avatar);
            root.render(
              <Avatar
                name={name}
                size="24"
                round={true}
                className="w-full h-full object-cover"
              />
            );
          }}
        />
      ) : (
        <Avatar
          name={name}
          size="24"
          round={true}
          className="w-full h-full object-cover"
        />
      )}
    </div>
  );

  useEffect(() => {
    const fetchTokenPrices = async () => {
      if (!walletsData || !walletsData.getUserWallets) return;

      try {
        setLoading(true);
        const wallets = walletsData.getUserWallets;
        const walletAddresses = [...new Set(wallets.map(w => w.wallet_address))];

        const priceResults = await Promise.all(
          walletAddresses.map(address => getTokenBalances({ variables: { address } }))
        );

        const tokenPrices = {};
        const tokenPricesByAddress = {};

        priceResults.forEach(result => {
          if (result.data && result.data.getTokenBalancesByAddress && result.data.getTokenBalancesByAddress.tokenAssets) {
            result.data.getTokenBalancesByAddress.tokenAssets.forEach(token => {
              const key = `${token.symbol.replaceAll(/[\s\W]/g, '')}-${token.chainIndex}-${token.tokenAddress}`;

              if (!tokenPrices[key]) {
                tokenPrices[key] = {
                  price: parseFloat(token.tokenPrice),
                  balance: parseFloat(token.balance),
                  wallet_address: token.address
                };
              } else {
                tokenPrices[key].balance += parseFloat(token.balance);
              }

              const keyByAddress = `${token.symbol.replaceAll(/[\s\W]/g, '')}-${token.chainIndex}-${token.tokenAddress}-${token.address}`;

              if (!tokenPricesByAddress[keyByAddress]) {
                tokenPricesByAddress[keyByAddress] = {
                  price: parseFloat(token.tokenPrice),
                  balance: parseFloat(token.balance),
                  wallet_address: token.address
                };
              } else {
                tokenPricesByAddress[keyByAddress].balance += parseFloat(token.balance);
              }
            });
          }
        });

        console.log('tokenPrices', JSON.stringify(tokenPrices));

        await processWalletData(wallets, tokenPricesByAddress);
        const transformedAssets = await transformTokensToAssets(wallets, tokenPrices);
        setAssets(transformedAssets);
      } catch (error) {
        console.error('Error fetching token prices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTokenPrices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletsData, getTokenBalances])

  useEffect(() => {
    const updatePriceChanges = async () => {
      if (!assets.length || !walletViews.length) return;

      setLoading(true);
      const updatedAssets = [];

      for (const asset of assets) {
        if (+asset.price.amount !== 0 || +asset.price.entry_price !== 0) {
          await new Promise((resolve) => setTimeout(resolve, 100));

          let priceChange = 0;
          if (asset.price.entry_price) {
            priceChange = ((asset.price.amount - asset.price.entry_price) / asset.price.entry_price) * 100;
          } else {
            priceChange = await calculatePriceChange({
              contract_address: asset.contract_address,
              chain_index: asset.chain_index,
              price: asset.price.amount,
              entry_price: asset.price.entry_price
            });
          }

          updatedAssets.push({
            ...asset,
            price: {
              ...asset.price,
              change: parseFloat(priceChange.toFixed(2))
            }
          });
        } else {
          updatedAssets.push(asset);
        }
      }

      // Create a map of price changes from updatedAssets
      const priceChanges = updatedAssets.reduce((acc, asset) => {
        acc[asset.id] = asset.price.change;
        return acc;
      }, {});

      // Update wallet views with the new price changes
      const updatedWalletViews = walletViews.map(wallet => ({
        ...wallet,
        assets: wallet.assets.map(asset => ({
          ...asset,
          price: {
            ...asset.price,
            change: priceChanges[asset.id] || 0
          }
        }))
      }));

      setAssetsChange(updatedAssets);
      setWalletViewsChange(updatedWalletViews);
      setLoading(false);
    };

    updatePriceChanges();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volumeFilter, assets, walletViews]);

  if (loading) {
    return (
      <motion.div
        key="skeleton"
        {...motionConfig}
        className={cn(
          styles.wrapper,
          "overflow-hidden"
        )}
      >
        <div className="mb-6">
          <Skeleton
            className="!bg-gray-custom/25 opacity-25"
            width={160}
            height={24}
          />

          <div className="flex max-480:flex-col-reverse max-480:flex-wrap justify-between items-center gap-4 mb-6">
            <div className="flex gap-2">
              <Skeleton
                className="!bg-gray-custom/25 opacity-25"
                height={38}
                width={300}
              />
            </div>

            <div className="flex max-480:flex-row flex-wrap justify-start items-center gap-4 max-480:w-full">
              <Skeleton
                className='!bg-gray-custom/25 opacity-25'
                height={38}
                width={360}
              />
            </div>
          </div>
        </div>

        <Skeleton
          className='!bg-gray-custom/25 opacity-25'
          height={500}
          width="100%"
        />
      </motion.div>
    );
  }

  if (!walletsData || !walletsData.getUserWallets) return <div>Error loading assets</div>;

  console.log('walletViews', walletViews);

  return (
    <div className={styles.wrapper}>
      <div className="mb-6">
        <div className="mb-2 font-sans font-semibold text-white text-lg text-start leading-6 tracking-[-0.02em]">Assets</div>

        <div className="flex max-480:flex-col-reverse max-480:flex-wrap justify-between items-center gap-4 mb-6">
          <ViewModeToggle viewMode={viewMode} onToggle={toggleViewMode} />
          {/* <VolumeFilter setVolumeFilter={setVolumeFilter} volumeFilter={volumeFilter} /> */}

          <div className="flex max-480:flex-row flex-wrap justify-start items-center gap-4 max-480:w-full">
            <AddWallet />
            <AdvancedFilter />
          </div>
        </div>

      </div>

      <AnimatePresence mode="wait">
        {isCombinedView ? (
          <AssetsTable
            key="combined"
            assets={assetsChange}
            getNetworkName={getNetworkName}
          />
        ) : (
          <WalletAssetsView
            key="by-wallet"
            wallets={walletViewsChange}
            getNetworkName={getNetworkName}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default MyAssets;
