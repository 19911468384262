/* eslint-disable import/no-extraneous-dependencies */
import { useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';
import { useState, useEffect, useRef } from 'react';
import { useCapabilities, useWriteContracts } from 'wagmi/experimental';
import contractAbi from '../../../constants/smartContract';
import { base, baseSepolia, sepolia, mainnet, polygon } from '@wagmi/core/chains'
import { useWagmiConfig } from '../../../myHooks/useWagmi'
import { isProduction } from '../../../utils';

export const useCoinbaseTrans = () => {
  const wagmiConfig = useWagmiConfig();

  const { address, isConnected } = useAccount();
  const { connect } = useConnect();
  const { disconnect } = useDisconnect();
  const [isProcessing, setIsProcessing] = useState(false);

  // Configure `useWriteContracts` to call the mint function on the contract
  const { writeContractsAsync } = useWriteContracts();

  // Check for paymaster capabilities with `useCapabilities`
  const { data: availableCapabilities, isLoading } = useCapabilities({
    account: address,
  });

  const availableCapabilitiesRef = useRef(null);

  useEffect(() => {
    if (availableCapabilities) {
      availableCapabilitiesRef.current = availableCapabilities;
    }
  }, [availableCapabilities]);

  const handleClaim = async (dividendInfo) => {
    if (!isConnected) {
      await wagmiConfig.connectors[0].connect();
      await connect({ connector: wagmiConfig.connectors[0] })
    }

    setIsProcessing(true);

    try {
      console.log('before writeContracts')

      const capabilitiesForChain = availableCapabilitiesRef.current
        ? availableCapabilitiesRef.current[isProduction ? base.id : baseSepolia.id]
        : null;

      const dynamicCapabilities = capabilitiesForChain && capabilitiesForChain.paymasterService.supported
      ? {
          paymasterService: {
            url: process.env.REACT_APP_PAYMASTER_AND_BUNDLER_ENDPOINT,
          },
        }
      : {};

      console.log('dynamicCapabilities', dynamicCapabilities);

      let chainId = isProduction ? base.id : baseSepolia.id;

      if (dividendInfo.network === 'mainnet') {
        chainId = isProduction ? mainnet.id : sepolia.id;
      } else if (dividendInfo.network === 'mainnet-only') {
        chainId = mainnet.id;
      } else if (dividendInfo.network === 'polygon') {
        chainId = isProduction ? polygon.id : '80002'; // amoy
      }

      const result = await writeContractsAsync({
        contracts: [
          {
            address: dividendInfo.address,
            abi: contractAbi.splitPaymentAbi,
            functionName: 'claim',
            args: [],
          },
        ],
        chainId,
        capabilities: dynamicCapabilities,
        options: {
          paymasterService: dynamicCapabilities.paymasterService,
        },
      });

      setIsProcessing(false);
      return result; // Return the hash instead of the full result
    } catch (error) {
      console.error('Approving failed:', error);
      setIsProcessing(false);

      return null;
    }
  };

  return {
    isConnected,
    connect,
    disconnect,
    handleClaim,
    isProcessing,
  }
}

export default useCoinbaseTrans;
