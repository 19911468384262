// src/services/dummyDataIssuers.js
import { is } from 'date-fns/locale'
import { isProduction } from '../utils'

const issuers = [
  {
    id: 1,
    tenantId: 3,
    coin_name: "INV",
    company_name: "Investhub",
    text: "Effortless Blockchain Investments.",
    image: "default-icon-day-v3.svg",
    market: {},
    network: 'base',
    contract: isProduction ? '0xDB99Fe1141Db1609721d6f561AA7D80302d18bA7' : '0x4DF60A566Ca5500b61d7C05B7770b3Ae0b635808',
    paymentHub: isProduction ? '0xDB99Fe1141Db1609721d6f561AA7D80302d18bA7' : '0x4DF60A566Ca5500b61d7C05B7770b3Ae0b635808',
    whitelistAddress: isProduction ? '0x3bF84Cf43EEb7D3FeB7bEeC85B62B918dAe86a5B' : '0x892c46f81Ec4D8F78db55C3C2b7B34e486Dd0d51',
    currencies: isProduction ? ['usdc', 'usdt', 'dai'] : ['usdc'],
    rate: 1
  },
  // {
  //   id: 2,
  //   coin_name: "KTS",
  //   company_name: "Kirk Test SplitPay",
  //   text: "Power your future with KTS, crafted by Kirk Test SplitPay for seamless integration in autonomous systems.",
  //   image: "default-icon-day-v3.svg",
  //   market: {},
  //   network: 'base',
  //   contract: isProduction ? '0xc2C9Aab24B0c24E9849dF3C10fb5C0bBf92d4a72' : '',
  //   paymentHub: isProduction ? '0xc2C9Aab24B0c24E9849dF3C10fb5C0bBf92d4a72' : '',
  //   whitelistAddress: isProduction ? '0x8aE25Cd3705aADF586FA964f8dC7D6E2eE3a0579' : '',
  //   currencies: isProduction ? ['eure', 'usdt', 'usdc', 'dai'] : [],
  //   rate: 1
  // },
  {
    id: 3,
    coin_name: "CAL",
    company_name: "CALIDRIS FINTECH AG",
    text: "Explore CALI, a cutting-edge digital asset by CALIDRIS FINTECH AG, leading the charge in financial technology innovation.",
    image: "default-icon-day-v3.svg",
    market: {},
    network: 'mainnet',
    contract: isProduction ? '' : '0x0fB576bc3719500921E59f882bc1c884775a1Ae2',
    paymentHub: isProduction ? '' : '0x0fB576bc3719500921E59f882bc1c884775a1Ae2',
    whitelistAddress: isProduction ? '' : '0x2Db609c2639DDb7C19D659fbD45604095f533822',
    currencies: isProduction ? [] : ['eure', 'usdt', 'usdc', 'dai'],
    rate: 1
  },
  // {
  //   id: 4,
  //   coin_name: "ARBE",
  //   company_name: "ARBE TECHNOLOGIES",
  //   text: "Power your future with ARBE, crafted by ARBE TECHNOLOGIES for seamless integration in autonomous systems.",
  //   image: "default-icon-day-v3.svg",
  //   market: {},
  //   network: 'polygon',
  //   contract: isProduction ? '0x876d3B344c34296BF9AaF79D64f257e3C3Dc34D5' : '',
  //   paymentHub: isProduction ? '0x876d3B344c34296BF9AaF79D64f257e3C3Dc34D5' : '',
  //   whitelistAddress: isProduction ? '0xaEc7e4be41286A3F4F01446B0777B00182691ded' : '',
  //   currencies: isProduction ? ['eure', 'usdt', 'usdc', 'dai'] : [],
  //   rate: 1
  // },
  {
    id: 5,
    coin_name: "bTSLA",
    company_name: "bTSLA",
    text: "Discover bTSLA, bridging the gap between innovative tech investments and blockchain advancements.",
    image: "default-icon-day-v3.svg",
    market: {},
    // network: 'base',
  },
  {
    id: 6,
    coin_name: "CUR",
    company_name: "Curio AG",
    text: "Elevate your investment strategy with CUR by Curio AG, where art meets blockchain technology.",
    image: "default-icon-day-v3.svg",
    market: {},
    // network: 'base',
  },
  {
    id: 7,
    coin_name: "AMB",
    company_name: "AMM Bahamas",
    text: "Dive into AMB by AMM Bahamas, offering high-yield opportunities amidst the digital asset waves.",
    image: "default-icon-day-v3.svg",
    market: {},
    // network: 'base',
  },
  {
    id: 8,
    coin_name: "TRX",
    company_name: "TAURUSX",
    text: "Navigate the financial cosmos with TRX by TAURUSX, paving the way for innovative trading solutions.",
    image: "default-icon-day-v3.svg",
    market: {},
    // network: 'base',
  },
  {
    id: 9,
    coin_name: "UECK",
    company_name: "Günther Uecker",
    text: "Experience the fusion of art and financial assets with UECK, the token inspired by Günther Uecker's legacy.",
    image: "default-icon-day-v3.svg",
    market: {},
    // network: 'base',
  },
  {
    id: 10,
    coin_name: "TRE",
    company_name: "TREASURY",
    text: "Secure your wealth with TRE, a comprehensive financial asset solution, perfectly balanced and globally diversified.",
    image: "default-icon-day-v3.svg",
    market: {},
    // network: 'base',
  },
  {
    id: 11,
    coin_name: "EDPX",
    company_name: "EDPX",
    text: "Innovate your portfolio with EDPX, your gateway to exponential growth in the tech and financial sectors.",
    image: "default-icon-day-v3.svg",
    market: {},
    // network: 'base',
  },
  {
    id: 12,
    coin_name: "DAKS",
    company_name: "Draggable Aktionariat AG Shares",
    text: "Aktionariat AG provides Swiss companies with digital tools to create a market for their tokenized shares on their website. DAKS tokens are shares of Aktionariat AG that are bound to a Token Holder Agreement that includes the possibility to automatically enforce a drag-along in case of an acquisition.",
    image: "default-icon-day-v3.svg",
    "contract": isProduction ? "0x6f38e0f1a73c96cb3f42598613ea3474f09cb200" : "",
    "totalShares": 1650000,
    "decimals": 0,
    network: 'mainnet',
    "paymentHub": isProduction ? "0x4fA0C488F321A1D089f7E5f951fe8C43F2064709" : "",
    "brokerbotAddress": isProduction ? "0x2f2c3cf0b2d6f4f6cd5f57665ae629eee813350b" : "",
    "market": {
      "address": "mainnet-0x2f2c3cf0b2d6f4f6cd5f57665ae629eee813350b",
      "url": "https://investors.aktionariat.com",
      "baseCurrency": "CHF",
      "baseCryptoCurrency": "ZCHF",
      "bankTransferEnabled": true,
      "bankTransferLimit": 1000,
      "cryptocurrencyMinimumAmount": 0,
      "allowList": false,
      "identityVerification": false,
      "version": 8
    }
  },
  {
    id: 13,
    coin_name: "DEXA",
    company_name: "Draggable Example AG",
    text: "Example AG Token\nhttps://hub.aktionariat.com/brokerbot-v3/staging/DEXA.html\n\nhttps://aktionariat.github.io/web-components/brokerbot/",
    image: "default-icon-day-v3.svg",
    "contract": isProduction ? "" : "0xbf0e13ab37573abb68ff9c33ee9ff8148b8a61e2",
    "totalShares": 2000000,
    "decimals": 0,
    network: 'mainnet-only',
    "paymentHub": isProduction ? "" : "0x4fA0C488F321A1D089f7E5f951fe8C43F2064709",
    "brokerbotAddress": isProduction ? "" : "0x690d26caeb5c878182af126cf4e6d8c0de82a62a",
    "market": {
      "address": isProduction ? "mainnet-0x690d26caeb5c878182af126cf4e6d8c0de82a62a" : "",
      "url": "https://aktionariat.github.io/web-components/brokerbot/?ticker=DEXA",
      "baseCurrency": "CHF",
      "baseCryptoCurrency": "ZCHF",
      "bankTransferEnabled": true,
      "bankTransferLimit": 0,
      "cryptocurrencyMinimumAmount": 0,
      "allowList": false,
      "identityVerification": false,
      "version": 8
    }
  },
  {
    coin_name: "USDS",
    company_name: "USDS Stablecoin",
    name: "USDS Stablecoin",
    address: "0x820C137fa70C8691f0e44Dc420a5e53c168921Dc",
    symbol: "USDS",
    decimals: 18,
    chainId: 8453,
    "contract": isProduction ? "0x820C137fa70C8691f0e44Dc420a5e53c168921Dc" : "",
    logoURI:
      "https://coin-images.coingecko.com/coins/images/39926/large/usds.webp?1726666683",
    network: 'base',
    isSwap: true,
  },
]

export default issuers
