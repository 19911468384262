import React, { useState } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

// Layout, tailwind registered at here for now
import { DashboardLayout, Entry, InstantInvestmentLayout } from './components/layouts'

import Theme from './components/Theme'
import SiteWrap from './components/PageWrapper'
import PageWrapper from './components/Page'
import PageWrapperProject from './components/PageProject'
import PageDashboard from './components/PageDashboard'
import PageAdminDashboard from './components/PageAdminDashboard'
import ProtectedZone from './components/routes/ProtectedZone'
import AuthRoute from './components/routes/AuthRoute'
import NotFound from './pages/NotFound'
import AuthNotFound from './pages/AuthNotFound'
import { USER_RIGHT_TYPES } from './constants/user'
// import NewHome from './pages/NewHome'
import Dashboard from './pages/Dashboard'
// import Pricing from './pages/Pricing'
// import Login from './pages/Login'
import SignIn from './pages/SignIn'

// import SignUp from './pages/SignUp' should be removed after change to the new versions
import Signup from './pages/Signup'
import RestorePassword from './pages/RestorePassword'
import RestorePasswordConfirm from './pages/RestorePasswordConfirm'
import Policy from './pages/Policy'
import Tos from './pages/TOS'
import SignUpConfirm from './pages/SignUpConfirm'
import CheckoutStatus from './pages/CheckoutStatus'
// import CheckoutStatusDummy from './pages/CheckoutStatusDummy'
import AdminDashboard from './pages/AdminDashboard'
import AdminTenant from './pages/AdminTenant'
import AdminSetting from './pages/AdminSetting'
import AdminPrice from './pages/AdminPrice'
import AdminUser from './pages/AdminUser'
import ConnectWallet from './pages/ConnectWallet'
// import OnBoarding from './pages/Onboarding'
// import OnBoardingBest from './pages/OnBoardingBest'
// import InvestorOnBoarding from './pages/InvestorOnBoarding'
// import Explore from './pages/Explore'
// import OpportunityList from './pages/OpportunityList/OpportunityList'
import AdminBlacklist from './pages/AdminBlacklist'
import AdminStorage from './pages/AdminStorage'
import TenantSubscribe from './pages/TenantSubscribe'
import TenantSetupDns from './pages/TenantSetupDns'
import AdminRedirect from './pages/AdminRedirect'
import AdminPage from './pages/AdminPage'
import AdminProjectPage from './pages/AdminProjectPage'
import AdminCreateProjectPage from './pages/AdminCreateProjectPage'
import AdminEditProjectPage from './pages/AdminEditProjectPage'
// import Project from './pages/Project'
import SsoRequest from './pages/SsoRequest'
import TenantDetails from './pages/TenantDetails'
// import UserKycInfo from './pages/UserKycInfo'
import AdminTenantDetails from './pages/AdminTenantDetails'
import TenantUpgrade from './pages/TenantUpgrade'
import TenantRedirect from './pages/TenantRedirect'
import TenantEasySetup from './pages/TenantEasySetup'
import TenantEasyEvolveSetup from './pages/TenantEasyEvolveSetup'
import ProfileName from './pages/ProfileName'
// import Learn from './pages/Learn'
// import LearnDetail from './pages/LearnDetail'
import MyProfile from './pages/MyProfile'
// import Opportunity from './pages/Opportunity'
// import OpportunitySuccess from './pages/OpportunitySuccess'
import SsoAuth from './pages/SsoAuth'
import PersonalisedOpportunities from './pages/PersonalisedOpportunities'
import Issuer from './pages/Issuer'
import DashboardHomepage from './pages/DashboardHomepage'
import DashboardTokens from './pages/DashboardTokens'
import DashboardChatPro from './pages/DashboardChatPro'
import DashboardIssuer from './pages/DashboardIssuer'
import StyleGuide from './pages/StyleGuide'
import InstantInvestment from "./pages/InstantInvestment"
import IssuerDetail from './pages/IssuerDetail'
import Commission from './pages/Commission'

export default () => {
  const [pageTitle, setPageTitle] = useState("")

  return (
    <Theme>
      <Switch>
      {/* <Route
        exact
        path="/"
        render={() => (
          <PageWrapper>
            <Home />
          </PageWrapper>
        )} /> */}

      <Route
        exact
        path="/"
        render={() => (
          <Redirect to="/tokenlist" />
        )}
      />

      {/* <Route
        exact
        path="/issuer"
        render={() => (
          <SiteWrap>
            <Issuer />
          </SiteWrap>
        )}
      />

      <Route
        exact
        path="/checkout-status-dummy"
        render={() => (
          <PageWrapper>
            <CheckoutStatusDummy />
          </PageWrapper>
        )}
      />

      <Route
        exact
        path="/opportunity"
        render={() => (
          <SiteWrap>
            <OpportunityList />
          </SiteWrap>
        )}
      />

      <Route
        exact
        path="/opportunity/success"
        render={() => (
          <PageWrapper>
            <OpportunitySuccess />
          </PageWrapper>
        )}
      />

      <Route
        exact
        path="/opportunity/:id"
        render={({ match }) => (
          <PageWrapper>
            <Opportunity match={match} />
          </PageWrapper>
        )}
      />

      <Route
        exact
        path="/learn"
        render={() => (
          <SiteWrap
            classWrapper="outer v2"
          >
            <Learn />
          </SiteWrap>
        )}
      />

      <Route
        exact
        path="/learn/:learnId"
        render={({ match }) => (
          <PageWrapper>
            <LearnDetail match={match} />
          </PageWrapper>
        )}
      />

      <Route
        exact
        path="/pricing"
        render={() => (
          <SiteWrap
            classWrapper="outer v2 pricing"
          >
            <Pricing />
          </SiteWrap>
        )}
      /> */}

      <AuthRoute
        exact
        path="/login"
        render={() => (
          <Entry>
            <SignIn />
          </Entry>
        )}
      />

      <AuthRoute
        exact
        path="/signup"
        render={() => (
          <Entry>
            <Signup />
          </Entry>
        )}
      />

      <AuthRoute exact path="/signup/confirm" component={SignUpConfirm} />

      <AuthRoute
        exact
        path="/restore-password"
        render={() => (
          <SiteWrap
            classWrapper="outer v2"
          >
            <RestorePassword />
          </SiteWrap>
        )}
      />
      <AuthRoute
        exact
        path="/restore-password/confirm"
        render={() => (
          <PageWrapper>
            <RestorePasswordConfirm />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/privacy-policy"
        render={() => (
          <PageWrapper>
            <Policy />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/tos"
        render={() => (
          <PageWrapper>
            <Tos />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/not-found"
        render={() => (
          <PageWrapper>
            <NotFound />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/connect-wallet"
        render={() => (
          <PageWrapper>
            <ConnectWallet />
          </PageWrapper>
        )}
      />
      {/* <Route
        exact
        path="/onboarding"
        render={() => (
          <PageWrapper>
            <OnBoarding />
          </PageWrapper>
        )}
      />

      {/* <Route
        exact
        path="/explore"
        render={() => (
          <SiteWrap
            classWrapper="outer v2"
          >
            <Explore />
          </SiteWrap>
        )}
      /> */}

      <Route
        exact
        path="/chatpro"
        render={() => (
          <DashboardLayout
            pageTitle={pageTitle}
            isContentFullHeight={true}
          >
            <DashboardChatPro setPageTitle={setPageTitle} />
          </DashboardLayout>
        )}
      />

      <Route
        path="/dashboard/tokens/:tokenName"
        render={({ match }) => (
          <DashboardLayout
              pageTitle={pageTitle}
              isContain={true}
              isContentFullHeight={true}
          >
            <DashboardIssuer setPageTitle={setPageTitle} match={match} />
          </DashboardLayout>
        )}
      />

      {/* <Route
        exact
        path="/onboarding-best"
        render={() => (
          <PageWrapper>
            <OnBoardingBest />
          </PageWrapper>
        )}
      /> */}
      {/* <Route
        exact
        path="/investor-onboarding"
        render={() => (
          <PageWrapper>
            <InvestorOnBoarding />
          </PageWrapper>
        )}
      /> */}
      <Route
        exact
        path="/sso/auth"
        render={() => (
          <PageWrapper>
            <SsoAuth />
          </PageWrapper>
        )}
      />
      {/* <Route
        exact
        path="/projects/:projectUri"
        render={({ match }) => (
          <PageWrapperProject>
            <Project match={match} />
          </PageWrapperProject>
        )}
      /> */}
      <Route
        exact
        path="/sso/request"
        render={() => (
          <PageWrapperProject>
            <SsoRequest />
          </PageWrapperProject>
        )}
      />
      <Route
        exact
        path="/redirect-to-tenant"
        render={() => (
          <PageWrapperProject>
            <TenantRedirect />
          </PageWrapperProject>
        )}
      />

      <ProtectedZone
        path={[
          '/dashboard-old',
          '/dashboard',
          '/dashboard/*',
          '/my-profile',
          '/my-account/kyc',
          '/payment/stripe/:status',
          '/tenant-easy-setup',
          '/tenant-evolve-setup',
          '/profile-name',
          '/personalised_opportunities',
          '/instant-investment',
          '/tokenlist',
          '/explore/tokens/*',
          '/commission',
        ]}
        minRights={USER_RIGHT_TYPES.COMMON}
      >
        <Route
          exact
          path="/explore/tokens/*"
          render={({ match }) => (
            <DashboardLayout
              pageTitle={pageTitle}
              isContain={true}
              isContentFullHeight={true}
            >
              <IssuerDetail setPageTitle={setPageTitle} />
            </DashboardLayout>
          )}
        />

        <Route
          exact
          path="/dashboard-old"
          render={() => (
            <PageDashboard>
              <Dashboard />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/dashboard"
          render={() => (
            <DashboardLayout pageTitle={pageTitle} isContentFullHeight={true}>
              <DashboardHomepage setPageTitle={setPageTitle} />
            </DashboardLayout>
          )}
        />

        <Route
          exact
          path="/tokenlist"
          render={() => (
            <DashboardLayout pageTitle={pageTitle}>
              <DashboardTokens setPageTitle={setPageTitle} />
            </DashboardLayout>
          )}
        />

        <Route
          exact
          path="/instant-investment"
          render={() => (
            <InstantInvestmentLayout isLogin={true} pageTitle={pageTitle}>
              <InstantInvestment setPageTitle={setPageTitle} />
            </InstantInvestmentLayout>
          )}
        />

        <Route
          exact
          path="/commission"
          render={() => (
            <DashboardLayout pageTitle={pageTitle}>
              <Commission setPageTitle={setPageTitle} />
            </DashboardLayout>
          )}
        />

        <Route
          exact
          path="/my-profile"
          render={() => (
            <DashboardLayout pageTitle={pageTitle}>
              <MyProfile setPageTitle={setPageTitle} />
            </DashboardLayout>
          )}
        />

        <Route
          exact
          path="/profile-name"
          render={() => (
            <PageDashboard>
              <ProfileName />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/tenant-easy-setup"
          render={() => (
            <PageDashboard>
              <TenantEasySetup />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/tenant-evolve-setup"
          render={() => (
            <PageDashboard>
              <TenantEasyEvolveSetup />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/dashboard/tenants/:tenantId"
          render={({ match }) => (
            <PageDashboard>
              <TenantDetails match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/dashboard/tenants/:tenantId/subscribe"
          render={({ match }) => (
            <PageDashboard>
              <TenantSubscribe match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/dashboard/tenants/:tenantId/setup-dns"
          render={({ match }) => (
            <PageDashboard>
              <TenantSetupDns match={match} />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/dashboard/tenants/:tenantId/upgrade"
          render={({ match }) => (
            <PageDashboard>
              <TenantUpgrade match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/payment/stripe/:status"
          render={({ match }) => (
            <PageDashboard>
              <CheckoutStatus match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/my-account/kyc"
          render={() => (
            // <PageDashboard>
            //   <UserKycInfo />
            // </PageDashboard>
            <Redirect to="/dashboard" />
          )}
        />
        <Route
          exact
          path="/personalised_opportunities"
          render={() => (
            <PageDashboard>
              <PersonalisedOpportunities />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="*"
          render={() => (
            <PageDashboard>
              <AuthNotFound />
            </PageDashboard>
          )}
        />
      </ProtectedZone>

      <ProtectedZone path={['/admin', '/admin/*']} minRights={USER_RIGHT_TYPES.ADMIN}>
        <Route exact path="/admin" component={AdminRedirect} />
        <Route
          exact
          path="/admin/dashboard"
          render={() => (
            <PageAdminDashboard>
              <AdminDashboard />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="/admin/tenants"
          render={() => (
            <PageAdminDashboard>
              <AdminTenant />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="/admin/tenants/:tenantId"
          render={({ match }) => (
            <PageAdminDashboard>
              <AdminTenantDetails match={match} />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="/admin/pages"
          render={() => (
            <PageAdminDashboard>
              <AdminPage />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="/admin/pages/projects"
          render={() => (
            <PageAdminDashboard>
              <AdminProjectPage />
            </PageAdminDashboard>
          )}
        />
        <Route
          exact
          path="/admin/pages/projects/create"
          render={() => (
            <PageAdminDashboard>
              <AdminCreateProjectPage />
            </PageAdminDashboard>
          )}
        />
        <Route exact path="/admin/pages/projects/:projectId/edit" component={AdminEditProjectPage} />

        <Route
          exact
          path="/admin/settings"
          render={() => (
            <PageAdminDashboard>
              <AdminSetting />
            </PageAdminDashboard>
          )}
        />
        <Route
          exact
          path="/admin/users"
          render={() => (
            <PageAdminDashboard>
              <AdminUser />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="/admin/prices"
          render={() => (
            <PageAdminDashboard>
              <AdminPrice />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="/admin/blacklist"
          render={() => (
            <PageAdminDashboard>
              <AdminBlacklist />
            </PageAdminDashboard>
          )}

        />
        <Route
          exact
          path="/admin/storage"
          render={() => (
            <PageAdminDashboard>
              <AdminStorage />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="*"
          render={() => (
            <PageDashboard>
              <AuthNotFound />
            </PageDashboard>
          )}
        />
      </ProtectedZone>

      <Route
        exact
        path="/xxx-style-guide"
        render={() => (<StyleGuide />)}
      />

      <Route
        exact
        path="*"
        render={() => (
          <PageDashboard>
            <NotFound />
          </PageDashboard>
        )}
      />
    </Switch>
  </Theme>
  )
}
