// Import statements
import React, { useState, useEffect, useCallback, useRef } from "react";
import { ModalWide } from "../../../../../components/shared/Modals";
import ShortToken from "../../../../../components/shared/ShortToken";
import Icons from "../../../../../components/shared/Icons";

// Image imports
import ethImg from "../../../../../assets/images/ethereum.png";
import polygonImg from "../../../../../assets/images/polygon.png";
import ibanImgOne from "../../../../../assets/images/IBAN/iban-detail.png";
import ibanMoveAccountImg from "../../../../../assets/images/IBAN/move-other-account.png";
import ibanNumber from "../../../../../assets/images/IBAN/iban-number.png";
import { MoneriumClient, constants } from '@monerium/sdk';
import { useAuth, useProfile } from '@monerium/sdk-react-provider';
import { useMe } from '../../../../../myHooks'
import Web3 from 'web3'
import { createWalletClient, custom } from 'viem'
import { mainnet, sepolia, polygon } from 'viem/chains'

import { ethereum } from '../../../../../myHooks/useCoinbaseAuth';
import metamaskService from '../../../../../services/metamask'
import { toaster, isProduction } from '../../../../../utils';
import { providers } from 'ethers'
// eslint-disable-next-line import/no-unresolved
import { useAppKitAccount, useAppKitProvider, useDisconnect } from '@reown/appkit/react'
import { useFormContext } from "react-hook-form";
import Loader from "../../../../../components/shared/Loader";
import classNames from 'classnames'

const { LINK_MESSAGE } = constants; // "I hereby declare that I am the address owner."

const titles = [
  "Decide Which Network to Use for Generating an IBAN", // 1
  "Transfer Money to proceed with order", // 2
  "Order Status",
];

const IBANDetail = ({ isOpen, onClose, onNext, currentIssuer }) => {
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [profile, setProfile] = useState(null);
  const [monerium, setMonerium] = useState();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [iban, setIban] = useState();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const { walletProvider } = useAppKitProvider('eip155')
  const { address: wcAddress, isConnected: isWcConnected } = useAppKitAccount()
  const { getValues } = useFormContext()
  const transactions = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [network, setNetwork] = useState(null)

  const { data: { me: user } = {} } = useMe()

  const handleStepChange = (step, titleIndex) => {
    setCurrentStep(step);
    setCurrentTitleIndex(titleIndex);
  };

  useEffect(() => {
    if (!isOpen) {
      // Reset state ketika modal ditutup
      setCurrentStep(1);
      setCurrentTitleIndex(0);
      setIsLoading(false)
      setNetwork(null)
      setIban(null)
    }

    // console.log('isAuthorized', isAuthorized);

    // if (isOpen && monerium && !code && !isAuthorized && !monerium.isAuthorized) {
    //   console.log('monerium.authorize');
    //   localStorage.setItem('issuer', urlParams.get('issuer'))
    //   setIsLoading(true)

    //   monerium.authorize({
    //     clientId: process.env.REACT_APP_MONERIUM_AUTHORIZATION_CLIENT_ID,
    //     redirectUri: process.env.REACT_APP_MONERIUM_REDIRECT_URI
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const sdk = new MoneriumClient({
      environment: isProduction ? 'production' : 'sandbox',
      clientId: process.env.REACT_APP_MONERIUM_AUTHORIZATION_CLIENT_ID,
      redirectUri: process.env.REACT_APP_MONERIUM_REDIRECT_URI
    });

    setMonerium(sdk);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const connect = async () => {
      if (monerium && !monerium.isAuthorized && isOpen) {
        setIsLoading(true)
        try {
          const auth = await monerium.getAccess();

          console.log('sdk.bearerProfile', monerium.bearerProfile)
          console.log('sdk.isAuthorized', monerium.isAuthorized)

          console.log('auth', auth);

          if (!auth) {
            // save issuer to local storage
            localStorage.setItem('issuer', urlParams.get('issuer'))

            monerium.authorize({
              clientId: process.env.REACT_APP_MONERIUM_AUTHORIZATION_CLIENT_ID,
              redirectUri: process.env.REACT_APP_MONERIUM_REDIRECT_URI
            });
          } else {
            localStorage.removeItem('issuer')
            setIsAuthorized(auth);
            setIsLoading(false)
          }
        } catch (err) {
          localStorage.setItem('issuer', urlParams.get('issuer'))

          monerium.authorize({
            clientId: process.env.REACT_APP_MONERIUM_AUTHORIZATION_CLIENT_ID,
            redirectUri: process.env.REACT_APP_MONERIUM_REDIRECT_URI
          });
        }
      }
    };

    connect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monerium, code, isOpen]);

  const linkWallet = useCallback(async (selectedNetwork) => {
    try {
      let signature;

      setIsLoading(true)

      if (user.login_provider === 'coinbase') {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: 0xaa36a7 }], // Sepolia
          // params: [{ chainId: 0x14a34 }],
        })

        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        const walletAddress = accounts[0];

        console.log('walletAddress', walletAddress)

        let chain = isProduction ? mainnet : sepolia;

        if (currentIssuer.network === 'polygon') {
          chain = isProduction ? polygon : '80002'; // amoy
        }

        const walletClient = createWalletClient({
          chain,
          transport: custom(ethereum),
        })

        signature = await walletClient.signMessage({
          account: user.wallet_address,
          message: LINK_MESSAGE,
        })
      } else if (user.login_provider === 'walletconnect' && isWcConnected) {
        const ethersProvider = new providers.Web3Provider(walletProvider)

        const hexMessage = `0x${Buffer.from(LINK_MESSAGE, "utf8").toString("hex")}`

        // Request signature from user
        signature = await ethersProvider.provider.request({
          method: 'personal_sign',
          params: [
            hexMessage,
            wcAddress,
          ]
        });
      } else {
        if (metamaskService.isMetamaskNotAvailable()) {
          toaster.error('You need to install Metamask to use this feature')

          return false;
        }

        signature = await metamaskService.signMessage(user.wallet_address, LINK_MESSAGE);
      }

      console.log('selectedNetwork', selectedNetwork)

      // Link a new address to Monerium and create accounts for ethereum and gnosis.
      await monerium.linkAddress({
        profile: profile.id,
        address: user.wallet_address, // user wallet address
        message: LINK_MESSAGE,
        signature,
        chain: selectedNetwork,
      });

      setIsLoading(false)

      return true;
    } catch (err) {
      toaster.error(`Error linking wallet: ${err.message}`);
      setIsLoading(false)
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  const checkIbanLinked = useCallback(async (currentNetwork) => {
    if (!monerium || !profile || !currentNetwork) return

    const { ibans } = await monerium.getIbans({
      profile: profile.id
    })

    console.log('ibans', ibans)
    console.log('user.wallet_address', user.wallet_address)
    console.log('currentNetwork', currentNetwork)

    const existingIban = (ibans || []).find(iban => iban.address.toLowerCase() === user.wallet_address.toLowerCase() && iban.chain === currentNetwork)

    console.log('existingIban', existingIban)

    if (existingIban) {
      setIban(existingIban)
      handleStepChange(5, 2)
    } else {
      handleStepChange(3, 1)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monerium, profile])

  const checkAndLinkWallet = useCallback(async (currentNetwork) => {
    if (!monerium || !profile || !currentNetwork) return

    try {
      setIsLoading(true)
      setNetwork(currentNetwork)

      console.log('currentNetwork', currentNetwork)

      const { addresses } = await monerium.getAddresses({
        profile: profile.id
      })

      console.log('addresses', addresses)

      if (addresses.find(address => address.address.toLowerCase() === user.wallet_address.toLowerCase() && address.chains.includes(currentNetwork))) {
        await checkIbanLinked(currentNetwork)
      } else {
        const linked = await linkWallet(currentNetwork)

        if (linked) {
          await checkIbanLinked(currentNetwork)
        }
      }
      setIsLoading(false)
    } catch (err) {
      console.error('Error checking and linking wallet:', err);
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monerium, user, profile])

  useEffect(() => {
    const fetchData = async () => {
      if (monerium && monerium.isAuthorized && !profile) {
        try {
          setIsLoading(true)

          console.log('sdk.bearerProfile', monerium.bearerProfile)

          const { profiles } = await monerium.getProfiles();

          setProfile(await monerium.getProfile(
            profiles[0].id
          ))

          setIsLoading(false)
        } catch (err) {
          console.error('Error fetching data:', err);
          setIsLoading(false)
        }
      }
    };
    fetchData();
  }, [monerium, profile, user, isAuthorized]);

  const requestIban = async () => {
    // await monerium.submitProfileDetails(profile.id, {
    //   personal: {
    //     firstName: 'Jane',
    //     lastName: 'Doe',
    //     address: 'Pennylane 123',
    //     postalCode: '7890',
    //     city: 'Liverpool',
    //     country: 'FR',
    //     countryState: 'Merseyside',
    //     nationality: 'FR',
    //     birthday: '1990-05-15',
    //     idDocument: {
    //       number: 'A1234566788',
    //       kind: 'passport'
    //     }
    //   }
    // })

    const iban = await monerium.requestIban({
      address: user.wallet_address,
      chain: network,
      emailNotifications: true
    });

    handleStepChange(5, 2);
  }

  const moveIban = async (currentNetwork) => {
    try {
      setIsLoading(true)
      const { ibans } = await monerium.getIbans({
        profile: profile.id,
      })

      const result = await monerium.moveIban(ibans[0].iban, {
        address: user.wallet_address,
        chain: currentNetwork,
      })

      console.log('result', result);

      const iban = await monerium.getIban(ibans[0].iban);

      if (result.status === 'Accepted') {
        setIban(iban)
        handleStepChange(5, 2)
      }

      setIsLoading(false)
    } catch (err) {
      console.error('Error moving iban:', err);
      setIsLoading(false)
    }
  }

  const fetchTransactions = useCallback(async () => {
    if (!monerium || !profile || !user) return;

    const { orders } = await monerium.getOrders({
      profile: profile.id,
      address: user.wallet_address,
    })

    console.log('orders', orders);
    transactions.current = orders

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monerium, profile, user])

  useEffect(() => {
    if (monerium && profile && iban && !transactions.current) {
      fetchTransactions()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monerium, profile, iban])

  const checkNewTransaction = useCallback(async () => {
    if (!monerium || !profile || !user || !transactions.current) return null;

    const { orders } = await monerium.getOrders({
      profile: profile.id,
      address: user.wallet_address,
    })

    // check if new transaction is added
    console.log('transactions', transactions.current)
    const newTransaction = orders.find(order => !transactions.current.map(transaction => transaction.id).includes(order.id))

    console.log('newTransaction', newTransaction);

    return newTransaction

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monerium, transactions, profile, user])

  // checkNewTransaction every 5s if step is 5 and stop when new transaction have status success
  useEffect(() => {
    if (currentStep >= 5) {
      console.log('setInterval');
      const interval = setInterval(async () => {
        const newTransaction = await checkNewTransaction()

        console.log('newTransaction', newTransaction);

        const { state } = newTransaction || {}

        console.log('state', state);

        switch (state) {
          case 'pending':
            handleStepChange(6, 2)
            break
          case 'placed':
            handleStepChange(7, 2)
            break
          case 'processed':
            clearInterval(interval)

            setTimeout(() => {
              onNext();
              onClose();
            }, 2000)
            break
          default:
            break
        }
      }, 5000)

      return () => clearInterval(interval)
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  console.log('currentStep', currentStep);
  console.log('monerium.isAuthorized', monerium && monerium.isAuthorized);

  const handleCopy = (text) => {
    if (!text) return

    navigator.clipboard.writeText(text).then(() => {
      // setIcon('checks')
      // setTimeout(() => setIcon('copy'), 300)
    })
  }

  if (isLoading) {
    return (
      <ModalWide
        title={titles[currentTitleIndex]}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Loader />
      </ModalWide>
    )
  }

  if (monerium && !monerium.isAuthorized) {
    return (
      <ModalWide
        title="Lost the connection. Please click to connect again"
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className="block font-bold text-sm text-white">
          <button onClick={() => monerium.authorize()}>Connect</button>
        </div>
      </ModalWide>
    )
  }

  console.log('currentIssuer', currentIssuer);

  let buyValue = +(getValues('amount') || 100).toFixed(2);
  buyValue = +(getValues('amount') || 100) > buyValue ? buyValue + 0.01 : buyValue;

  return (
    <ModalWide
      title={titles[currentTitleIndex]}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div>
        {currentStep === 1 && (
          <>
            {/* Step 1: Choose network */}

            <div className="justify-center items-stretch gap-x-3 gap-y-3 border-0 grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto my-0 px-0 pt-3 pb-6 max-w-sm align-baseline">
              {/* ETH for migrate IBAN */}

              <label className={classNames(
                "flex flex-col flex-wrap justify-start items-start hover:opacity-60 w-full transition-all duration-[0.2s] cursor-pointer",
                currentIssuer.network === 'mainnet' || currentIssuer.network === 'mainnet-only' ? '-order-1' : ''
              )}>
                <div className="block font-bold text-sm text-white">
                  Ethereum network { currentIssuer.network === 'mainnet' || currentIssuer.network === 'mainnet-only' ? '(Recommended)' : '' }
                </div>
                <input
                  type="radio"
                  name="iban-network"
                  className="absolute border-0 bg-[0_0] bg-[initial] opacity-0 m-0 p-0 border-solid rounded-none font-[inherit] text-inherit leading-[inherit] overflow-visible align-middle"
                  onClick={() => checkAndLinkWallet(isProduction ? 'ethereum' : 'sepolia')}
                />
                <div className="flex flex-row flex-wrap justify-start items-center gap-x-3 gap-y-3 border-0 m-0 px-0 py-2 rounded-2xl w-full align-baseline">
                  <div className="border-0 m-0 p-0 rounded-[50%] w-10 h-10 overflow-hidden align-baseline">
                    <img src={ethImg} alt="Ethereum Network" />
                  </div>

                  <div className="flex flex-row flex-wrap flex-[1_1_0] justify-start items-center border-0 m-0 p-0 w-full font-semibold text-white align-baseline">
                    <div className="flex-1 text-left">
                      <ShortToken
                        original={(user && user.wallet_address) || ''}
                      />
                    </div>
                  </div>
                </div>
              </label>

              {/* Polygon redirect to stepper */}
              <label className="flex flex-col flex-wrap justify-start items-start hover:opacity-60 w-full transition-all duration-[0.2s] cursor-pointer">
                <div className="block font-bold text-sm text-white">
                  Polygon Network { currentIssuer.network === 'polygon' ? '(Recommended)' : '' }
                </div>
                <input
                  type="radio"
                  name="iban-network"
                  className="absolute border-0 bg-[0_0] bg-[initial] opacity-0 m-0 p-0 border-solid rounded-none font-[inherit] text-inherit leading-[inherit] overflow-visible align-middle"
                  onClick={() => checkAndLinkWallet(isProduction ? 'polygon' : 'amoy')}
                />
                <div className="flex flex-row flex-wrap justify-start items-center gap-x-3 gap-y-3 border-0 m-0 px-0 py-2 rounded-2xl w-full align-baseline">
                  <div className="border-0 m-0 p-0 rounded-[50%] w-10 h-10 overflow-hidden align-baseline">
                    <img src={polygonImg} alt="Polygon Network" />
                  </div>

                  <div className="flex flex-row flex-wrap flex-[1_1_0] justify-start items-center border-0 m-0 p-0 w-full font-semibold text-white align-baseline">
                    <div className="flex-1 text-left">
                      <ShortToken
                        original={(user && user.wallet_address) || ''}
                      />
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            {/* Step 2: If no IBAN account record for Polygon */}

            <div className="justify-center items-stretch gap-x-3 gap-y-3 border-0 grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto my-0 px-0 pt-3 pb-6 max-w-sm align-baseline">
              <div className="flex flex-col flex-wrap justify-start items-start w-full transition-all duration-[0.2s] cursor-pointer">
                <div className="block font-bold text-sm text-white">
                  Polygon network
                </div>
                <div className="flex flex-row flex-wrap justify-start items-center gap-x-3 gap-y-3 border-0 m-0 px-0 py-2 rounded-2xl w-full align-baseline">
                  <div className="border-0 m-0 p-0 rounded-[50%] w-10 h-10 overflow-hidden align-baseline">
                    <img src={polygonImg} alt="Polygon Network" />
                  </div>

                  <div className="flex flex-row flex-wrap flex-[1_1_0] justify-start items-center border-0 m-0 p-0 w-full font-semibold text-white align-baseline">
                    <div className="flex-1 text-left">
                      <ShortToken
                        original={(user && user.wallet_address) || ''}
                      />
                    </div>

                    <div className="border-0 m-0 p-0 font-[600] text-[#6F737E] text-[13px] align-baseline">
                      € 0
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative w-full text-center">
              <img src={ibanImgOne} alt="IBAN details" className="mx-auto" />
            </div>
            <div className="mt-4" />

            <div className="mx-auto w-full max-w-sm">
              <button
                className="inline-flex justify-center items-center bg-[#0052ff] hover:bg-[#0148dd] hover:opacity-90 m-0 px-6 rounded-[40px] w-full h-14 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] overflow-visible backface-visibility-visible"
                onClick={() => requestIban()}
              >
                Confirm & continue
              </button>
            </div>
          </>
        )}

        {currentStep === 3 && (
          <>
            {/* Step 3: If IBAN account have record and need to migrate account - Ethereum */}
            <div className="justify-center items-stretch gap-x-3 gap-y-3 border-0 grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto my-0 px-0 pt-3 pb-6 max-w-sm align-baseline">
              <div className="flex flex-col flex-wrap justify-start items-start w-full transition-all duration-[0.2s] cursor-pointer">
                <div className="block font-bold text-sm text-white">
                  {network} network
                </div>
                <div className="flex flex-row flex-wrap justify-start items-center gap-x-3 gap-y-3 border-0 m-0 px-0 py-2 rounded-2xl w-full align-baseline">
                  <div className="border-0 m-0 p-0 rounded-[50%] w-10 h-10 overflow-hidden align-baseline">
                    <img src={network === 'ethereum' ? ethImg : polygonImg} alt={network} />
                  </div>

                  <div className="flex flex-row flex-wrap flex-[1_1_0] justify-start items-center border-0 m-0 p-0 w-full font-semibold text-white align-baseline">
                    <div className="flex-1 text-left">
                      <ShortToken
                        original={(user && user.wallet_address) || ''}
                      />
                    </div>

                    {/* <div className="border-0 m-0 p-0 font-[600] text-[#6F737E] text-[13px] align-baseline">
                      € 0
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="relative w-full text-center font-bold text-sm text-white">
                You already have a Monerium IBAN connected to another account. Do you want to move it to this account?
              </div>

              <div className="relative w-full text-center font-bold text-sm text-white">
                Click confirm to move it to this account
              </div>

              <div className="mt-4" />

              <div className="mx-auto w-full max-w-sm">
                {/* Ini belum */}
                <button
                  className="inline-flex justify-center items-center bg-[#0052ff] hover:bg-[#0148dd] hover:opacity-90 m-0 px-6 rounded-[40px] w-full h-14 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] overflow-visible backface-visibility-visible"
                  onClick={() => moveIban(network)}
                >
                  Confirm
                </button>
              </div>
            </div>
          </>
        )}

        {currentStep === 4 && (
          <>
            {/* Step 4: After IBAN migrate account - ethereum */}
            <div className="justify-center items-stretch gap-x-3 gap-y-3 border-0 grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto my-0 px-0 pt-3 pb-6 max-w-sm align-baseline">
              <div className="flex flex-col flex-wrap justify-start items-start w-full transition-all duration-[0.2s] cursor-pointer">
                <div className="block font-bold text-sm text-white">
                  Ethereum network
                </div>
                <div className="flex flex-row flex-wrap justify-start items-center gap-x-3 gap-y-3 border-0 m-0 px-0 py-2 rounded-2xl w-full align-baseline">
                  <div className="border-0 m-0 p-0 rounded-[50%] w-10 h-10 overflow-hidden align-baseline">
                    <img src={ethImg} alt="Polygon Network" />
                  </div>

                  <div className="flex flex-row flex-wrap flex-[1_1_0] justify-start items-center border-0 m-0 p-0 w-full font-semibold text-white align-baseline">
                    <div className="flex-1 text-left">
                      <ShortToken
                        original={(user && user.wallet_address) || ''}
                      />
                    </div>

                    <div className="border-0 m-0 p-0 font-[600] text-[#6F737E] text-[13px] align-baseline">
                      {/* € 0 */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative w-full text-center">
              <div className="transition-all">
                {/* Title */}
                <div className="block m-0 mb-3 pb-4 border-b-2 border-b-solid border-b-white/10 w-full text-left text-lg text-white break-words leading-6">IBAN details</div>
              </div>
            </div>
            <div className="mt-4" />
            <div className="mx-auto w-full max-w-sm">
              <button
                className="inline-flex justify-center items-center bg-[#0052ff] hover:bg-[#0148dd] hover:opacity-90 m-0 px-6 rounded-[40px] w-full h-14 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] overflow-visible backface-visibility-visible"
                onClick={() => handleStepChange(5, 2)}
              >
                Confirm & continue
              </button>
            </div>
          </>
        )}

        {currentStep === 5 && (
          <>
            {/* Order status 1 */}
            <ol className="inline-flex justify-center items-center mx-auto w-full max-w-md">
              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-1/2 after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-[#0052ff] rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0">
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-white"
                  />
                </span>
                <div className="w-full text-[13px] text-white leading-[1.2] tracking-[-0.02em]">
                  Ordered
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span
                  className="relative z-10 flex justify-center items-center bg-black-light rounded-full w-10 lg:w-12 h-10 lg:h-12 cursor-pointer shrink-0"
                >
                  <Icons nameIcon="loader" className="w-4 h-4 text-white" />
                </span>
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Verifying
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-black-light rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0" />
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Processing
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-black-light rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0" />
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Sending
                </div>
              </li>
            </ol>

            <div className="relative mt-12 mb-4 w-full text-center">
              <div className="my-4 text-[13px] text-white/70 leading-[1.2] tracking-[-0.02em]">
                Please send {buyValue} Euro to your IBAN:
              </div>

              <div className="mx-auto w-full max-w-sm">
                <div className="flex justify-between items-center bg-transparent px-0 py-4 rounded-xl h-12 font-semibold text-gray-lighter text-sm leading-6 tracking-[-0.005em] transition-colors duration-[0.2s] ease-linear">
                  <div className="">IBAN</div>
                  <button
                    type="button"
                    className="inline-flex justify-end items-center gap-1 bg-transparent hover:opacity-80 text-white transition-opacity duration-[0.2s] ease-linear cursor-pointer outline-none"
                    onClick={() => handleCopy(iban.iban)}
                  >
                    {iban.iban}
                    <Icons
                      nameIcon="copy"
                      className="inline-flex ml-2 w-4 h-4"
                    />
                  </button>
                </div>
                <div className="flex justify-between items-center bg-transparent px-0 py-4 rounded-xl h-12 font-semibold text-gray-lighter text-sm leading-6 tracking-[-0.005em] transition-colors duration-[0.2s] ease-linear">
                  <div className="">BIC</div>
                  <button
                    type="button"
                    className="inline-flex justify-end items-center gap-1 bg-transparent hover:opacity-80 text-white transition-opacity duration-[0.2s] ease-linear cursor-pointer outline-none"
                    onClick={() => handleCopy(iban.bic)}
                  >
                    {iban.bic}
                    <Icons
                      nameIcon="copy"
                      className="inline-flex ml-2 w-4 h-4"
                    />
                  </button>
                </div>
                <div className="flex justify-between items-center bg-transparent px-0 py-4 rounded-xl h-12 font-semibold text-gray-lighter text-sm leading-6 tracking-[-0.005em] transition-colors duration-[0.2s] ease-linear">
                  <div className="">Wallet</div>
                  <div className="inline-flex justify-end items-center gap-1 bg-transparent hover:opacity-80 text-white transition-opacity duration-[0.2s] ease-linear cursor-pointer outline-none">
                    <ShortToken
                      original={iban.address || ''}
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center bg-transparent px-0 py-4 rounded-xl h-12 font-semibold text-gray-lighter text-sm leading-6 tracking-[-0.005em] transition-colors duration-[0.2s] ease-linear">
                  <div className="">Chain</div>
                  <div className="inline-flex justify-end items-center gap-1 bg-transparent hover:opacity-80 text-white transition-opacity duration-[0.2s] ease-linear cursor-pointer outline-none">{iban.chain}</div>
                </div>
              </div>
            </div>
          </>
        )}

        {currentStep === 6 && (
          <>
            {/* Order status 2 */}
            <ol className="inline-flex justify-center items-center mx-auto w-full max-w-md">
              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-1/2 after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-[#0052ff] rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0">
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-white"
                  />
                </span>
                <div className="w-full text-[13px] text-white leading-[1.2] tracking-[-0.02em]">
                  Ordered
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-[#0052ff] rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0">
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-white"
                  />
                </span>
                <div className="w-full text-[13px] text-white leading-[1.2] tracking-[-0.02em]">
                  Verifying
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span
                  className="relative z-10 flex justify-center items-center bg-black-light rounded-full w-10 lg:w-12 h-10 lg:h-12 cursor-pointer shrink-0"
                >
                  <Icons nameIcon="loader" className="w-4 h-4 text-white" />
                </span>
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Processing
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-black-light rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0" />
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Sending
                </div>
              </li>
            </ol>
            <div className="relative mt-12 mb-4 w-full text-center">
              <div className="my-4 text-[13px] text-white/70 leading-[1.2] tracking-[-0.02em]">
                Waiting to receive your {buyValue} EURO into your Investing IBAN
              </div>
            </div>
          </>
        )}

        {currentStep === 7 && (
          <>
            {/* Order status 3 */}
            <ol className="inline-flex justify-center items-center mx-auto w-full max-w-md">
              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-1/2 after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-[#0052ff] rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0">
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-white"
                  />
                </span>
                <div className="w-full text-[13px] text-white leading-[1.2] tracking-[-0.02em]">
                  Ordered
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-[#0052ff] rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0">
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-white"
                  />
                </span>
                <div className="w-full text-[13px] text-white leading-[1.2] tracking-[-0.02em]">
                  Verifying
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-[#0052ff] rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0">
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-white"
                  />
                </span>
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Processing
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span
                  className="relative z-10 flex justify-center items-center bg-black-light rounded-full w-10 lg:w-12 h-10 lg:h-12 cursor-pointer shrink-0"
                >
                  <Icons nameIcon="loader" className="w-4 h-4 text-white" />
                </span>
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Sending
                </div>
              </li>
            </ol>

            <div className="relative mt-12 mb-4 w-full text-center">
              <div className="my-4 text-[13px] text-white/70 leading-[1.2] tracking-[-0.02em]">
                You received your {buyValue} Euro in your Investing Iban, {buyValue} EURe are
                minted to your wallet.
              </div>
            </div>
          </>
        )}
      </div>
    </ModalWide>
  );
};

export default IBANDetail;
