import React, { useState, useEffect } from "react";
import cn from 'classnames';
import { useFormContext } from "react-hook-form";

import ListTokens from "./ListTokens";
import tokens from "../../../../../services/dummyDataToken";

import styles from "./Style.module.scss"

const FieldSell = ({ isLogin, value, onChange, balanceAfterTopup, readOnly, sellToken, setSellToken, tokenBalances }) => {
  const [isFocused, setIsFocused] = useState(false);
  const allowedTokens = tokenBalances || [];
  const [currentToken, setCurrentToken] = useState(allowedTokens.find((token) => token.symbol.toLowerCase() === (sellToken.symbol || '').toLowerCase() && token.network === sellToken.network));

  useEffect(() => {
    if (sellToken.symbol) {
      setCurrentToken(allowedTokens.find((token) => token.symbol.toLowerCase() === (sellToken.symbol || '').toLowerCase() && token.network === sellToken.network));
    }
  }, [allowedTokens, sellToken]);

  return (
    <div
      className={cn(
        styles.wrapper,
        "relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent",
        isFocused ? "before:border-white/20" : "before:border-transparent"
      )}
    >
      <div className="font-sans text-[13px] text-gray-custom leading-[1.2] tracking-[-0.02em]">
        Sell
      </div>

      <div className="flex flex-row flex-wrap justify-between items-center mt-2">
        <div
          className={cn(
            'flex flex-1',
            isLogin ? "flex-col flex-wrap justify-start items-start" : ""
          )}
        >
          <input
            name="amount"
            type="number"
            step="any"
            className={cn(
              "relative flex-auto bg-transparent opacity-100 p-0 border-none w-0 max-h-11 text-4xl text-ellipsis text-left text-white placeholder:text-[#6F737E] transition-opacity duration-[250ms] overflow-hidden pointer-events-auto ease-[ease-in-out] outline-none font-sans",
              isLogin ? "w-full" : ""
            )}
            placeholder="0"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />

          {isLogin && currentToken && (
            <div className="mt-[6px] font-sans text-[13px] text-white/70 leading-[1.2] tracking-[-0.02em]">
              {value} {currentToken.symbol.toUpperCase()}
            </div>
          )}
        </div>

        <div
          className={cn(
            isLogin ? "flex flex-col justify-start items-end" : ""
          )}
        >
          <ListTokens readOnly={readOnly} sellToken={sellToken} setSellToken={setSellToken} tokenBalances={tokenBalances} />

          {isLogin && currentToken && (
            <div className="mt-[6px] w-full font-sans text-[13px] text-white/70 text-right leading-[1.2] tracking-[-0.02em]">
              Balance: &nbsp;
              {currentToken && <>{currentToken.balance || 0} {currentToken.symbol.toUpperCase()}</>}
            </div>
          )}
        </div>
      </div>

      <div className="m-0 min-w-0 font-bold text-[#6F737E] text-sm tracking-[-0.01em]">
        {/* 100 EURO */}
      </div>
    </div>
  );
};

export default FieldSell;
