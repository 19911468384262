import React, { useState, useCallback } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// Inner
import { FieldBuy, FieldSell } from "../FieldBuySell";
// import FetchSpendingCapInvestment from "../../../../components/FetchSpendingCapInvestment";
import DetailItem from "./DetailItem";

const motionConfig = {
  initial: { opacity: 0, y: 4 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -4 },
  transition: { duration: 0.2 }
};

const SkeletonLoading = () => (
  <div className='relative flex flex-row flex-wrap justify-start items-center gap-1 [&>span]:w-full'>
    <Skeleton width={100} height={117.18} className="!bg-gray-custom/25 opacity-25 !rounded-2xl !w-full" />
  </div>
)

const StepBuyAndSell = ({
  isLogin,
  buyValue,
  sellValue,
  handleBuyChange,
  handleSellChange,
  balanceAfterTopup,
  fetchingData,
  fetchingRateAndPrice,
  buyToken,
  sellToken,
  setBuyToken,
  setSellToken,
  tokenBalances,
  buyDetails,
  predefinedSwapBuy,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const detailItems = useCallback(() => [
    {
      label: `User Interface Fee (${buyDetails.feePercentage * 100}%)`,
      value: `${buyDetails.uiFee > 0 ? +buyDetails.uiFee.toFixed(8) : '0'} ${buyDetails.from.symbol}`
    },
    {
      label: "Network Fee",
      value: "Free sponsored by Investhub Genossenschaft",
      valueClass: "font-bold text-[#0052ff] text-sm"
    },
    buyDetails.swapFee ? {
      label: buyDetails.swapFeePercentage ? `Swap Fee (${+(buyDetails.swapFeePercentage * 100).toFixed(6)}%)` : 'Swap Fee',
      value: `${buyDetails.swapFee > 0 ? +buyDetails.swapFee.toFixed(8) : '0'} ${buyDetails.from.symbol}`
    } : null,
    buyDetails.relayFeeTotal ? {
      label: 'Destination Network Fee',
      value: `${buyDetails.relayFeeTotal > 0 ? +buyDetails.relayFeeTotal.toFixed(8) : '0'} ${buyDetails.from.symbol}`
    } : null,
    // {
    //   label: "Total",
    //   value: `${buyDetails.totalAmount > 0 ? +buyDetails.totalAmount.toFixed(8) : '0'} ${buyDetails.from}`
    // }
  ], [buyDetails]);

  return (
    <>
      <FieldSell
        isLogin={isLogin}
        onChange={handleSellChange}
        value={sellValue}
        sellToken={sellToken}
        setSellToken={setSellToken}
        tokenBalances={tokenBalances}
      />
      {/* <FetchSpendingCapInvestment value={investValue} network={network} /> */}
      <div className="w-full h-2" />
      <AnimatePresence mode="wait">
        {fetchingData || fetchingRateAndPrice ? (
          <motion.div
            key="skeleton"
            {...motionConfig}
          >
            <SkeletonLoading />
          </motion.div>
        ): (
          <motion.div
            key="content"
            {...motionConfig}
          >
            <FieldBuy
              isLogin={isLogin}
              onChange={handleBuyChange}
              value={buyValue}
              balanceAfterTopup={balanceAfterTopup}
              buyToken={buyToken}
              setBuyToken={setBuyToken}
              tokenBalances={tokenBalances}
              sellToken={sellToken}
              predefinedSwapBuy={predefinedSwapBuy}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="w-full h-2" />

      { buyDetails && buyDetails.feePercentage && buyDetails.from && buyDetails.to && (
        <>
          <div className="relative flex flex-row flex-nowrap justify-center items-center mt-4 overflow-hidden">
            <div className="flex-1 bg-white/20 w-full h-[2px]" />
            <div
              className="inline-flex relative px-4 font-bold text-white hover:text-white/60 text-xs text-center uppercase transition-all duration-[0.2s] cursor-pointer"
              onClick={toggleDetails}
            >
              {!showDetails ? <>See Details</> : <>See Less</>}
            </div>
            <div className="flex-1 bg-white/20 w-full h-[2px]" />
          </div>
          <div className="w-full h-4" />

          <AnimatePresence>
            {showDetails && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="text-white detail"
              >
                <div className="pt-4 w-full">
                  {detailItems().filter(item => item !== null).map((item, index) => (
                    <React.Fragment key={index}>
                      <DetailItem {...item} />
                      <div className="w-full h-3" />
                    </React.Fragment>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
      <div className="w-full h-4" />
    </>
  );
};

export default StepBuyAndSell;
