// src/components/layouts/Wrapper/Dashboard/Sidebar/Navigation/index.jsx

import React from "react"
import { Tooltip } from "react-tooltip"
import { Link, useLocation } from "react-router-dom"
import { UseIsMax767 } from "../../../../shared/MediaQueries"

// Shared
import Icons from "../../../../shared/Icons"
import { DASHBOARD_NAV_ITEMS } from "../../../../../constant/navigationPrimary"
import { useMe } from "../../../../../myHooks/useMe"

const Navigation = ({ isCollapsed }) => {
  const location = useLocation()
  const isMobile = UseIsMax767()
  const { data: { me: user } = {} } = useMe()

  const handleNavClick = (e) => {
    if (isMobile && !isCollapsed) {
      const hamburgerButton = document.querySelector('[data-hamburger-toggle]')
      if (hamburgerButton) {
        hamburgerButton.click()
      }
    }
  }

  return (
    <div className="flex flex-col space-y-2 mb-auto">
      {DASHBOARD_NAV_ITEMS.filter(item => !item.roles || item.roles.includes(user && user.account_type)).map((item) => (
        <Link
          key={item.path}
          to={item.path}
          onClick={handleNavClick}
          className={`rounded-2xl flex px-4 py-4 flex-wrap transition-all duration-[0.2s] hover:text-white ${location.pathname === item.path ? 'bg-white/20 text-white' : 'bg-transparent text-white/70'} ${isCollapsed ? 'justify-center items-center' : 'justify-start items-start'}`}
          data-tooltip-id={item.tooltipId}
        >
          <Icons
            nameIcon={item.icon}
            className="w-6 h-6"
          />
          {!isCollapsed && (
            <span className="top-[1px] relative ml-3 font-semibold text-sm leading-6 tracking-[-0.02em]">
              {item.label}
            </span>
          )}
          {isCollapsed && (
            <Tooltip id={item.tooltipId} auto effect="solid" className="!inline-flex !justify-center !items-center !bg-white/10 !px-2 !py-[2px] !rounded-md font-sans !font-bold !text-white !text-xs">
              {item.label}
            </Tooltip>
          )}

        </Link>
      ))}
    </div>
  )
}

export default Navigation
