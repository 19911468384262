import { useCallback, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import metamaskService from '../services/metamask'
import { isProduction } from '../utils'

export const REQUEST_NONCE = gql`
  mutation RequestMetamaskNonce($address: String!) {
    requestMetamaskNonce(address: $address)
  }
`;

export const METAMASK_LOGIN = gql`
  mutation MetamaskLogin($input: MetamaskLoginInput) {
    metamaskLogin(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const METAMASK_REGISTER = gql`
  mutation MetamaskRegister($input: MetamaskRegisterInput) {
    metamaskRegister(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const useMetamaskAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [account, setAccount] = useState(null);
  const [signature, setSignature] = useState(null);

  const [requestNonce] = useMutation(REQUEST_NONCE);
  const [metamaskLogin] = useMutation(METAMASK_LOGIN);
  const [metamaskRegister] = useMutation(METAMASK_REGISTER);

  const connect = useCallback(async (signupData) => {
    setLoading(true);
    setError(null);

    try {
      // await metamaskService.requireNetwork(isProduction ? 'mainnet' : 'base_sepolia', () => {
      //   window.location.reload()
      // })

      // Check if Metamask is installed
      await metamaskService.requestAccounts()

      const account = metamaskService.getCurrentAddress()

      setAccount(account);

      if (!account) {
        throw new Error('Failed to connect to wallet');
      }

      // Get nonce from backend
      const { data: nonceData } = await requestNonce({
        variables: { address: account }
      });

      // Create message to sign
      const message = `Welcome to Investhub.io!\n\nTo access features related to security tokens, simply click the "Sign" button—no username or password is required, and this action will not incur any gas fees.\n\nBy clicking "Sign," you agree to Investhub Genossenschaft LVC's Terms and Conditions and confirm that you have read and understood their Privacy Policy.\n\nURI:\napp.investhub.io\n\nNonce: ${nonceData.requestMetamaskNonce}`;

      // Request signature from user
      const signature = await metamaskService.signMessage(account, message);

      console.log('signature', signature);

      if (signupData) {
        // Login with signature
        const { data: loginData } = await metamaskRegister({
          variables: {
            input: {
              address: account,
              accountType: (signupData || {}).accountType,
              referralModel: (signupData || {}).referralModel,
              signature,
              loginProvider: 'metamask'
            }
          }
        });

        return loginData;
      }

      setSignature(signature);

      return { signature, account };
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [requestNonce, metamaskRegister]);

  const loginWithSignature = useCallback(async (twoFaToken) => {
    setLoading(true);
    setError(null);

    try {
      // Login with signature
      const { data: loginData } = await metamaskLogin({
        variables: {
          input: {
            address: account,
            signature,
            twoFaToken
          }
        }
      });

      return loginData;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [metamaskLogin, account, signature]);

  return {
    connect,
    loginWithSignature,
    loading,
    error,
    requestNonce,
    metamaskLogin,
    metamaskRegister,
  };
};

export default useMetamaskAuth
