import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import Icons from '../../../../components/shared/Icons'
import { ModalWide } from '../../../../components/shared/Modals'
import { useMe } from '../../../../myHooks'
import { useLocation, useHistory } from 'react-router-dom'
// Inner
import SetupTwoFactorAuth from './SetupTwoFactorAuth'
import DisableTwoFactorAuth from './DisableTwoFactorAuth'

const SectionTwoFactorSkeleton = () => (
  <div className='relative flex flex-row flex-wrap justify-start items-center gap-1 [&>span]:w-full'>
    <Skeleton width={100} height={12} className="!bg-gray-custom/25 opacity-25 !rounded-2xl !w-full" />
    <Skeleton width={100} height={20} className="!bg-gray-custom/25 opacity-25 !rounded-2xl !w-full" />
  </div>
)

const TwoFactorAuth = () => {
  const { t } = useTranslation()
  const [is2FaDialogOpened, setIs2FaDialogOpened] = useState(false)
  const { data: { me } = {}, loading, refetch } = useMe({ fetchPolicy: 'network-only' })
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (!me) return

    const params = new URLSearchParams(location.search);
    const twoFa = params.get('twoFa');

    if (twoFa === 'true') {
      if (!me.isTwoFaEnabled) {
        setIs2FaDialogOpened(true)
      } else {
        history.push('/dashboard')
      }
    }
  }, [location.search, me, history])

  if (loading) return <SectionTwoFactorSkeleton />

  return (
    <>
      <div>
        <div className="relative flex flex-row flex-wrap justify-start items-center gap-1 mb-4 pb-4 border-b border-b-white/10 border-solid">
          {/* Label */}
          <div className={`flex font-sans font-semibold flex-row flex-wrap flex-1 gap-1 text-[13px] leading-[1.2] tracking-[-0.02em] transition-colors duration-[0.2s] ${me.isTwoFaEnabled ? 'text-white' : 'text-gray-lighter'}`}>
            {t('settings.security.google_authenticator')}
            {/* <span
              className="top-[1px] relative cursor-pointer"
              data-tooltip-id='google-authenticator'
            >
              <Icons
                nameIcon="alert-circle"
                className="w-[14px] h-[14px]"
              />
            </span> */}

            <Tooltip
              id="google-authenticator"
              auto
              effect="solid"
              className="!inline-flex !justify-center !items-center !bg-black-light !px-2 !py-[2px] !rounded-md !font-sans !font-bold !text-white !text-xs"
            >
              {me.isTwoFaEnabled ? t('settings.security.google_authenticator_enabled') : t('settings.security.google_authenticator_disabled')}
            </Tooltip>
          </div>

          {/* Checkbox */}
          <label className="inline-block relative ml-auto cursor-pointer">
            <input
              type="checkbox"
              className="absolute opacity-0 w-0 h-0"
              onChange={() => setIs2FaDialogOpened(true)}
              disabled={me.isTwoFaEnabled}
              checked={me.isTwoFaEnabled}
            />
            <span className={`flex items-center justify-center transition-all duration-[0.2s] ${me.isTwoFaEnabled ? 'text-white' : 'text-gray-lighter'}`}>
              <span className={`block relative border-2 border-white/10 bg-[#1e2025] border-solid rounded-3xl w-12 h-6 transition-all duration-[0.2s] toggle-checkbox__box before:content-[""] before:absolute  before:w-5 before:h-5 before:transition-all before:duration-[0.2s] before:rounded-[50%] before:left-0.5 before:top-2/4 ${me.isTwoFaEnabled ? 'before:bg-primary before:translate-x-6 before:-translate-y-2/4' : 'before:bg-white before:-translate-y-2/4 '}`} />
            </span>
          </label>

          {/* Description */}
          <span className="block mt-1 font-sans text-gray-lighter text-xs tracking-[-0.02em]">
            {t('settings.security.google_authenticator_description')}
          </span>
        </div>
      </div>

      <ModalWide
        isOpen={is2FaDialogOpened}
        onClose={() => setIs2FaDialogOpened(false)}
        className="investhub-modal investhub-modal__m"
        title={me.isTwoFaEnabled ? t('Two-Factor Authentication') : t('settings.security.setup_google_authenticator')}
        subtitle={!me.isTwoFaEnabled ? t('settings.security.setup_google_authenticator_description') : null }
        customClassModal="!max-w-[480px]"
      >
        {!me.isTwoFaEnabled && (
          <SetupTwoFactorAuth
            onClose={() => setIs2FaDialogOpened(false)}
            onComplete={async () => {
              await refetch()
              setIs2FaDialogOpened(false)
            }}
          />
        )}
        {me.isTwoFaEnabled && (
          <DisableTwoFactorAuth
            onClose={() => setIs2FaDialogOpened(false)}
            onComplete={async () => {
              await refetch()
              setIs2FaDialogOpened(false)
            }}
          />
        )}
      </ModalWide>
    </>
  )
}

export default TwoFactorAuth
