/* eslint-disable import/no-extraneous-dependencies */
import { http, useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';
import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors';
import { useCapabilities, useWriteContracts } from 'wagmi/experimental';
import contractAbi from '../../../../../constants/smartContract';
import { base, baseSepolia, sepolia, mainnet, polygon } from '@wagmi/core/chains'
import { WC_PROJECT_ID } from '../../../../../config'
import { useWagmiConfig } from '../../../../../myHooks/useWagmi'
import { getAccount, createConfig, getTransaction } from '@wagmi/core'
import { isProduction } from '../../../../../utils';
import { getCoinAddress, getCollectingFeeAddress, STABLE_COIN_ENUM } from '../../../../../myHooks/useWeb3Provider';

export const useCoinbaseTrans = () => {
  const wagmiConfig = useWagmiConfig();

  const { address, isConnected } = useAccount();
  const { connect } = useConnect();
  const { disconnect } = useDisconnect();
  const [isProcessing, setIsProcessing] = useState(false);
  const { signMessageAsync } = useSignMessage();

  // Configure `useWriteContracts` to call the mint function on the contract
  const { writeContractsAsync } = useWriteContracts();

  // Check for paymaster capabilities with `useCapabilities`
  const { data: availableCapabilities, isLoading } = useCapabilities({
    account: address,
  });

  const availableCapabilitiesRef = useRef(null);

  useEffect(() => {
    if (availableCapabilities) {
      availableCapabilitiesRef.current = availableCapabilities;
    }
  }, [availableCapabilities]);

  const handleApprove = async (value, buyDetails, network) => {
    console.log('buyDetails', buyDetails)
    console.log('network', network)

    if (!isConnected) {
      await wagmiConfig.connectors[0].connect();
      await connect({ connector: wagmiConfig.connectors[0] })
    }

    setIsProcessing(true);
    const coinAddress = buyDetails.from.tokenAddress

    const contractAddress = getCollectingFeeAddress(network);

    const abi = [
      {
        "constant": false,
        inputs: [
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' }
        ],
        name: 'approve',
        outputs: [
          { name: '', type: 'bool' }
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
    ];

    try {
      console.log('before writeContracts')

      const capabilitiesForChain = availableCapabilitiesRef.current
        ? availableCapabilitiesRef.current[isProduction ? base.id : baseSepolia.id]
        : null;

      const dynamicCapabilities = capabilitiesForChain && capabilitiesForChain.paymasterService.supported
      ? {
          paymasterService: {
            url: process.env.REACT_APP_PAYMASTER_AND_BUNDLER_ENDPOINT,
          },
        }
      : {};

      console.log('dynamicCapabilities', dynamicCapabilities);
      console.log('network', network)
      console.log('coinAddress', coinAddress)

      let chainId = isProduction ? base.id : baseSepolia.id;

      if (network === 'mainnet') {
        chainId = isProduction ? mainnet.id : sepolia.id;
      } else if (network === 'mainnet-only') {
        chainId = mainnet.id;
      } else if (network === 'polygon') {
        chainId = isProduction ? polygon.id : '80002'; // amoy
      }

      const result = await writeContractsAsync({
        contracts: [
          {
            address: coinAddress,
            abi,
            functionName: 'approve',
            args: [contractAddress, BigInt(value).toString()],
          },
        ],
        chainId,
        capabilities: dynamicCapabilities,
        options: {
          paymasterService: dynamicCapabilities.paymasterService,
        },
      });

      setIsProcessing(false);
      return result; // Return the hash instead of the full result
    } catch (error) {
      console.error('Approving failed:', error);
      setIsProcessing(false);

      return null;
    }
  };

  const handleSignMessage = async (message) => {
    console.log('address', address);

    console.log('isConnected', isConnected)

    if (!isConnected) {
      console.log('connect')
      console.log('wagmiConfig.connectors', wagmiConfig.connectors[0])
      await wagmiConfig.connectors[0].connect();
      await connect({ connector: wagmiConfig.connectors[0] })
    }

    try {
      console.log('before handleSignMessage')
      const result = await signMessageAsync({
        message,
      })

      setIsProcessing(false);
      return result; // Return the hash instead of the full result
    } catch (error) {
      console.error('Approving failed:', error);
      setIsProcessing(false);

      return null;
    }
  };

  const handleSwapToken = async (buyDetails, network, amountOutMin) => {
    console.log('handleSwapToken');
    setIsProcessing(true);
    const contractAddress = getCollectingFeeAddress(network);

    const capabilitiesForChain = availableCapabilitiesRef.current
      ? availableCapabilitiesRef.current[isProduction ? base.id : baseSepolia.id]
      : null;

    const dynamicCapabilities = capabilitiesForChain && capabilitiesForChain.paymasterService.supported
      ? {
          paymasterService: {
            url: process.env.REACT_APP_PAYMASTER_AND_BUNDLER_ENDPOINT,
          },
        }
      : {};

    try {
      console.log('dynamicCapabilities', dynamicCapabilities);

      let chainId = isProduction ? base.id : baseSepolia.id;

      if (network === 'mainnet') {
        chainId = isProduction ? mainnet.id : sepolia.id;
      } else if (network === 'mainnet-only') {
        chainId = mainnet.id;
      } else if (network === 'polygon') {
        chainId = isProduction ? polygon.id : '80002'; // amoy
      }

      let result;
      console.log('000000')

      if (buyDetails.from.network !== buyDetails.to.network) {
        console.log('cross chain transfer')

        const response = await fetch(`https://app.across.to/api/suggested-fees?inputToken=${buyDetails.from.tokenAddress}&outputToken=${buyDetails.to.tokenAddress}&originChainId=${buyDetails.from.chainIndex}&destinationChainId=${buyDetails.to.chainIndex}&amount=${Math.ceil(buyDetails.amountIn * (10 ** buyDetails.amountInDecimals))}&recipient=${address}&relayer=0x0000000000000000000000000000000000000000`);

        const data = await response.json();

        console.log('data', data)

        if (data.status === 400) {
          throw new Error(data.message);
        }

        const amountIn = Math.ceil(buyDetails.amountIn * (10 ** buyDetails.amountInDecimals))

        result = await writeContractsAsync({
          contracts: [
            {
              address: contractAddress,
              abi: contractAbi.feeContractAbi,
              functionName: 'crossChainTransferWithFee',
              args: [
                address,
                buyDetails.tokenInAddress,
                buyDetails.tokenOutAddress,
                amountIn,
                Math.ceil(buyDetails.amountOut * (10 ** buyDetails.amountOutDecimals)),
                buyDetails.to.chainIndex,
                '0x0000000000000000000000000000000000000000',
                data.timestamp,
                data.fillDeadline,
                '0',
              ],
              value: ['eth', 'pol'].includes(buyDetails.from.symbol.toLowerCase()) ? amountIn : 0,
            },
          ],
          chainId,
          capabilities: dynamicCapabilities,
          options: {
            paymasterService: dynamicCapabilities.paymasterService,
          },
        });
      } else if (['eth', 'pol'].includes(buyDetails.to.symbol.toLowerCase())) {
        result = await writeContractsAsync({
          contracts: [
            {
              address: contractAddress,
              abi: contractAbi.feeContractAbi,
              functionName: 'swapToETHWithFee',
              args: [
                buyDetails.tokenInAddress,
                buyDetails.amountIn * (10 ** buyDetails.amountInDecimals),
                amountOutMin,
                buyDetails.feeTier,
                address,
              ],
              value: 0,
            },
          ],
          chainId,
          capabilities: dynamicCapabilities,
          options: {
            paymasterService: dynamicCapabilities.paymasterService,
          },
        });
      } else {
        console.log('11111')
        const amountIn = Math.ceil(buyDetails.amountIn * (10 ** buyDetails.amountInDecimals))
        console.log('22222')

        result = await writeContractsAsync({
          contracts: [
            {
              address: contractAddress,
              abi: contractAbi.feeContractAbi,
              functionName: 'swapWithFee',
              args: [
                buyDetails.tokenInAddress,
                buyDetails.tokenOutAddress,
                amountIn,
                amountOutMin,
                buyDetails.feeTier,
                address,
                buyDetails.path || '0x',
              ],
              value: ['eth', 'pol'].includes(buyDetails.from.symbol.toLowerCase()) ? BigInt(amountIn).toString() : 0,
            },
          ],
          chainId,
          capabilities: dynamicCapabilities,
          options: {
            paymasterService: dynamicCapabilities.paymasterService,
          },
        });

        console.log('33333')
      }

      setIsProcessing(false);
      return result;
    } catch (error) {
      console.error('Swap token failed:', error);
      setIsProcessing(false);

      return null;
    }
  };

  // useEffect(() => {
  //   console.log('xxxxx')
  //   const fetchTrans = async () => {
  //     const transaction = await getTransaction(wagmiConfig, {
  //       hash: '0x60e447c6f4f58d62e0fd52e2dd080ea6abf1b0895104d2a146fad8deeea8db4c0000000000000000000000000000000000000000000000000000000000014a34',
  //       index: 0,
  //       chainId: BASE_SEPOLIA_CHAIN_ID,
  //     });

  //     console.log('transaction', transaction)
  //   }

  //   fetchTrans();
  // }, [wagmiConfig])

  return {
    isConnected,
    connect,
    disconnect,
    handleApprove,
    isProcessing,
    handleSignMessage,
    handleSwapToken,
  }
}

export default useCoinbaseTrans;
