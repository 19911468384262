import React, { useState, useEffect, useCallback } from "react";
import { useFormContext } from "react-hook-form"
import Avatar from "react-avatar";
import { ethers } from "ethers";
import { motion, AnimatePresence } from "framer-motion";
import { CheckboxField } from "../../../../components/shared/Fields";
import { FieldBuy, FieldInvest } from "../../FieldBuyInvest";
import { useWeb3Provider, getCollectingFeeAddress, getCoinAddress, COIN_DECIMALS } from "../../../../myHooks/useWeb3Provider";
import useFeeSwap from "../../../../myHooks/useFeeSwap";
import { DASHBOARD_DATA_TOKEN_EXCEL } from "../../../../constant/dataTokenFromExcel"
import { Web3 } from 'web3';
import { useMe } from '../../../../myHooks';
import contractAbi from '../../../../constants/smartContract';

// Inner
import DetailItem from "./DetailItem";

const STABLE_COIN_ENUM = {
  dai: 1,
  usdc: 2,
  eure: 3,
  usdt: 4,
  busd: 5,
}

const StepDetailBuyInvest = ({
  buyValue,
  investValue,
  handleInvestChange,
  isLogin,
  setCurrentIssuer,
  currentIssuer,
  balanceAfterTopup,
  onCheckboxChange,
  buyDetails,
  tokenBalances,
}) => {
  const { watch, getValues } = useFormContext();
  const [showDetails, setShowDetails] = useState(false);
  const [issuerDetails, setIssuerDetails] = useState(null);
  const [networkFee, setNetworkFee] = useState(null);
  const { provider } = useWeb3Provider(currentIssuer.network, currentIssuer.id);
  const web3 = new Web3(provider);
  const { status: feeStatus } = useFeeSwap(provider, currentIssuer.network);
  const { data: { me: user } = {} } = useMe()

  useEffect(() => {
    const details = DASHBOARD_DATA_TOKEN_EXCEL.find(
      token => token.ticker === currentIssuer.coin_name
    );
    setIssuerDetails(details);
  }, [currentIssuer]);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const checkboxItems = [
    {
      name: 'purchase-agreement',
      label: (
        <>
          I have read, fully understood and agree to the bounded by the{" "}
          <a
            href="/agreement"
            className="hover:opacity-80 font-bold text-white transition-all duration-[0.2s]"
          >
            Purchase agreement
          </a>{" "}
          in respect of this token sale
        </>
      ),
    },
    {
      name: 'support-document',
      label: (
        <>
          I have read fully understood and agree to the bound by the other
          relevant agreements here in respect of this token sale.{" "}
          <a
            href="/document"
            className="hover:opacity-80 font-bold text-white transition-all duration-[0.2s]"
          >
            Support Document
          </a>
        </>
      ),
    },
  ];

  console.log('calculateFees buyDetails', buyDetails)

  const detailItems = useCallback(() => [
    {
      label: `User Interface Fee (${buyDetails.feePercentage * 100}%)`,
      value: `${buyDetails.uiFee > 0 ? +buyDetails.uiFee.toFixed(8) : '0'} ${buyDetails.currency}`
    },
    {
      label: "Network Fee",
      value: "Free sponsored by Investhub Genossenschaft",
      valueClass: "font-bold text-[#0052ff] text-sm"
    },
    buyDetails.swapFeePercentage ? {
      label: `Swap Fee (${buyDetails.swapFeePercentage * 100}%)`,
      value: `${buyDetails.swapFee > 0 ? +buyDetails.swapFee.toFixed(8) : '0'} ${buyDetails.currency}`
    } : null,
    {
      label: "Total",
      value: `${buyDetails.totalAmount > 0 ? +buyDetails.totalAmount.toFixed(8) : '0'} ${buyDetails.currency}`
    }
  ], [buyDetails]);

  const keyInfoItems = [
    {
      label: "Raised",
      value: (issuerDetails && issuerDetails.committed) ? `€ ${issuerDetails.committed}` : "-"
    },
    {
      label: "Target",
      value: (issuerDetails && issuerDetails.target) || "-",
      valueClass: "font-bold text-green-500 text-sm",
    },
    {
      label: "Equity",
      value: `${(issuerDetails && issuerDetails.equity) || "-"}`
    },
    {
      label: "Pre-Money Valuation",
      value: (issuerDetails && issuerDetails.valuation) ? `€ ${issuerDetails.valuation}` : "-"
    },
    {
      label: "Country",
      value: typeof (issuerDetails && issuerDetails.incorporation_country) === 'object'
        ? (issuerDetails && issuerDetails.incorporation_country && issuerDetails.incorporation_country.text)
        : ((issuerDetails && issuerDetails.incorporation_country) || "-")
    }

  ];

  // Watch checkbox values
  const purchaseAgreement = watch('purchase-agreement', false);
  const supportDocument = watch('support-document', false);

  // Pass allChecked status to parent
  useEffect(() => {
    const allChecked = purchaseAgreement && supportDocument;
    onCheckboxChange(allChecked);
  }, [purchaseAgreement, supportDocument, onCheckboxChange]);

  const getCompanyCountry = () => {
    if (!issuerDetails || !issuerDetails.incorporation_country) return "-";
    return typeof issuerDetails.incorporation_country === 'object'
      ? issuerDetails.incorporation_country.text
      : issuerDetails.incorporation_country;
  };

  return (
    <>
      <FieldInvest
        isLogin={isLogin}
        onChange={handleInvestChange}
        value={investValue}
        setCurrentIssuer={setCurrentIssuer}
        currentIssuer={currentIssuer}
        readOnly
      />
      <div className="w-full h-2" />
      <FieldBuy
        isLogin={isLogin}
        onChange={handleInvestChange}
        value={buyValue}
        balanceAfterTopup={balanceAfterTopup}
        currentIssuer={currentIssuer}
        tokenBalances={tokenBalances}
        readOnly
      />
      <div className="w-full h-2" />

      <div className="relative flex flex-row flex-nowrap justify-center items-center mt-4 overflow-hidden">
        <div className="flex-1 bg-white/20 w-full h-[2px]" />
        <div
          className="inline-flex relative px-4 font-bold text-white hover:text-white/60 text-xs text-center uppercase transition-all duration-[0.2s] cursor-pointer"
          onClick={toggleDetails}
        >
          {!showDetails ? <>See Details</> : <>See Less</>}
        </div>
        <div className="flex-1 bg-white/20 w-full h-[2px]" />
      </div>
      <div className="w-full h-4" />

      {checkboxItems.map((item, index) => (
        <React.Fragment key={index}>
          <CheckboxField name={item.name} label={item.label} useForm={true} />
          {index < checkboxItems.length - 1 && <div className="w-full h-2" />}
        </React.Fragment>
      ))}

      <AnimatePresence>
        {showDetails && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="text-white detail"
          >
            <div className="pt-4 w-full">
              {detailItems().filter(item => item !== null).map((item, index) => (
                <React.Fragment key={index}>
                  <DetailItem {...item} />
                  <div className="w-full h-3" />
                </React.Fragment>
              ))}

              <div className="m-0 mt-6 mb-4 w-full min-w-0 overflow-hidden font-bold text-white text-base text-ellipsis text-nowrap leading-6 tracking-[-0.01em] whitespace-nowrap">
                Key Information
              </div>

              <div className="flex max-480:flex-col justify-between max-480:justify-start items-center max-480:items-start gap-2 max-480:gap-0 w-full">
                <div className="text-left">
                  <div className="mb-2 text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">
                    Company
                  </div>

                  <div className="flex justify-start items-start gap-2">
                    <Avatar
                      name={(issuerDetails && issuerDetails.company) || currentIssuer.coin_name}
                      size="40"
                      round={true}
                      className="rounded-full"
                    />

                    <div className="flex flex-col flex-nowrap justify-start items-start gap-1">
                      <div className="font-bold text-white text-sm">
                      {(issuerDetails && issuerDetails.company) || "-"}
                      </div>
                      <div className="mb-2 text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">
                        {getCompanyCountry()}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 max-480:text-left text-right">
                  <div className="mb-2 max-480:mb-0 text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">
                    Industry
                  </div>
                  <div className="font-bold text-white text-sm">
                    {(issuerDetails && issuerDetails.sectors) || "-"}
                  </div>
                </div>
              </div>
              <div className="w-full h-3" />

              {keyInfoItems.map((item, index) => (
                <React.Fragment key={index}>
                  <DetailItem {...item} />
                  <div className="w-full h-3" />
                </React.Fragment>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="w-full h-4" />
    </>
  );
};
export default StepDetailBuyInvest;
