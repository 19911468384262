/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useState, useCallback } from 'react';
import { ethers } from 'ethers';
import { COIN_DECIMALS, getCoinAddress } from './useWeb3Provider';
// eslint-disable-next-line import/no-extraneous-dependencies
import { uniswapV3PoolABI } from '../constants/smartContract';

const useFeeSwap = (provider) => {
  const [status, setStatus] = useState('');

  const feeRouterAddress = '0xfe6354b14f12c709D68F9A27590dA93B35A91407';

  const getUniswapV3Factory = (network) => {
    switch (network) {
      case 'base':
        return '0x33128a8fC17869897dcE68Ed026d694621f6FDfD';
      case 'polygon':
        return '0x1F98431c8aD98523631AE4a59f267346ea31F984';
      default:
        return '0x33128a8fC17869897dcE68Ed026d694621f6FDfD';
    }
  };

  const feeRouterABI = [
    "function swapWithFee(address tokenIn, address tokenOut, uint256 amountIn, uint256 amountOutMin, uint24 fee, address recipient) external"
  ];

  const factoryABI = [
    "function getPool(address tokenIn, address tokenOut, uint24 fee) view returns (address)",
  ];

  const getFeeTier = async (tokenInAddress, tokenOutAddress, signer, network) => {
    try {
      const feeTiers = [100, 500, 3000, 10000]; // Check different fee tiers
      let selectedFeeTier = 0;
      const uniswapV3Factory = getUniswapV3Factory(network);

      console.log('uniswapV3Factory', uniswapV3Factory);
      console.log('network', network);

      const factoryContract = new ethers.Contract(uniswapV3Factory, factoryABI, signer);

      // Check for liquidity in each fee tier
      for (const fee of feeTiers) {
        const poolAddress = await factoryContract.getPool(tokenInAddress, tokenOutAddress, fee);

        console.log('poolAddress', poolAddress);

        if (poolAddress !== '0x0000000000000000000000000000000000000000') {
          const poolContract = new ethers.Contract(poolAddress, uniswapV3PoolABI, signer);
          const liquidity = await poolContract.liquidity();

          if (liquidity.gt(0)) {
            selectedFeeTier = fee;
            break;
          }
        }
      }

      if (selectedFeeTier === 0) {
        setStatus('No liquidity available for this token pair.');

        return null;
      }

      return selectedFeeTier;
    } catch (error) {
      console.error('getFeeTier error', error);

      return null;
    }
  };

  const handleSwap = async (tokenIn, tokenOut, amountIn, recipient, network) => {
    if (!tokenIn || !tokenOut || !amountIn || !recipient) {
      setStatus('Please fill in all fields');

      return null;
    }

    console.log('provider', provider);

    console.log('tokenIn', tokenIn)
    console.log('tokenOut', tokenOut)
    console.log('amountIn', amountIn)
    console.log('recipient', recipient)

    try {
      // Initialize ethers.js provider and signer
      const ethersProvider = new ethers.providers.Web3Provider(provider);
      await ethersProvider.send('eth_requestAccounts', []); // Request account access
      const signer = ethersProvider.getSigner();

      // Connect to fee router contract
      const feeRouterContract = new ethers.Contract(feeRouterAddress, feeRouterABI, signer);

      const tokenInAddress = getCoinAddress(tokenIn, network);
      const tokenOutAddress = getCoinAddress(tokenOut, network);
      // const tokenOutAddress = '0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf'; // cbBTC
      // const tokenOutAddress = '0x4200000000000000000000000000000000000006'; // WETH

      const selectedFeeTier = await getFeeTier(tokenInAddress, tokenOutAddress, signer);

      if (selectedFeeTier === 0) {
        setStatus('No liquidity available for this token pair.');

        return null;
      }

      // Approve token transfer to the contract
      const tokenContract = new ethers.Contract(tokenInAddress, [
        "function decimals() view returns (uint8)",
        "function approve(address spender, uint256 amount) public returns (bool)",
        "function allowance(address owner, address spender) public view returns (uint256)",
        "function balanceOf(address account) public view returns (uint256)"
      ], signer);

      const amountInWei = ethers.utils.parseUnits(amountIn.toString(), COIN_DECIMALS[tokenIn]); // Adjust decimals as needed
      setStatus('Approval successful, proceeding with swap...');

      const currentAllowance = await tokenContract.allowance(await signer.getAddress(), feeRouterAddress);
      const balance = await tokenContract.balanceOf(await signer.getAddress());

      if (balance.lt(amountInWei)) {
        throw new Error('Insufficient token balance.');
      }

      console.log('currentAllowance', currentAllowance.toString())
      console.log('amountInWei', amountInWei.toString())
      console.log('balance', balance.toString())

      if (currentAllowance.lt(amountInWei)) {
        await tokenContract.approve(feeRouterAddress, amountInWei);
      }

      console.log('selectedFeeTier', selectedFeeTier)

      // Set swap parameters
      const feeTier = selectedFeeTier; // 0.3% Uniswap V3 fee tier
      const amountOutMin = 0; // No minimum for demo purposes (adjust in production)

      console.log('amountInWei', amountInWei.toString())
      console.log('tokenInAddress', tokenInAddress)
      console.log('tokenOutAddress', tokenOutAddress)
      console.log('recipient', recipient)

      // Execute the swap
      const tx = await feeRouterContract.swapWithFee(
        tokenInAddress,
        tokenOutAddress,
        amountInWei.toString(),
        amountOutMin.toString(),
        feeTier,
        recipient
      );

      setStatus(`Transaction sent: ${tx.hash}`);
      await tx.wait();
      setStatus('Swap completed successfully!');

      return tx.hash;
    } catch (error) {
      console.error('Error during swap:', error);
      setStatus(`Swap failed: ${error.message}`);

      return null;
    }
  };

  return { status, getFeeTier, handleSwap };
};

export default useFeeSwap;
