/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MoneriumClient } from '@monerium/sdk';
import { useMe } from '../../../myHooks';
import { isProduction } from '../../../utils';

import WalletGroup from './WalletGroup';
import Heading from '../Heading';
import Loader from '../../../components/shared/Loader';

const AccountNumber = () => {
  const { t } = useTranslation();
  const [monerium, setMonerium] = useState();
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ibans, setIbans] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [balances, setBalances] = useState({});
  const { data: { me: user } = {} } = useMe();
  const [isAuthorized, setIsAuthorized] = useState(false);

  // Initialize Monerium SDK
  useEffect(() => {
    const sdk = new MoneriumClient({
      environment: isProduction ? 'production' : 'sandbox',
      clientId: process.env.REACT_APP_MONERIUM_AUTHORIZATION_CLIENT_ID,
      redirectUri: process.env.REACT_APP_MONERIUM_PROFILE_REDIRECT_URI
    });

    setMonerium(sdk);
  }, []);

  // Fetch balances for an address on a specific chain
  const fetchBalances = useCallback(async (address, chain) => {
    if (!monerium || !address || !chain) return {};

    try {
      const currencies = ['eur', 'usd', 'gbp'];
      const balancePromises = currencies.map(currency =>
        monerium.getBalances(address, chain, currency)
          .then(balance => ({ [currency]: balance }))
          .catch(() => ({ [currency]: 0 }))
      );

      const results = await Promise.all(balancePromises);
      const combinedBalances = results.reduce((acc, curr) => ({ ...acc, ...curr }), {});

      return combinedBalances;
    } catch (err) {
      console.error('Error fetching balances:', err);
      return {};
    }
  }, [monerium]);

  // Fetch addresses and balances
  const fetchAddressesAndBalances = useCallback(async (profile) => {
    console.log('fetchAddressesAndBalances')

    if (!monerium || !profile) return;

    console.log('profile', profile)

    try {
      setIsLoading(true);
      const { addresses } = await monerium.getAddresses({
        profile: profile.id
      });
      setAddresses(addresses);

      // Fetch balances for all addresses and chains
      const balancesMap = {};
      for (const address of addresses) {
        balancesMap[address.address] = {};
        for (const chain of address.chains) {
          balancesMap[address.address][chain] = await fetchBalances(address.address, chain);
        }
      }
      setBalances(balancesMap);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching addresses and balances:', err);
      setIsLoading(false);
    }
  }, [monerium, fetchBalances]);

  const handleConnect = async () => {
    if (!monerium) return;

    setIsLoading(true);
    try {
      const auth = await monerium.getAccess();

      if (!auth) {
        monerium.authorize({
          clientId: process.env.REACT_APP_MONERIUM_AUTHORIZATION_CLIENT_ID,
          redirectUri: process.env.REACT_APP_MONERIUM_PROFILE_REDIRECT_URI
        });
      } else {
        setIsAuthorized(true);
      }
    } catch (err) {
      console.error('Error connecting to Monerium:', err);
      monerium.authorize({
        clientId: process.env.REACT_APP_MONERIUM_AUTHORIZATION_CLIENT_ID,
        redirectUri: process.env.REACT_APP_MONERIUM_PROFILE_REDIRECT_URI
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const connect = async () => {
      if (monerium && !monerium.isAuthorized && !isAuthorized) {
        const auth = await monerium.getAccess();

        if (auth) {
          setIsAuthorized(true);
        }
      }
    };
    connect();
  }, [monerium, isAuthorized]);

  // Fetch profile and IBANs when authorized
  useEffect(() => {
    const fetchData = async () => {
      if (monerium && monerium.isAuthorized && !profile) {
        try {
          setIsLoading(true);
          const { profiles } = await monerium.getProfiles();
          const userProfile = await monerium.getProfile(profiles[0].id);
          setProfile(userProfile);

          const { ibans } = await monerium.getIbans({
            profile: profiles[0].id
          });
          setIbans(ibans);

          await fetchAddressesAndBalances(userProfile);
          setIsLoading(false);
        } catch (err) {
          console.error('Error fetching data:', err);
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [monerium, isAuthorized, profile, fetchAddressesAndBalances]);

  if (isLoading) {
    return <Loader />;
  }

  if (!monerium || !monerium.isAuthorized) {
    return (
      <>
        <Heading title={t('settings.monerium.title')} />
        <div className="flex flex-col flex-wrap justify-center items-center bg-white/20 px-8 py-12 rounded-2xl w-full text-white text-center">
          <div className='mx-auto w-full max-w-screen-sm'>
            <div className="m-0 font-sans font-semibold text-white text-xl text-center leading-7 tracking-[-0.02em]">
              {t('settings.monerium.connect_description')}
            </div>
          </div>
        </div>

        <div className="mt-6 text-center">
          <button
            onClick={handleConnect}
            className="inline-flex justify-center items-center gap-4 bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-6 py-4 rounded-3xl outline-none overflow-visible font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer"
          >
            {t('settings.monerium.connect_button')}
          </button>
        </div>
      </>
    );
  }

  // Get unique currencies from balances
  const availableCurrencies = [...new Set(
    Object.values(balances).flatMap(chains =>
      Object.values(chains).flatMap(currencies =>
        Object.keys(currencies)
      )
    )
  )].map(curr => curr.toUpperCase());

  return (
    <>
      <Heading title={t('settings.monerium.title')} />

      <div className="space-y-6">
        {availableCurrencies.map(currency => (
          <WalletGroup
            key={currency}
            currency={currency}
            monerium={monerium}
            profile={profile}
            addresses={addresses}
            balances={balances}
            ibans={ibans}
            setIbans={setIbans}
            onRefreshBalances={fetchAddressesAndBalances}
          />
        ))}
      </div>
    </>
  );
};

export default AccountNumber;
