import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import Icons from '../../../../components/shared/Icons';

const TextField = ({
  label,
  toolTipText,
  value = '',
  disabled = false,
  wrapperClass = '',
  placeholder = '',
  onChange,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e) => {
    if (disabled) return;
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className={`relative ${wrapperClass}`}>
      {label && (
        <label className={`flex flex-row flex-wrap gap-1 mb-3 text-[13px] leading-[1.2] tracking-[-0.02em] transition-colors duration-[0.2s] font-semibold font-sans ${isFocused || disabled ? 'text-white' : 'text-gray-lighter'} ${disabled ? 'text-white' : 'text-gray-lighter'}`}>
          {label}
          {toolTipText && (
            <>
              <span className='top-[1px] relative cursor-pointer' data-tooltip-id="text-tooltip">
                <Icons nameIcon='alert-circle' className="w-[14px] h-[14px]" />
              </span>
              <Tooltip id="text-tooltip" className="!inline-flex !justify-center !items-center !bg-white/10 !px-2 !py-[2px] !rounded-md font-sans !font-bold !text-white !text-xs">
                {toolTipText}
              </Tooltip>
            </>
          )}
        </label>
      )}

      <div className="relative">
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={`border-2 w-full border-solid font-sans text-base leading-6 font-normal min-w-0 text-white text-start bg-black-dark ease-linear rounded-xl outline-none !p-4 transition-all duration-[0.2s] ${disabled ? 'cursor-not-allowed opacity-100 border-white/10' : 'cursor-text opacity-90 border-white/10'} ${isFocused ? 'border-white/30' : 'border-transparent'}`}
        />
      </div>
    </div>
  );
};

export default TextField;
