// src/pages/InstantInvestment/SwapSteps/index.jsx

import React, { useState, useCallback, useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import PageTitle from "../../../components/layouts/InstantInvestment/PageTitle";
// Inner
import StepReviewProcessOrder from "./StepReviewProcessOrder";
import StepTransitionButton from "./StepTransitionButton";
import StepBuyAndSell from "./StepBuyAndSell";
import { toaster } from "../../../utils";
import { useMe } from '../../../myHooks'

import Web3 from 'web3'
import { ethereum, walletClient } from '../../../myHooks/useCoinbaseAuth';
// eslint-disable-next-line import/no-unresolved
import { useAppKit, useAppKitAccount, useAppKitProvider } from '@reown/appkit/react'
import { providers, Contract, BigNumber } from 'ethers'
import {
  useWeb3Provider,
  COIN_DECIMALS,
  getCoinAddress,
  getUniswapUniversalPath,
  FEE_SWAP,
  getCollectingFeeAddress,
  getNetworkName,
  getUniswapUniversalPathWithWETH,
  getUniswapUniversalPathWithWETHReverse,
  getUniswapUniversalPathForUSDC
} from '../../../myHooks/useWeb3Provider';
import smartContract from "../../../constants/smartContract";
import Loader from "../../../components/shared/Loader";
import useFeeSwap from "../../../myHooks/useFeeSwap";
import metamaskService from "../../../services/metamask";
import { GET_TOKEN_BALANCES_BY_ADDRESS, GET_ALL_SEARCH_TOKENS } from '../../../queriesAndMutations';
import { uniswapTokens } from '../../../services/uniswapTokens';
import crosschainSupportToken from "../../../services/crosschainSupportToken";

const SkeletonLoading = () => (
  <div className='relative flex flex-row flex-wrap justify-start items-center gap-1 [&>span]:w-full'>
    <Skeleton width={100} height={20} className="!bg-gray-custom/25 opacity-25 !rounded-2xl !w-full" />
    <Skeleton width={100} height={20} className="!bg-gray-custom/25 opacity-25 !rounded-2xl !w-full" />
  </div>
)

const SwapSteps = ({ predefinedSwapBuy }) => {
  const { t } = useTranslation();
  const [popupEnabled, setPopupEnabled] = useState(true);
  const [checkingPopups, setCheckingPopups] = useState(true);
  const [allChecked, setAllChecked] = useState(false);
  const [step, setStep] = useState("buy-and-invest");
  const [buyValue, setBuyValue] = useState(0);
  const [sellValue, setSellValue] = useState(0);
  const [buyToken, setBuyToken] = useState({});
  const [sellToken, setSellToken] = useState({});
  const [buyDetails, setBuyDetails] = useState({});
  const { data: { me: user } = {} } = useMe()
  const [tokenBalance, setTokenBalance] = useState(0)
  const [isValueValid, setIsValueValid] = useState(false);
  const [maxSlippage, setMaxSlippage] = useState(5.5);

  const { provider } = useWeb3Provider(sellToken.network || 'base');

  const { getFeeTier } = useFeeSwap(provider);

  const [isDataReady, setIsDataReady] = useState(true);
  const [fetchingDataFailed, setFetchingDataFailed] = useState(false);

  const [getTokenBalancesByAddress] = useLazyQuery(GET_TOKEN_BALANCES_BY_ADDRESS);
  const [getAllSearchTokens] = useLazyQuery(GET_ALL_SEARCH_TOKENS);
  const tokenBalancesRef = useRef([]);
  const [tokenBalances, setTokenBalances] = useState([]);

  const delayDebounceSellChange = useRef(null);
  const delayDebounceBuyChange = useRef(null);

  const ButtonContent = ({ step, isValueValid }) => (
    <AnimatePresence mode="wait">
      <motion.span
        key={`${step}-${isValueValid}`}
        initial={{ opacity: 0, y: 4 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -4 }}
        transition={{ duration: 0.2 }}
        className="flex items-center gap-2"
      >
        {
          step === "detail-buy-invest"
            ? "Reviewing the investment"
            : !isValueValid
              ? "Insufficient balance"
              : "Continue"
        }
      </motion.span>
    </AnimatePresence>
  );

  const defaultNextStepMap = {
    "buy-and-invest": "review-process-investment",
  }

  const [nextStepMap, setNextStepMap] = useState(defaultNextStepMap);

  const defaultPrevStepMap = {
    "detail-buy-invest": "buy-and-invest",
  }

  const [prevStepMap, setPrevStepMap] = useState(defaultPrevStepMap);

  const handleNextStep = () => {
    if (nextStepMap[step]) {
      setStep(nextStepMap[step]);
    }
  };

  const handlePrevStep = () => {
    if (prevStepMap[step]) {
      setStep(prevStepMap[step]);
    }
  };

  // Add checkbox change handler
  const handleCheckboxChange = (checked) => {
    setAllChecked(checked);
  };

  // set form currency by user currency
  useEffect(() => {
    const fetchTokenBalances = async () => {
      if (user) {
        console.log('fetchTokenBalances')

        const chainIndexes = crosschainSupportToken.map(chain => chain.chainId.toString());

        const { data: tokenBalancesData } = await getTokenBalancesByAddress({
          variables: { address: user.wallet_address, chainIndexes }
        });

        const { data: searchTokensData } = await getAllSearchTokens({
          variables: { userId: user.id }
        });

        const balanceTokens = tokenBalancesData.getTokenBalancesByAddress.tokenAssets

        // // Get tokens from tokenBalancesData with price > 0
        // const balanceTokens = tokenBalancesData.getTokenBalancesByAddress.tokenAssets
        //   .filter(balance => balance.tokenPrice > 0)
        //   .map(balance => {
        //     const token = uniswapTokens.find(token =>
        //       token.symbol.toLowerCase() === balance.symbol.toLowerCase() &&
        //       token.chainId.toString() === currentChainIndex
        //     );
        //     return {
        //       ...balance,
        //       name: token ? token.name : balance.symbol.toLowerCase(),
        //       image: token ? token.logoURI : 'default-icon-day-v3.svg',
        //       network: getNetworkName(balance.chainIndex).toLowerCase()
        //     };
        //   });

        // Get tokens from uniswapTokens for current network
        const networkTokens = [...uniswapTokens, ...searchTokensData.getAllSearchTokens]
          .filter(token => chainIndexes.includes(token.chainId.toString()))
          .map(token => {
            // Try to find balance data for this token
            const balanceData = balanceTokens.find(b =>
              b.symbol.toLowerCase() === token.symbol.toLowerCase() &&
              token.chainId.toString() === b.chainIndex.toString()
            );

            let tokenAddress = balanceData ? balanceData.tokenAddress : token.address;

            if (!tokenAddress) {
              const crosschainToken = crosschainSupportToken.find(chain => chain.chainId.toString() === token.chainId.toString());

              if (crosschainToken) {
                tokenAddress = (crosschainToken.tokens.find(t => t.symbol.toLowerCase() === token.symbol.toLowerCase()) || {}).address;
              }
            }

            return {
              chainIndex: token.chainId.toString(),
              symbol: token.symbol,
              image: token.logoURI,
              name: token.name,
              // Use balance data if found, otherwise set defaults
              balance: balanceData ? balanceData.balance : '0',
              tokenPrice: balanceData ? balanceData.tokenPrice : '0',
              tokenAddress,
              // Include other balance properties with defaults
              tokenType: balanceData ? balanceData.tokenType : '1',
              isRiskToken: balanceData ? balanceData.isRiskToken : false,
              transferAmount: balanceData ? balanceData.transferAmount : '0',
              availableAmount: balanceData ? balanceData.availableAmount : '0',
              rawBalance: balanceData ? balanceData.rawBalance : '',
              address: balanceData ? balanceData.address : user.wallet_address,
              network: getNetworkName(token.chainId).toLowerCase(),
              decimals: token.decimals,
              acceptedRisk: true
            };
          });

        // Combine both arrays, removing duplicates by symbol
        const combinedTokens = [...networkTokens];

        // balanceTokens.forEach(balanceToken => {
        //   const exists = combinedTokens.some(token =>
        //     token.symbol.toLowerCase() === balanceToken.symbol.toLowerCase()
        //   );
        //   if (!exists) {
        //     combinedTokens.push(balanceToken);
        //   }
        // });

        const sortedTokens = combinedTokens.sort((a, b) => b.balance - a.balance);

        console.log('combinedTokens', sortedTokens)

        tokenBalancesRef.current = sortedTokens;
        setTokenBalances(sortedTokens);
      }
    }

    fetchTokenBalances();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const crosschainCalculateFees = useCallback(async (from, to, amount, exactOutput = false) => {
    console.log('crosschainCalculateFees')
    if (!provider || !from.symbol || !to.symbol || !amount) return {};

    const web3 = new Web3(provider);

    const fee = FEE_SWAP;
    let amountOut, amountIn, uiFee, relayFeeTotal;

    if (exactOutput) {
      amountOut = +amount

      amountIn = amountOut / (1 - fee);

      const amountSend = Math.ceil(amountIn * 10 ** from.decimals);

      const response = await fetch(`https://app.across.to/api/suggested-fees?inputToken=${from.tokenAddress}&outputToken=${to.tokenAddress}&originChainId=${from.chainIndex}&destinationChainId=${to.chainIndex}&amount=${amountSend}&recipient=${user.wallet_address}&relayer=0x0000000000000000000000000000000000000000`);

      const data = await response.json();

      console.log('data', data)

      if (data.status === 400) {
        toaster.error(data.message);
        setIsValueValid(false);
        return {};
      }

      relayFeeTotal = +data.relayFeeTotal / (10 ** to.decimals);
      amountIn += relayFeeTotal / (1 - fee);
      uiFee = amountIn * fee;
    } else {
      amountIn = +amount

      amountOut = amountIn - (amountIn * fee);

      const amountSend = Math.ceil(amountOut * 10 ** to.decimals);

      const response = await fetch(`https://app.across.to/api/suggested-fees?inputToken=${from.tokenAddress}&outputToken=${to.tokenAddress}&originChainId=${from.chainIndex}&destinationChainId=${to.chainIndex}&amount=${amountSend}&recipient=${user.wallet_address}&relayer=0x0000000000000000000000000000000000000000`);

      const data = await response.json();

      console.log('data', data)

      if (data.status === 400) {
        toaster.error(data.message);
        setIsValueValid(false);
        return {};
      }

      relayFeeTotal = +data.relayFeeTotal / (10 ** to.decimals);
      amountOut -= relayFeeTotal;
      uiFee = amountIn * fee;
    }

    return {
      from,
      to,
      tokenInAddress: from.tokenAddress,
      tokenOutAddress: to.tokenAddress,
      amountIn,
      amountOut,
      amountInDecimals: from.decimals,
      amountOutDecimals: to.decimals,
      feePercentage: fee,
      uiFee,
      networkFeeAmount: 0,
      relayFeeTotal: relayFeeTotal,
      network: from.network
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const calculateFees = useCallback(async (from, to, amount, exactOutput = false) => {
    console.log('calculateFees')
    if (!provider || !from.symbol || !to.symbol || !amount) return {};

    const web3 = new Web3(provider);

    const ethersProvider = new providers.Web3Provider(provider)

    const fee = FEE_SWAP;
    const networkFeeAmount = 0; // 0.25%

    console.log('calculateFees from', from)
    console.log('calculateFees to', to)
    console.log('calculateFees amount', amount)

    // const ethersProvider = new providers.Web3Provider(provider);
    await ethersProvider.send('eth_requestAccounts', []); // Request account access
    const signer = ethersProvider.getSigner();

    const collectingFeeAddress = getCollectingFeeAddress(from.network);

    console.log('calculateFees collectingFeeAddress', collectingFeeAddress)

    const feeContract = new web3.eth.Contract(smartContract.feeContractAbi, collectingFeeAddress);

    const tokenInAddress = from.tokenAddress || getCoinAddress(from.symbol.toLowerCase(), from.network, tokenBalancesRef.current);
    const tokenOutAddress = to.tokenAddress || getCoinAddress(to.symbol.toLowerCase(), to.network, tokenBalancesRef.current);

    const abi = [
      {
        constant: true,
        inputs: [{ name: "_owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{ name: "", type: "uint8" }],
        payable: false,
        type: "function",
      },
    ];

    const tokenInContract = new web3.eth.Contract(abi, tokenInAddress);
    const amountInDecimals = from.decimals || +Number(await tokenInContract.methods.decimals().call());
    console.log('calculateFees amountInDecimals', amountInDecimals)

    const tokenOutContract = new web3.eth.Contract(abi, tokenOutAddress);
    const amountOutDecimals = to.decimals || +Number(await tokenOutContract.methods.decimals().call());

    console.log('calculateFees tokenInAddress', tokenInAddress);
    console.log('calculateFees tokenOutAddress', tokenOutAddress);
    console.log('calculateFees signer', signer);
    console.log('calculateFees from.network', from.network);

    let feeTier = await getFeeTier(tokenInAddress, tokenOutAddress, ethersProvider, from.network);

    console.log('calculateFees feeTier', feeTier);

    let path = '0x';
    let pathReverse = '0x';

    if (!feeTier) {
      if (from.symbol.toLowerCase() === 'usdc' || to.symbol.toLowerCase() === 'usdc') {
        console.log('calculateFees try to use WETH');
        const ethAddress = getCoinAddress('eth', from.network, tokenBalancesRef.current) || getCoinAddress('pol', from.network, tokenBalancesRef.current);

        let feeTierTokenInToWETH = await getFeeTier(tokenInAddress, ethAddress, ethersProvider, from.network);

        if (!feeTierTokenInToWETH) {
          feeTierTokenInToWETH = 3000;
        }

        let feeTierFromWETHToTokenOut = await getFeeTier(ethAddress, tokenOutAddress, ethersProvider, from.network);

        if (!feeTierFromWETHToTokenOut) {
          feeTierFromWETHToTokenOut = 3000;
        }

        path = getUniswapUniversalPathForUSDC(from.tokenAddress, to.tokenAddress, from.network, feeTierTokenInToWETH, feeTierFromWETHToTokenOut);

        pathReverse = getUniswapUniversalPathForUSDC(to.tokenAddress, from.tokenAddress, from.network, feeTierFromWETHToTokenOut, feeTierTokenInToWETH);

        console.log('calculateFees path', path)
        console.log('calculateFees pathReverse', pathReverse)

        feeTier = feeTierTokenInToWETH + feeTierFromWETHToTokenOut;
      } else {
        console.log('calculateFees use uniswap universal path')
        let feeTierTokenInToUSDC = await getFeeTier(tokenInAddress, getCoinAddress('usdc', from.network, tokenBalancesRef.current), ethersProvider, from.network);

        if (!feeTierTokenInToUSDC) {
          feeTierTokenInToUSDC = 3000;
        }

        console.log('calculateFees feeTierTokenInToUSDC', feeTierTokenInToUSDC)

        let feeTierFromUSDCToTokenOut = await getFeeTier(getCoinAddress('usdc', from.network, tokenBalancesRef.current), tokenOutAddress, ethersProvider, from.network);

        if (!feeTierFromUSDCToTokenOut) {
          feeTierFromUSDCToTokenOut = 3000;
        }

        console.log('calculateFees feeTierFromUSDCToTokenOut', feeTierFromUSDCToTokenOut)

        path = getUniswapUniversalPath(from.tokenAddress, to.tokenAddress, from.network, feeTierTokenInToUSDC, feeTierFromUSDCToTokenOut);

        const { amountOut: amountOutValue, gasEstimate: gasEstimateValue } = await feeContract.methods
          .getSwapAndBuyQuoteExactInput(tokenInAddress, BigInt(parseInt(+amount * 10 ** amountInDecimals)).toString(), tokenOutAddress, 0, path)
          .call();

        if (Number(amountOutValue) <= 0) {
          console.log('calculateFees try to use WETH');
          const feeTierUSDCToWETH = 500;
          const ethAddress = getCoinAddress('eth', from.network, tokenBalancesRef.current) || getCoinAddress('pol', from.network, tokenBalancesRef.current);
          let feeTierFromWETHToTokenOut = await getFeeTier(ethAddress, tokenOutAddress, ethersProvider, from.network);

          if (!feeTierFromWETHToTokenOut) {
            feeTierFromWETHToTokenOut = 3000;
          }
          path = getUniswapUniversalPathWithWETH(from.tokenAddress, to.tokenAddress, from.network, feeTierTokenInToUSDC, feeTierUSDCToWETH, feeTierFromWETHToTokenOut);

          pathReverse = getUniswapUniversalPathWithWETHReverse(to.tokenAddress, from.tokenAddress, from.network, feeTierFromWETHToTokenOut, feeTierUSDCToWETH, feeTierTokenInToUSDC);

          console.log('calculateFees path', path)
          console.log('calculateFees pathReverse', pathReverse)

          feeTier = feeTierTokenInToUSDC + feeTierUSDCToWETH + feeTierFromWETHToTokenOut;
        } else {
          pathReverse = getUniswapUniversalPath(to.tokenAddress, from.tokenAddress, from.network, feeTierFromUSDCToTokenOut, feeTierTokenInToUSDC);
          console.log('calculateFees path', path)
          console.log('calculateFees pathReverse', pathReverse)
          feeTier = feeTierTokenInToUSDC + feeTierFromUSDCToTokenOut;
        }
      }
    }

    let amountIn;
    let amountOut;
    let gasEstimate;

    if (exactOutput) {
      amountOut = +amount
      const amountOutDecimalsValue = BigInt(parseInt(+amount * 10 ** amountOutDecimals)).toString()

      console.log('calculateFees amountOut', amountOut)
      const { amountIn: amountInValue, gasEstimate: gasEstimateValue } = await feeContract.methods
        .getSwapAndBuyQuoteExactOutput(tokenInAddress, amountOutDecimalsValue, tokenOutAddress, feeTier, pathReverse)
        .call();

      console.log('calculateFees amountInValue', amountInValue)

      if (Number(amountInValue) <= 0) {
        toaster.error('No liquidity available for this token pair.');
        setIsValueValid(false);

        return {};
      }

      amountIn = Number(amountInValue) / 10 ** amountInDecimals;
      gasEstimate = gasEstimateValue;
    } else {
      amountIn = +amount
      const amountInDecimalsValue = BigInt(parseInt(+amount * 10 ** amountInDecimals)).toString()

      console.log('calculateFees amountIn', amountIn)
      const { amountOut: amountOutValue, gasEstimate: gasEstimateValue } = await feeContract.methods
        .getSwapAndBuyQuoteExactInput(tokenInAddress, amountInDecimalsValue, tokenOutAddress, feeTier, path)
        .call();

      console.log('calculateFees amountOutValue', amountOutValue)

      if (Number(amountOutValue) <= 0) {
        toaster.error('No liquidity available for this token pair.');
        setIsValueValid(false);

        return {};
      }

      amountOut = Number(amountOutValue) / 10 ** amountOutDecimals;
      gasEstimate = gasEstimateValue;
    }

    console.log('calculateFees amountOut', amountOut)
    console.log('calculateFees gasEstimate', gasEstimate)

    const uiFee = amountIn * fee;

    return {
      from,
      to,
      tokenInAddress,
      tokenOutAddress,
      path,
      amountIn,
      amountOut,
      amountInDecimals,
      amountOutDecimals,
      feePercentage: fee,
      uiFee,
      networkFeeAmount: 0,
      swapFeePercentage: +feeTier / 1000000,
      swapFee: amountIn * (+feeTier / 1000000),
      feeTier,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const validateSellTokenBalance = useCallback((token, amount) => {
    if (!token.symbol) {
      return false;
    }

    const tokenBalance = tokenBalances.find((balance) => balance.symbol.toLowerCase() === token.symbol.toLowerCase());

    if (!tokenBalance || amount > tokenBalance.balance) {
      return false;
    }

    return true;
  }, [tokenBalances]);

  const handleBuyChange = useCallback(async (value) => {
    setBuyValue(value);

    clearTimeout(delayDebounceBuyChange.current);

    delayDebounceBuyChange.current = setTimeout(async () => {
      let fee;

      if (sellToken.network !== buyToken.network) {
        fee = await crosschainCalculateFees(sellToken, buyToken, value, true);
      } else {
        fee = await calculateFees(sellToken, buyToken, value, true);
      }

      if (fee.amountIn > 0) {
        const isSellTokenBalanceValid = validateSellTokenBalance(sellToken, fee.amountIn);

        setIsValueValid(isSellTokenBalanceValid);
      }

      setBuyDetails(fee);
      setSellValue(+fee.amountIn.toFixed(6));

      console.log('fee', fee)
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellToken, buyToken, provider]);

  const handleSellChange = useCallback(async (value) => {
    setSellValue(value);

    clearTimeout(delayDebounceSellChange.current);

    delayDebounceSellChange.current = setTimeout(async () => {
      let fee;

      setIsDataReady(false);

      if (sellToken.network !== buyToken.network) {
        fee = await crosschainCalculateFees(sellToken, buyToken, value);
      } else {
        fee = await calculateFees(sellToken, buyToken, value);
      }

      if (fee.amountIn > 0) {
        const isSellTokenBalanceValid = validateSellTokenBalance(sellToken, fee.amountIn);

        setIsValueValid(isSellTokenBalanceValid);

        setBuyDetails(fee);
        setBuyValue(+fee.amountOut.toFixed(6));
      }

      console.log('fee', fee)

      setIsDataReady(true);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellToken, buyToken, provider]);

  const handleSellTokenChange = useCallback(async (token) => {
    setSellToken(token);

    console.log('handleSellTokenChange sellToken', token)

    setIsDataReady(false);

    let fee;

    if (token.network !== buyToken.network) {
      fee = await crosschainCalculateFees(token, buyToken, sellValue);
    } else {
      fee = await calculateFees(token, buyToken, sellValue);
    }

    if (fee.amountIn > 0) {
      setBuyDetails(fee);
      setBuyValue(fee.amountOut);
      const isSellTokenBalanceValid = validateSellTokenBalance(token, fee.amountIn);

      setIsValueValid(isSellTokenBalanceValid);
    }

    console.log('fee', fee)

    setIsDataReady(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyToken, sellValue, provider]);

  const handleBuyTokenChange = useCallback(async (token) => {
    setBuyToken(token);

    setIsDataReady(false);

    console.log('handleBuyTokenChange buyToken', token)

    let fee;

    if (sellToken.network !== token.network) {
      fee = await crosschainCalculateFees(sellToken, token, sellValue);
    } else {
      fee = await calculateFees(sellToken, token, sellValue);
    }
    setBuyDetails(fee);
    setBuyValue(fee.amountOut);

    if (fee.amountIn > 0) {
      const isSellTokenBalanceValid = validateSellTokenBalance(sellToken, fee.amountIn);

      setIsValueValid(isSellTokenBalanceValid);
    }

    console.log('fee', fee)

    setIsDataReady(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellToken, sellValue, provider]);

  useEffect(() => {
    if (predefinedSwapBuy && tokenBalances.length > 0) {
      const selected = tokenBalances.find(
        (token) =>
          token.symbol.toLowerCase() ===
            predefinedSwapBuy.symbol.toLowerCase() &&
          token.network.toLowerCase() ===
            predefinedSwapBuy.network.toLowerCase()
      );
      handleBuyTokenChange(selected);
    }
  }, [predefinedSwapBuy, handleBuyTokenChange, tokenBalances])

  // Add popup check function
  const checkPopupEnabled = useCallback(async () => {
    try {
      setCheckingPopups(true);
      // Try to open a small popup window
      const popup = window.open('about:blank', '_blank', 'width=1,height=1');

      if (!popup || popup.closed || typeof popup.closed === 'undefined') {
        setPopupEnabled(false);
      } else {
        setPopupEnabled(true);
        popup.close();
      }
    } catch (error) {
      setPopupEnabled(false);
    } finally {
      setCheckingPopups(false);
    }
  }, []);

  useEffect(() => {
    checkPopupEnabled();
  }, [checkPopupEnabled]);

  // useEffect(() => {
  //   if (fetchingDataFailed && numberFetchFailed < 3) {
  //     setFetchingDataFailed(false);
  //     setNumberFetchFailed(prev => prev + 1);
  //     toaster.info('Refetching data...');

  //     setTimeout(() => {
  //       checkTokenBalance(user.currency);
  //     }, 2000);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fetchingDataFailed, numberFetchFailed]);

  console.log('buyValue', buyValue)
  console.log('sellValue', sellValue)

  const onSubmit = async (data) => {
    console.log('data', data)
    console.log('step', step)

    let investment;

    console.log('nextStepMap[step]', nextStepMap[step])

    switch (nextStepMap[step]) {
      case 'detail-buy-invest':
        try {
          if (step === 'buy-and-invest' || step === 'sharedholder-personal-info') {
            handleNextStep();
          }
        } catch (error) {
          console.error('Swap creation failed:', error);

          toaster.error(error.message);
        }
        break;
      case 'review-process-investment':
        handleNextStep();

        break;
      default:
        handleNextStep();
        break;
    }

    return investment;
  };

  // Add this before the return statement
  if (checkingPopups) {
    return (
      <div className="mx-auto w-full max-w-[382px]">
        <SkeletonLoading />
      </div>
    );
  }

  return (
    <>
      <PageTitle
        title={t('investment.swap_title')}
        description={
          step === "sharedholder-registration"
            ? t('investment.description.sharedholder-registration')
            : step === "sharedholder-personal-info"
            ? t('investment.description.sharedholder-personal-info')
            : ""
        }
      />

      {step === "buy-and-invest" && (
        <div className="w-full max-w-[382px] mx-auto mb-4 flex justify-end items-center">
          <label className="text-sm font-medium text-white mr-4">
            Max Slippage (%)
          </label>
          <div className="relative">
            <input
              name="amount"
              type="number"
              step="any"
              className="w-24 px-3 pr-6 py-1.5 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors text-right"
              placeholder="0"
              value={maxSlippage}
              onChange={(e) => setMaxSlippage(e.target.value)}
            />
            <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">%</span>
          </div>
        </div>
      )}

      {step === "buy-and-invest" && (
        <StepBuyAndSell
          isLogin={true}
          handleSellChange={handleSellChange}
          handleBuyChange={handleBuyChange}
          buyValue={buyValue}
          sellValue={sellValue}
          balanceAfterTopup={tokenBalance}
          sellToken={sellToken}
          buyToken={buyToken}
          setSellToken={handleSellTokenChange}
          setBuyToken={handleBuyTokenChange}
          tokenBalances={tokenBalances}
          buyDetails={buyDetails}
          predefinedSwapBuy={predefinedSwapBuy}
        />
      )}

      {step === "review-process-investment" &&
        <StepReviewProcessOrder
          setStep={setStep}
          buyDetails={buyDetails}
          tokenBalances={tokenBalances}
          maxSlippage={maxSlippage}
        />
      }

      {step !== "before-inssuficient-balance" && step !== "review-process-investment" && (
        <StepTransitionButton
          onSubmit={onSubmit}
          disabled={
            !isDataReady ||
            !isValueValid ||
            !popupEnabled
          }
          isLoading={!isDataReady}
        >
          <ButtonContent
            step={step}
            isValueValid={isValueValid}
          />
        </StepTransitionButton>
      )}

      {(step === "sharedholder-registration" || step === "sharedholder-personal-info") && (
        <button
          type="button"
          onClick={() => {
            window.location.href = '/instant-investment';
          }}
          className="inline-flex justify-center items-center bg-white hover:bg-[#ffffffab] hover:opacity-90 m-0 mt-4 px-6 !rounded-2xl outline-none w-full !h-14 overflow-visible font-medium text-[100%] text-black normal-case leading-none transition-colors duration-[0.2s] cursor-pointer"
        >
          Cancel
        </button>
      )}
    </>
  );
};

export default SwapSteps;
