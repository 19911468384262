import React from "react";
import { formatNumber } from "../../../utils/formatNumber";

/**
 * Component to display formatted numbers
 * @param {Object} props
 * @param {number|string} props.value - The number to display
 * @param {number} props.decimals - Number of decimal places
 * @param {boolean} props.forceDecimals - Force showing all decimals
 * @param {string} props.className - Additional CSS classes
 * @returns {React.ReactElement}
 */
const FormattedNumber = ({ value, decimals, forceDecimals = false, className = '' }) => {
  const formattedValue = formatNumber(value, decimals, forceDecimals);

  return (
    <span className={className}>
      {formattedValue}
    </span>
  );
};

export default FormattedNumber;
