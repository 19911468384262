import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TransactionsSkeleton = () => {
  return (
    <div className="flex flex-row flex-wrap justify-start items-center gap-x-3 gap-y-3 m-0 px-0 py-2 border-0 transition-all duration-[0.2s]">
      <div className="-top-1 relative m-0 p-0 border-0 rounded-[50%] w-12 h-12">
        <Skeleton
          circle
          width={48}
          height={48}
          className="!bg-gray-custom/25 opacity-25 !rounded-2xl"
        />
      </div>

      <div className="flex flex-row flex-wrap flex-[1_1_0] justify-start items-stretch m-0 p-0 border-0 font-semibold text-white">
        <div className="flex flex-col flex-wrap flex-1 justify-center items-start">
          <span className="block font-sans text-base leading-[1.1]">
            <Skeleton width={60} height={16} className="!bg-gray-custom/25 opacity-25 !rounded-2xl" />
          </span>

          <span className="m-0 p-0 border-0 font-[600] text-[13px] text-gray-lighter">
            <Skeleton width={40} height={6} className="!bg-gray-custom/25 opacity-25 !rounded-2xl" />
          </span>
        </div>

        <div className="flex flex-col flex-wrap justify-start items-end">
          <span className="block font-sans text-base leading-[1.1]">
            <Skeleton width={60} height={16} className="!bg-gray-custom/25 opacity-25 !rounded-2xl" />
          </span>
          <span className="m-0 p-0 border-0 font-[600] text-[13px] text-gray-lighter">
            <Skeleton width={40} height={6} className="!bg-gray-custom/25 opacity-25 !rounded-2xll" />
          </span>
        </div>
      </div>
    </div>
  )
}

export default TransactionsSkeleton
