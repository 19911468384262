/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import styles from './MyRewards.module.scss'
import Icons from '../../../components/shared/Icons'
import { useWeb3Provider } from '../../../myHooks/useWeb3Provider'
import { Contract, providers, utils } from 'ethers'
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_ALL_SPLIT_PAY_CONTRACTS } from '../../../queriesAndMutations/splitPayContractQuery';

// You'll need to import your contract ABI and address
import { splitPaymentAbi } from '../../../constants/smartContract'
import { NETWORK_IMAGES } from '../../../constant/images'
import { useMe } from '../../../myHooks/useMe'
import { useCoinbaseTrans } from './useCoinbaseTrans';
import { toaster, isProduction } from '../../../utils';
import metamaskService from '../../../services/metamask';
import { GET_TRANSACTIONS_BY_ADDRESS, GET_HISTORICAL_PRICE } from '../../../queriesAndMutations';
import { uniswapTokens } from '../../../services/uniswapTokens';

const TABS = [
  { id: 'active', label: 'Active' },
  { id: 'history', label: 'History' }
];

const TabContent = ({ tab }) => {
  if (tab === 'active') {
    return (
      <div className="bg-[#1e2025] p-4 border-2 border-white/10 border-solid rounded-xl outline-none w-full min-w-0 text-white text-start transition-all duration-[0.2s] ease-linear">
        {/* <div className="flex items-center gap-4 mb-4">
          <img src={NETWORK_IMAGES.ethereum} alt="Ethereum" className='rounded-full w-6 h-6' />
          <div className="flex-1">
            <div className="flex justify-between items-center mb-1">
              <div className="m-0 font-sans font-semibold text-white text-base text-start leading-6 transition-[color] duration-[0.02s]">ETH Staking</div>
              <div className="font-sans text-green-500 tracking-[-0.02em]">3.67% APR</div>
            </div>
            <div className="flex justify-between items-center font-sans text-[13px] text-gray-lighter truncate tracking-[-0.02em]">
              <div>0.5 ETH staked</div>
              <div>+$21.33 earned</div>
            </div>
          </div>
        </div>
        <div className="bg-black-light rounded-full h-1 overflow-hidden">
          <div className="bg-primary w-3/4 h-full" />
        </div>
        <div className="mt-1 font-sans text-[13px] text-gray-lighter tracking-[-.02em]">Next reward in 2 days</div> */}
        <div className="py-8 font-sans text-[13px] text-gray-lighter text-center tracking-[-.02em]">
          No activity rewards yet
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#1e2025] p-4 border-2 border-white/10 border-solid rounded-xl outline-none w-full min-w-0 text-white text-start transition-all duration-[0.2s] ease-linear">
      <div className="py-8 font-sans text-[13px] text-gray-lighter text-center tracking-[-.02em]">
        No historical rewards yet
      </div>
    </div>
  );
};

const MyRewards = () => {
  const [activeTab, setActiveTab] = useState('active');
  const { data: splitPayContracts, loading: contractsLoading } = useQuery(GET_ALL_SPLIT_PAY_CONTRACTS);
  const [dividendsInfo, setDividendsInfo] = useState([]);
  const [claimedDividendsInfo, setClaimedDividendsInfo] = useState([]);
  const { data: { me: user } = {} } = useMe();
  const [loadingStates, setLoadingStates] = useState({});
  const [totalEarnedThisMonth, setTotalEarnedThisMonth] = useState(0);

  const { provider } = useWeb3Provider('base');
  const { handleClaim: coinbaseClaim } = useCoinbaseTrans();
  const [getTransactionsByAddress] = useLazyQuery(GET_TRANSACTIONS_BY_ADDRESS);
  const [getHistoricalPrice] = useLazyQuery(GET_HISTORICAL_PRICE);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [currentDividend, setCurrentDividend] = useState(null);
  const [transactionHash, setTransactionHash] = useState(null);

  const loadDividendsInfo = async (splitPayContract) => {
    try {
      console.log('loadDividendsInfo splitPayContract', splitPayContract)
      const ethersProvider = new providers.Web3Provider(provider);
      await ethersProvider.send('eth_requestAccounts', []);
      const signer = ethersProvider.getSigner();

      console.log('signer', signer)

      const contract = new Contract(
        splitPayContract.address,
        splitPaymentAbi,
        signer
      );

      const shares = await contract.shares(user.wallet_address);

      console.log('shares', shares)

      // Get remaining dividends
      const claimed = await contract.released(user.wallet_address);

      console.log('claimed', claimed)

      const etherPerShare = await contract.etherPerShare();

      console.log('etherPerShare', etherPerShare)

      // Get if already claimed
      const released = await contract.released(user.wallet_address);

      console.log('released', released)

      return {
        name: splitPayContract.name,
        shares: +shares,
        claimed: +utils.formatUnits(claimed, splitPayContract.currency_decimals),
        remainingDividends: utils.formatUnits(etherPerShare.mul(shares).sub(claimed), splitPayContract.currency_decimals),
        isClaimable: etherPerShare.mul(shares).sub(claimed).gt(0) && released.eq(0),
        currency: splitPayContract.currency,
        currencyDecimals: splitPayContract.currency_decimals,
        date: splitPayContract.date,
        address: splitPayContract.address,
        network: splitPayContract.network
      };
    } catch (error) {
      console.error('Error loading dividends info:', error);
      return null;
    }
  };

  const loadAllDividendsInfo = async () => {
    if (user.wallet_address && provider && splitPayContracts && splitPayContracts.getAllSplitPayContracts) {
      const dividendsInfo = await Promise.all(splitPayContracts.getAllSplitPayContracts.map(loadDividendsInfo));
      console.log('dividendsInfo', dividendsInfo)
      setDividendsInfo(dividendsInfo.filter(info => info !== null && info.isClaimable));

      setClaimedDividendsInfo(dividendsInfo.filter(info => info !== null && info.claimed > 0));
    }
  }

  useEffect(() => {
    if (contractsLoading) return;
    loadAllDividendsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, provider, contractsLoading, splitPayContracts]);

  const handleClaim = async (dividendInfo) => {
    if (!user || !provider) return;

    try {
      setLoadingStates(prev => ({ ...prev, [dividendInfo.address]: true }));

      if (user.login_provider === 'coinbase') {
        const tx = await coinbaseClaim(dividendInfo);

        if (!tx) throw new Error('Transaction failed');
      } else {
        const ethersProvider = new providers.Web3Provider(provider);
        await ethersProvider.send('eth_requestAccounts', []);
        const signer = ethersProvider.getSigner();

        const contract = new Contract(
          dividendInfo.address,
          splitPaymentAbi,
          signer
        );

        const tx = await contract.claim();
        await tx.wait();

        setTransactionHash(tx.hash);
      }

      toaster.success('Claimed successfully');

      setCurrentDividend(dividendInfo)

      setSuccessModalOpen(true);

      await loadAllDividendsInfo();
    } catch (error) {
      console.error('Error claiming dividends:', error);
    } finally {
      setLoadingStates(prev => ({ ...prev, [dividendInfo.address]: false }));
    }
  };

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const getAddressExplorer = (network) => {
    switch (network) {
    case 'mainnet-only':
      return 'https://etherscan.io/address';
    case 'mainnet':
      return isProduction ? 'https://etherscan.io/address' : 'https://sepolia.etherscan.io/address'
    case 'base':
        return isProduction ? 'https://basescan.org/address' : 'https://sepolia.basescan.org/address'
    case 'polygon':
      return isProduction ? 'https://polygonscan.com/address' : 'https://amoy.polygonscan.com/address'
    case 'xinfin':
      return isProduction ? 'https://explorer.xinfin.network/address' : 'https://apothem.xinfin.network/address'
    default:
      return 'https://basescan.org/address'
    }
  }

  const getTransactionExplorer = (network) => {
    switch (network) {
    case 'mainnet-only':
      return 'https://etherscan.io/tx';
    case 'mainnet':
      return isProduction ? 'https://etherscan.io/tx' : 'https://sepolia.etherscan.io/tx'
    case 'base':
        return isProduction ? 'https://basescan.org/tx' : 'https://sepolia.basescan.org/tx'
    case 'polygon':
      return isProduction ? 'https://polygonscan.com/tx' : 'https://amoy.polygonscan.com/tx'
    case 'xinfin':
      return isProduction ? 'https://explorer.xinfin.network/tx' : 'https://apothem.xinfin.network/tx'
    default:
      return 'https://basescan.org/tx'
    }
  }

  const transactionExplorer = getTransactionExplorer(currentDividend ? currentDividend.network : 'base')
  const addressExplorer = getAddressExplorer(currentDividend ? currentDividend.network : 'base')

  const calculateTotalEarnedThisMonth = async () => {
    try {
      const dividends = claimedDividendsInfo.filter(dividend => new Date(+dividend.date).getMonth() === new Date().getMonth())

      console.log('claimedDividendsInfo', dividends)

      const begin = Date.now();
      const period = '1d'

      let totalEarned = 0;

      for (const dividend of dividends) {
        const chainIndex = metamaskService.getChainIdByName(dividend.network.toLowerCase());
        console.log('chainIndex', chainIndex)
        const token = uniswapTokens.find(token => token.symbol.toLowerCase() === dividend.currency.toLowerCase() && token.chainId === parseInt(chainIndex));

        console.log('token', token)
        if (!token) return;

        const { data } = await getHistoricalPrice({
          variables: {
            input: {
              tokenAddress: token.address,
              chainIndex: parseInt(chainIndex),
              limit: 1,
              begin: begin.toString(),
              end: (begin + 24 * 60 * 60 * 1000).toString(),
              period
            }
          }
        });

        if (data && data.getHistoricalPrice && data.getHistoricalPrice.historicalPrice) {
          const price = data.getHistoricalPrice.historicalPrice;

          console.log('price', price)
          console.log('dividend.claimed', dividend.claimed)

          totalEarned += +dividend.claimed * +price;
        }
      }

      console.log('totalEarned', totalEarned)

      setTotalEarnedThisMonth(totalEarned);
    } catch (error) {
      console.error('Error fetching historical price:', error);
    }
  };

  useEffect(() => {
    calculateTotalEarnedThisMonth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimedDividendsInfo]);

  return (
    <div className={styles.wrapper}>
      <div className="mb-8">
        <h2 className="mb-6 font-sans font-semibold text-white text-lg text-start leading-6 tracking-[-0.02em]">Rewards</h2>
        <div className="mb-1 font-sans text-[13px] text-gray-lighter tracking-[-0.02em]">Total earned this month</div>

        <div className={`font-sans font-bold text-${totalEarnedThisMonth > 0 ? 'green-500' : totalEarnedThisMonth === 0 ? 'gray-lighter' : 'red-500'} text-3xl`}>+${totalEarnedThisMonth.toFixed(2)}</div>
      </div>

      {/* Tabs */}
      {/* <div className="inline-flex gap-2 mb-2 w-full">
        {TABS.map(({ id, label }) => (
          <button
            key={id}
            onClick={() => handleTabChange(id)}
            className={`
              border-2 px-4 py-1 rounded-full font-medium text-[13px]
              transition-all duration-200 outline-none border-solid tracking-[-0.02em] font-sans
              ${activeTab === id
                ? 'border-primary text-white'
                : 'border-transparent text-gray-lighter hover:text-white'
              }
            `}
          >
            {label}
          </button>
        ))}
      </div> */}

      {/* <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
        >
          <TabContent tab={activeTab} />
        </motion.div>
      </AnimatePresence> */}

      {/* Unclaimed Rewards */}
      <div className="mt-12 max-767:mt-10" />
      <div className="bg-white/10 w-full h-[1px]" />
      <div className="mt-12">
        <h2 className="mb-6 font-sans font-semibold text-white text-lg text-start leading-6 tracking-[-0.02em]">Unclaimed Rewards</h2>

        <div className="bg-[#1e2025] p-4 border-2 border-white/10 border-solid rounded-xl outline-none w-full min-w-0 text-white text-start transition-all duration-[0.2s] ease-linear">
          {dividendsInfo.length > 0 ? (
            dividendsInfo.map((dividendInfo) => (
              <>
                <div className="flex justify-start items-center">
                <div className="bg-green-500/20 rounded-full w-6-h6" />

                <div className="flex-1">
                  <div className="flex justify-between items-center">
                    <div>
                      <h4 className="m-0 font-sans font-semibold text-white text-base text-start leading-6 transition-[color] duration-[0.02s]">{dividendInfo.name}</h4>
                      <div className="font-sans text-gray-lighter tracking-[-0,02em]">
                        {new Date(+dividendInfo.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                      </div>
                    </div>
                    <div className="font-sans text-green-500 tracking-[-0,02em]">
                      {dividendInfo.remainingDividends} {dividendInfo.currency}
                    </div>
                  </div>
                  </div>
                </div>

                <button
                  onClick={() => handleClaim(dividendInfo)}
                  disabled={loadingStates[dividendInfo.address]}
                  className="inline-flex justify-center items-center bg-green-500 hover:bg-green-600 hover:opacity-90 m-0 mt-4 px-6 py-3 rounded-3xl outline-none w-full h-10 overflow-visible font-sans font-medium text-[100%] text-white normal-case leading-none tracking-[-0.02em] transition-colors duration-[0.2s] cursor-pointer">
                  {loadingStates[dividendInfo.address] ? 'Claiming...' : `Claim Reward (${dividendInfo.remainingDividends} ${dividendInfo.currency})`}
                </button>

                <div className="mt-8" />
              </>
            ))
          ) : (
            <div className="py-8 font-sans text-[13px] text-gray-lighter text-center tracking-[-.02em]">
              No potentialy claimable rewards yet
            </div>
          )}
        </div>
      </div>

      {/* Recent Claims */}
      <div className="mt-12 max-767:mt-10" />
      <div className="bg-white/10 w-full h-[1px]" />
      <div className="mt-12">
        <h2 className="mb-6 font-sans font-semibold text-white text-lg text-start leading-6 tracking-[-0.02em]">Recent Claims</h2>
        <div className="bg-[#1e2025] p-4 border-2 border-white/10 border-solid rounded-xl outline-none w-full min-w-0 text-white text-start transition-all duration-[0.2s] ease-linear">
          {claimedDividendsInfo.length > 0 ? (
            claimedDividendsInfo.map((dividendInfo) => (
              <div className="flex justify-between items-center mb-8">
                <div>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`${getAddressExplorer(dividendInfo.network)}/${dividendInfo.address}`}
                    className="m-0 font-sans font-semibold text-white text-base text-start leading-6 transition-[color] duration-[0.02s]"
                  >
                    {dividendInfo.name}
                  </a>
                  <div className="font-sans text-gray-lighter tracking-[-0.02em]">
                    {new Date(+dividendInfo.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                  </div>
                </div>
                <div className="font-sans text-gray-lighter tracking-[-0.02em]">
                  {dividendInfo.claimed} {dividendInfo.currency}
                </div>
              </div>
            ))
          ) : (
            <div className="py-8 font-sans text-[13px] text-gray-lighter text-center tracking-[-.02em]">
              No recent claims yet
            </div>
          )}
        </div>
      </div>

      <AnimatePresence>
        {isSuccessModalOpen && (
          <motion.div
            className="z-50 fixed inset-0 flex p-6 max-767:p-0 overflow-auto transition-all duration-[0.2s] scroll-smooth modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="fixed inset-0 bg-black-light opacity-75 modal__overlay"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.75 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              onClick={() => setSuccessModalOpen(false)}
            />

            <motion.div
              className="z-10 relative bg-black-dark shadow-[transparent_0_0_0_0,transparent_0_0_0_0,#1e2025_0_0_0_1px_inset,#00000054_0_32px_64px_-16px] m-auto rounded-3xl w-full max-w-[578px]"
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
            >
              <div className="relative m-0 px-6 pt-12 pb-6 border-0 text-center align-baseline">
                <div className="mx-auto mb-4 w-32 h-32 text-center">
                  <Icons nameIcon="check-color" className="w-32 h-32" />
                </div>
                <div className="block m-0 pb-4 w-full font-sans text-white text-2xl break-words leading-6">
                  Claim Success
                </div>

                <p className="inline-flex justify-center items-center gap-4 mt-6 mb-4 text-white text-base">
                  <span className="inline-flex justify-center items-center gap-1 font-sans">
                    {currentDividend.remainingDividends} {currentDividend.currency}
                  </span>
                </p>
                <br />

                <div className="text-[#6f767e] text-[13px]">
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={user.login_provider === 'coinbase' ? `${addressExplorer}/${user.wallet_address}#tokentxns` : `${transactionExplorer}/${transactionHash}`}
                    className="font-sans font-semibold text-[#0052ff] hover:text-[#0148dd] transition-opacity duration-[0.2s]"
                  >
                    View on Explorer
                  </a>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MyRewards;
