// src/pages/DashboardTokens/Table/index.jsx

import React, { useRef, useState, useCallback } from 'react'
import { useTableScroll } from "../../../hooks/features/table/useTableScroll"

import Header from './Header'
import Footer from "./Footer"
import './style.css'

import { DASHBOARD_DATA_TOKEN_EXCEL } from "../../../constant/dataTokenFromExcel"
import Body from './Body'

const tableHeaders = [
  {
      key: 'company',
      title: 'Company',
      customStyle: {
          width: '320px',
          minWidth: '320px',
          left: 0
      }
  },
  {
      key: 'networks',
      title: 'Networks',
      customStyle: {
          width: '120px',
          minWidth: '120px',
          left: 0
      }
  },
  {
      key: 'ticker',
      title: 'Ticker',
      customStyle: {
          width: '120px',
          minWidth: '120px',
          left: 0
      }
  },
  {
      key: 'status',
      title: 'Status',
      customStyle: {
          width: '240px',
          minWidth: '240px',
          left: 0
      }
  },
  {
      key: 'protocol',
      title: 'Protocol',
      customStyle: {
          width: '140px',
          minWidth: '140px',
          left: 0
      }
  },
  {
    key: 'smart-contract',
    title: 'Smart Contract',
    customStyle: {
        width: '320px',
        minWidth: '320px',
        left: 0
    }
  },
  {
      key: 'offering_contract',
      title: 'Offering Contract',
      customStyle: {
          width: '240px',
          minWidth: '240px',
          left: 0
      }
  },
  {
      key: 'token_contract_address',
      title: 'Token Contract Address',
      customStyle: {
          width: '320px',
          minWidth: '320px',
          left: 0
      }
  },
  {
      key: 'isin',
      title: "ISIN",
      customStyle: {
          width: '120px',
          minWidth: '120px',
      }
  },
  {
      key: 'lei',
      title: "LEI",
      customStyle: {
          width: '220px',
          minWidth: '220px'
      }
  },
  {
      key: 'wallet',
      title: 'Wallet',
      customStyle: {
          width: '180px',
          minWidth: '180px',
          left: 0
      }
  },
  {
      key: 'custodians',
      title: 'Custodians',
      customStyle: {
          width: '160px',
          minWidth: '160px',
          left: 0
      }
  },
  {
      key: 'auditor',
      title: 'Auditor',
      customStyle: {
          width: '240px',
          minWidth: '240px',
          left: 0
      }
  },
  {
      key: 'technical_manager',
      title: 'Technical Manager',
      customStyle: {
          width: '180px',
          minWidth: '180px',
          left: 0
      }
  },
  {
      key: 'token_service_provider',
      title: 'Token Service Provider',
      customStyle: {
          width: '280px',
          minWidth: '280px',
          left: 0
      }
  },
  {
      key: 'smart_contract_audits',
      title: 'Smart Contract Audits',
      customStyle: {
          width: '280px',
          minWidth: '280px',
          left: 0
      }
  },
  {
      key: 'smart_contract_insurance',
      title: 'Smart Contract Insurance',
      customStyle: {
          width: '280px',
          minWidth: '280px',
          left: 0
      }
  },
  {
      key: 'transparency',
      title: 'Transparency',
      customStyle: {
          width: '210px',
          minWidth: '210px',
          left: 0
      }
  },
  {
      key: 'sectors',
      title: 'Sectors',
      customStyle: {
          width: '120px',
          minWidth: '120px',
          left: 0
      }
  },
  {
      key: 'whitepaper_terms_prospectus',
      title: 'Whitepaper/Terms/Prospectus',
      customStyle: {
          width: '280px',
          minWidth: '280px',
          left: 0
      }
  },
  {
      key: 'notification',
      title: 'Notification',
      customStyle: {
          width: '320px',
          minWidth: '320px',
          left: 0
      }
  },
  {
      key: 'supplement',
      title: 'Supplement',
      customStyle: {
          width: '120px',
          minWidth: '120px',
          left: 0
      }
  },
  {
      key: 'committed',
      title: "Committed",
      customStyle: {
          width: '160px',
          minWidth: '160px'
      },
  },
  {
      key: 'market_cap',
      title: 'Market Cap',
      customStyle: {
          width: '160px',
          minWidth: '160px',
          left: 0
      }
  },
  {
      key: 'holders',
      title: 'Holders',
      customStyle: {
          width: '160px',
          minWidth: '160px',
          left: 0
      }
  },
  {
      key: 'issuer_Legal_name',
      title: 'Issuer Legal Name',
      customStyle: {
          width: '280px',
          minWidth: '280px',
          left: 0
      }
  },
  {
      key: 'founding_year',
      title: 'Founding Year',
      customStyle: {
          width: '160px',
          minWidth: '160px',
          left: 0
      }
  },
  {
      key: 'incorporation_country',
      title: 'Incorporation Country',
      customStyle: {
          width: '240px',
          minWidth: '240px',
          left: 0
      }
  },
  {
      key: 'company_data',
      title: 'Company Data',
      customStyle: {
          width: '160px',
          minWidth: '160px'
      }
  },
  {
      key: 'registration_license',
      title: 'Registration/License',
      customStyle: {
          width: '320px',
          minWidth: '320px',
          left: 0
      }
  },
  {
      key: 'min_investment',
      title: 'Min. Investment',
      customStyle: {
          width: '170px',
          minWidth: '170px',
          left: 0
      }
  },
  {
      key: 'license_or_regulatory_framework',
      title: 'License or Regulatory Framework',
      customStyle: {
          width: '320px',
          minWidth: '320px',
          left: 0
      }
  },
  {
      key: 'discussionurl',
      title: 'Discussion URL',
      customStyle: {
          width: '160px',
          minWidth: '160px'
      }
  },
  {
      key: 'issuer_url',
      title: 'IssuerURL',
      customStyle: {
          width: '280px',
          minWidth: '280px',
          left: 0
      }
  },
  {
      key: 'risks',
      title: 'Risks',
      customStyle: {
          width: '320px',
          minWidth: '320px'
      }
  },
  {
    key: 'clasification',
    title: 'Classification',
    customStyle: {
      width: '160px',
      minWidth: '160px'
    }
  },
  {
    key: 'action',
    title: 'Action',
    customStyle: {
      width: '140px',
      minWidth: '140px',
      left: 0
    }
  }
];

const Table = () => {
  const headerRef = useRef(null);
  const contentRef = useRef(null);
  const [openCollapseId, setOpenCollapseId] = useState(null);
  const [globalDropdownId, setGlobalDropdownId] = useState(null);

  const {
    isScrolled,
    scrollText,
    rangeValue,
    handleRangeChange,
    toggleScrollPosition
  } = useTableScroll(headerRef, contentRef);

  const handleToggleCollapse = useCallback((id) => {
    setOpenCollapseId(openCollapseId === id ? null : id);

    setGlobalDropdownId(null);
  }, [openCollapseId]);

  return (
    <div className="flex flex-col flex-grow h-full">
      <div className="table-container relative border-2 border-white/10 rounded-xl w-full overflow-clip">
        {/* Table header */}
        <div
          ref={headerRef}
          className="z-10 sticky overflow-x-auto overscroll-x-contain transition-opacity hide-scrollbar"
          style={{ willChange: 'scroll-position' }}
        >
          <div className="inline-block sticky min-w-full align-middle">
            <table className="bg-black-light min-w-full">
                <thead>
                    <tr className="sticky-first-cell flex flex-row">
                        {tableHeaders.map((header, index) => (
                            <Header
                                key={`header-${index}`}
                                title={header.title}
                                subtitle={header.subtitle}
                                hasSorter={header.hasSorter}
                                customStyle={header.customStyle}
                                textTooltip={header.textTooltip}
                                isScrolled={isScrolled}
                                isFirstColumn={index === 0}
                            />
                        ))}
                    </tr>
                </thead>
            </table>
          </div>
        </div>

        {/* Table content */}
        <div className="overflow-clip">
          <div
            ref={contentRef}
            className="overflow-x-auto overscroll-x-contain hide-scrollbar"
            style={{ willChange: 'scroll-position' }}
          >
            <div className="inline-block min-w-full align-middle">
              <table className="bg-black-light min-w-full">
                <tbody>
                  {DASHBOARD_DATA_TOKEN_EXCEL.map((row, rowIndex) => (
                    <Body
                      key={`row-${rowIndex}`}
                      row={row}
                      headers={tableHeaders}
                      isScrolled={isScrolled}
                      openCollapseId={openCollapseId}
                      setOpenCollapseId={setOpenCollapseId}
                      onToggleCollapse={() => handleToggleCollapse(row.id)}
                      globalDropdownId={globalDropdownId}
                      setGlobalDropdownId={setGlobalDropdownId}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <Footer
            toggleScrollPosition={toggleScrollPosition}
            scrollText={scrollText}
            rangeValue={rangeValue}
            handleRangeChange={handleRangeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Table;
