// src/pages/DashboardTokens/Table/Body.jsx

import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import Cell from './Cell'

const Body = ({
  row,
  headers,
  isScrolled,
  openCollapseId,
  setOpenCollapseId,
  onToggleCollapse,
  globalDropdownId,
  setGlobalDropdownId,
}) => {
  return (
    <>
      <tr className="sticky-first-cell flex flex-row">
        {headers.map((header, index) => {
          const { key } = header
          let cellValue = null

          if (key === 'company') {
            cellValue = {
              text: row[key],
              image: row.image,
              hasChildren: row.hasChildren,
              isOpen: openCollapseId === row.id,
            }
          } else if (key === 'smart-contract') {
            cellValue = row.smartContractData
          } else {
            cellValue = row[key]
          }

          return (
            <Cell
              key={`${row.id}-${key}`}
              type={key}
              value={cellValue}
              customStyle={header.customStyle}
              isSticky={isScrolled && index === 0}
              rowData={row}
              openCollapseId={openCollapseId}
              setOpenCollapseId={setOpenCollapseId}
              onToggleCollapse={onToggleCollapse}
              globalDropdownId={globalDropdownId}
              setGlobalDropdownId={setGlobalDropdownId}
            />
          )
        })}
      </tr>

      {/* Render child rows when parent is expanded */}
      <AnimatePresence>
        {row.hasChildren && openCollapseId === row.id && (row.children || []).map((childRow) => (
          <motion.tr
            key={`child-${childRow.id}`}
            className="sticky-first-cell flex flex-row child-row"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {headers.map((header, index) => (
              <Cell
                key={`${childRow.id}-${header.key}`}
                type={header.key}
                value={childRow[header.key]}
                customStyle={header.customStyle}
                isSticky={isScrolled && index === 0}
                rowData={childRow}
                openCollapseId={openCollapseId}
                setOpenCollapseId={setOpenCollapseId}
                globalDropdownId={globalDropdownId}
                setGlobalDropdownId={setGlobalDropdownId}
                isChildRow
              />
            ))}
          </motion.tr>
        ))}
      </AnimatePresence>
    </>
  )
}

export default Body
