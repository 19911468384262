/**
 * Format a number to a fixed number of decimal places with thousands separator
 * @param {number} value - The number to format
 * @param {number} decimals - Number of decimal places (default: 6 for small numbers, 2 for larger numbers)
 * @param {boolean} forceDecimals - Force showing all decimals even if they are zeros
 * @returns {string} Formatted number
 */
export const formatNumber = (value, decimals, forceDecimals = false) => {
  if (value === undefined || value === null) return '0';

  // Handle non-numeric values
  if (Number.isNaN(value)) return '0';

  // Convert to number if it's a string
  const numValue = typeof value === 'string' ? parseFloat(value) : value;

  // Determine number of decimals based on value size if not specified
  let decimalPlaces = decimals;
  if (decimalPlaces === undefined) {
    decimalPlaces = Math.abs(numValue) < 100 ? 6 : 2;
  }

  // For very small numbers, show more decimals to avoid showing just zeros
  if (Math.abs(numValue) > 0 && Math.abs(numValue) < 0.001 && decimalPlaces < 8) {
    decimalPlaces = 8;
  }

  // Format with appropriate number of decimal places
  const formatted = forceDecimals
    ? numValue.toFixed(decimalPlaces)
    : parseFloat(numValue.toFixed(decimalPlaces)).toString();

  // Add thousands separators
  const parts = formatted.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};

export default formatNumber;
