import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShortToken from '../../../components/shared/ShortToken';
import { useClickOutside } from "../../../hooks/common/useClickOutside";

const WalletCard = ({ wallet, onMoveIban, isLoading, showIban }) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMoveIban = (network) => {
    onMoveIban(wallet.address, network);
  };

  const toggleDropdown = (forceState) => {
    setIsDropdownOpen(prev => (
      forceState !== undefined ? forceState : !prev
    ));
  }

  const ref = useClickOutside(isDropdownOpen, () => toggleDropdown(false))

  return (
    <>
      {wallet.networks.map((network) => (
        <tr key={`${wallet.address}-${network.name}`} className="border-white/10 border-t">
          <td className="px-4 py-4 text-left">
            <span className="font-sans text-white text-sm">
              <ShortToken original={wallet.address} hasCopy />
            </span>
          </td>
          <td className="px-4 py-4 text-left">
            <span className="font-sans text-white text-sm capitalize">
              {network.name}
            </span>
          </td>
          <td className="px-4 py-4 text-right">
            <span className="font-sans text-white text-sm">
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: network.currency,
              }).format(network.balance)}
            </span>
          </td>
        </tr>
      ))}
    </>
  )
}

export default WalletCard
