import React, { useState, useEffect } from "react";
import cn from "classnames";
import DropdownModal from "./DropdownModal";
import { useFormContext } from "react-hook-form";
import styles from "./Style.module.scss"

const FieldInvest = ({
  isLogin,
  value,
  onChange,
  balanceAfterTopup,
  readOnly,
  setCurrentIssuer,
  currentIssuer,
  setActiveTab,
  setPredefinedSwapBuy,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { register, setValue, getValues, watch } = useFormContext();

  return (
    <div
      className={cn(
        "relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent",
        {
          "before:border-white/20": isFocused,
          "before:border-transparent": !isFocused,
          [styles.wrapper]: true
        }
      )}
    >
      <div className="font-sans text-[13px] text-gray-custom leading-[1.2] tracking-[-0.02em]">
        Invest
      </div>

      <div className="flex flex-row flex-wrap justify-between items-center mt-2">
        <div
          className={`flex flex-1 ${
            isLogin ? "flex-col flex-wrap justify-start items-start" : ""
          }`}
        >
          <input
            name="token_amount"
            type="number"
            className={cn(
              "relative flex-auto bg-transparent opacity-100 p-0 border-none w-0 max-h-11 text-4xl text-ellipsis text-left text-white placeholder:text-[#6F737E] transition-opacity duration-[250ms] overflow-hidden pointer-events-auto ease-[ease-in-out] outline-none font-sans",
              isLogin ? "w-full" : ""
            )}
            placeholder="0"
            value={value}
            readOnly={readOnly}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            {...register('token_amount')}
            onChange={(event) => {
              register('token_amount').onChange(event);
              // setValue('token_amount', Number(event.target.value));
              onChange(Number(event.target.value) || 0);
            }}
          />

          {isLogin && (
            <div className="mt-[6px] font-sans text-[13px] text-white/70 leading-[1.2] tracking-[-0.02em]">
              {value.toFixed(2)} {currentIssuer.coin_name}
            </div>
          )}
        </div>

        <div
          className={cn(
            isLogin ? "flex flex-col justify-start items-end" : ""
          )}
        >
          <DropdownModal
            isLogin={isLogin}
            readOnly={readOnly}
            setCurrentIssuer={setCurrentIssuer}
            currentIssuer={currentIssuer}
            setActiveTab={setActiveTab}
            setPredefinedSwapBuy={setPredefinedSwapBuy}
          />

          {isLogin && (
            <div className="mt-[6px] w-full font-sans text-[13px] text-white/70 text-right leading-[1.2] tracking-[-0.02em]">
              Balance: 0
            </div>
          )}
        </div>
      </div>

      <div className="m-0 min-w-0 font-bold text-[#6F737E] text-sm tracking-[-0.01em]">
        {/* 100 EURO */}
      </div>
    </div>
  );
};

export default FieldInvest;
