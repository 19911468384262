import React from 'react';
import { motion } from 'framer-motion';
import ShortToken from '../../../components/shared/ShortToken';
import AssetsTable from './AssetsTable';

const demoWallets = [
  {
    wallet_address: '0x7e5145f3d6b2e8d1f098c',
    totalValue: 60002.50,
    assets: [
      {
        symbol: 'ETH',
        chain_index: '1',
        balance: 25.3458,
        price: 2890.45,
        allocation: 65.4
      },
      {
        symbol: 'MATIC',
        chain_index: '137',
        balance: 15000.234,
        price: 0.89,
        allocation: 24.6
      },
      {
        symbol: 'USDT',
        chain_index: '1',
        balance: 5000.00,
        price: 1.00,
        allocation: 10.0
      }
    ]
  },
  {
    wallet_address: '0x9f8234a1c5d7e9b2f3a4d6',
    totalValue: 21000.75,
    assets: [
      {
        symbol: 'BNB',
        chain_index: '56',
        balance: 45.678,
        price: 345.67,
        allocation: 75.3
      },
      {
        symbol: 'AVAX',
        chain_index: '43114',
        balance: 234.567,
        price: 23.45,
        allocation: 24.7
      }
    ]
  }
];

const WalletAssetsView = ({ wallets = demoWallets, getNetworkName }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="show"
      className="space-y-6"
    >
      {wallets.map((wallet) => (
        <motion.div
          key={wallet.wallet_address}
          variants={itemVariants}
          className="bg-black-dark rounded-xl overflow-hidden"
        >
          <div className="flex justify-between items-center p-4 border-b border-white/10">
            <div className="font-sans font-semibold text-white">
              <ShortToken original={wallet.wallet_address} hasCopy />
            </div>
            <span className="font-sans text-white">
              ${wallet.totalValue.toFixed(2)}
            </span>
          </div>
          <div className="p-4">
            <AssetsTable
              assets={wallet.assets}
              getNetworkName={getNetworkName}
            />
          </div>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default WalletAssetsView;
