import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useFormContext, Controller } from 'react-hook-form'
import './style.css'

const PhoneField = ({
  name,
  placeholder,
  label,
  validator,
  defaultValue,
  customClassOnField,
  onFocus,
  onBlur,
}) => {
  const { control } = useFormContext()

  return (
    <div className="relative field__wrapper field__wrapper-phone">
      {label && <label htmlFor={name} className="mb-2 font-semibold">{label}</label>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        rules={validator || {}}
        render={({ field, fieldState: { error } }) => (
          <>
            <div className="relative">
              <PhoneInput
                {...field}
                country="us"
                inputClass={`${customClassOnField} w-full p-4 bg-black-light text-base leading-6 font-normal text-white opacity-90 transition-colors duration-[0.2s] ease-linear focus:bg-gray-700 rounded-xl outline-none`}
                placeholder={placeholder}
                containerClass="phone-container"
                buttonClass="phone-button"
                inputProps={{
                  name,
                  required: true,
                }}
                onChange={field.onChange}
                value={field.value}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            </div>
            {error && <span className="block pt-1 text-[13px] text-red-200">{error.message}</span>}
          </>
        )}
      />
    </div>
  )
}

export default PhoneField
