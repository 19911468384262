// src/pages/Signup/components/SignupMethod/WalletOptions.jsx

import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next'
import Icons from '../../../../components/shared/Icons'
import { createCoinbaseWalletSDK } from '@coinbase/wallet-sdk';
import Web3 from 'web3';
import { REQUEST_NONCE, METAMASK_REGISTER } from '../../../../myHooks/useMetamaskAuth';
import config from '../../../../config';
import { useCookies } from 'react-cookie';
import { Buffer } from 'buffer';
import { toaster } from '../../../../utils'
import { UseIsMax480 } from '../../../../components/shared/MediaQueries'

// eslint-disable-next-line
import { useAppKit, useAppKitAccount, useAppKitProvider, useDisconnect } from '@reown/appkit/react'
import { providers } from 'ethers'
import { useCoinbaseAuth } from '../../../../myHooks/useCoinbaseAuth'
import { getNetworkName } from '../../../../myHooks/useWeb3Provider';

const WalletOptions = ({
  signupData,
  isModalView = false,
  onWalletClick,
  onPasskeyClick,
  onWalletSelect,
  onClose,
  onNext,
}) => {
  const { t } = useTranslation()
  const [requestNonce] = useMutation(REQUEST_NONCE);
  const [metamaskRegister] = useMutation(METAMASK_REGISTER);
  const [, setCookie] = useCookies()
  const [wcInfoModalOpen, setWcInfoModalOpen] = useState(null);
  const { open: openAppKitModal } = useAppKit()
  const { address: wcAddress, isConnected } = useAppKitAccount()
  const { walletProvider } = useAppKitProvider('eip155')
  const { disconnect } = useDisconnect();
  const [wcSignature, setWcSignature] = useState(null);
  const { address: coinbaseAddress, connectWallet } = useCoinbaseAuth(true)
  const isMobile = UseIsMax480()

  async function wcSignAndLogin() {
    try {
      if (!isConnected) {
        toaster.error('User disconnected');
        return
      }

      setWcInfoModalOpen(true);

      const ethersProvider = new providers.Web3Provider(walletProvider)

      console.log('wcAddress', wcAddress);

      const { data: nonceData } = await requestNonce({
        variables: { address: wcAddress }
      });

      // Create message to sign
      const message = `Welcome to Investhub.io!\n\nTo access features related to security tokens, simply click the "Sign" button—no username or password is required, and this action will not incur any gas fees.\n\nBy clicking "Sign," you agree to Investhub Genossenschaft LVC's Terms and Conditions and confirm that you have read and understood their Privacy Policy.\n\nURI:\napp.investhub.io\n\nNonce: ${nonceData.requestMetamaskNonce}`;
      const hexMessage = `0x${Buffer.from(message, "utf8").toString("hex")}`

      let isGnosisSafe = false;
      let safePopup = null;

      const web3 = new Web3(walletProvider);
      const code = await web3.eth.getCode(wcAddress)
      const chainId = await web3.eth.getChainId()

      const network = getNetworkName(chainId)

      if (code !== '0x') {
        isGnosisSafe = true;
      }

      if (isGnosisSafe) {
        const safeUrl = `https://app.safe.global/home?safe=${network}:${wcAddress}`
        safePopup = window.open(safeUrl, 'SafeTransaction', 'width=600,height=800');
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }

      // Request signature from user
      const signature = await ethersProvider.provider.request({
        method: 'personal_sign',
        params: [
          hexMessage,
          wcAddress,
        ]
      });

      if (safePopup) {
        safePopup.close();
      }

      console.log('signature', signature);

      setWcSignature(signature);

      // Login with signature
      const { data: loginData } = await metamaskRegister({
        variables: {
          input: {
            address: wcAddress,
            accountType: (signupData || {}).accountType,
            referralModel: (signupData || {}).referralModel,
            signature,
            loginProvider: 'walletconnect'
          }
        }
      });

      setCookie(config.jwtCookie, loginData.metamaskRegister.accessToken, { path: '/' })

      console.log('User signed up with address:', wcAddress);
      setWcInfoModalOpen(false);

      disconnect();
      onNext({
        method: 'wallet-connect'
      })
    } catch (error) {
      console.log(error)
      disconnect();
      // toaster.error(error.message);
      setWcInfoModalOpen(false);
    }
  }

  console.log('isConnected', isConnected)

  useEffect(() => {
    if (isModalView && wcAddress && !wcSignature) {
      wcSignAndLogin();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalView, wcAddress, wcSignature])

  const handleCoinBaseSignUp = async () => {
    try {
      const data = await connectWallet();

      setCookie(config.jwtCookie, data.metamaskRegister.accessToken, { path: '/' })

      console.log('User signed up with address:', coinbaseAddress);
      onNext({
        method: 'coinbase'
      })
    } catch (err) {
      console.error('Failed to connect wallet. Please try again.', err);
    }
  };

  const baseButtonClass = `
    pw:inline-flex pw:justify-center pw:items-center
    pw:bg-black-light hover:pw:bg-gray-lighter hover:pw:opacity-90
    pw:transition-colors pw:duration-[0.2s]
    pw:font-medium pw:text-white pw:cursor-pointer
  `

  const mainButtonClass = `
    ${baseButtonClass}
    pw:m-0 pw:px-6 pw:rounded-3xl pw:h-10
    pw:text-[100%] pw:normal-case pw:leading-none
    pw:outline-none
  `

  const modalButtonClass = `
    ${baseButtonClass}
    pw:shadow-[#6F737E_0_0_0_1px_inset]
    pw:m-0 pw:px-6 pw:rounded-[40px]
    pw:w-full pw:max-767:w-[initial] pw:!h-12
    pw:text-sm
  `

  if (isConnected && wcInfoModalOpen) {
    return (
      <>
        <div className="pw:max-767:flex pw:max-767:flex-row pw:max-767:flex-wrap pw:justify-center pw:max-767:justify-center pw:items-stretch pw:max-767:items-center pw:gap-x-4 pw:gap-y-4 pw:mx-auto pw:my-0 pw:p-0 pw:px-2 pw:border-0 pw:align-baseline">
          <h1 className="pw:m-0 pw:font-semibold pw:text-[28px] pw:text-white pw:text-center pw:leading-9 pw:transition-[color] pw:duration-[0.2s] pw:ease-[ease-out]">
            {t('Please sign the message in wallet app!')}
          </h1>
        </div>

        <div className='mx-auto mt-4 pw:max-w-72'>
          <button className={modalButtonClass} onClick={disconnect}>
            <Icons
              nameIcon="walletconnect"
              className="pw:mr-2 pw:rounded-full pw:w-6 pw:h-6"
            />
            <span className="pw:inline-block">{t('Disconnect')}</span>
          </button>
        </div>

        <div className="pw:mt-6 pw:w-full pw:text-[13px] pw:text-gray-lighter pw:text-center">
          {t('Need help?')}{' '}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
          <a href="#" className="hover:pw:opacity-80 pw:font-semibold pw:text-white pw:transition-opacity pw:duration-[0.2s]">{t('Click here')}</a>.
        </div>
      </>
    )
  }

  if (isModalView) {
    return (
      <>
        <div className="pw:max-767:flex pw:max-767:flex-row pw:max-767:flex-wrap pw:justify-center pw:max-767:justify-center pw:items-stretch pw:max-767:items-center pw:gap-x-4 pw:gap-y-4 pw:grid pw:grid-cols-[1fr_1fr_1fr] pw:grid-rows-[auto] pw:auto-cols-[1fr] pw:mx-auto pw:my-0 pw:p-0 pw:px-2 pw:border-0 pw:align-baseline">
          { !isMobile && (
            <button
              className={modalButtonClass}
              onClick={() => {
                onWalletSelect('metamask')
                onClose()
              }}
            >
              <Icons
                nameIcon="metamask"
                className="pw:mr-2 pw:rounded-full pw:w-6 pw:h-6"
              />
              <span className="pw:inline-block">{t('MetaMask')}</span>
            </button>
          )}

          <button className={modalButtonClass} onClick={isConnected ? wcSignAndLogin : openAppKitModal}>
            <Icons
              nameIcon="walletconnect"
              className="pw:mr-2 pw:rounded-full pw:w-6 pw:h-6"
            />
            <span className="pw:inline-block">{t('WalletConnect')}</span>
          </button>

          <button
            className={modalButtonClass}
            onClick={handleCoinBaseSignUp}
          >
            <Icons
              nameIcon="coinbase"
              className="pw:mr-2 pw:rounded-full pw:w-6 pw:h-6"
            />
            <span className="pw:inline-block">{t('Coinbase')}</span>
          </button>
        </div>

        <div className="pw:mt-6 pw:w-full pw:text-[13px] pw:text-gray-lighter pw:text-center">
          {t('Need help?')}{' '}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
          <a href="#" className="hover:pw:opacity-80 pw:font-semibold pw:text-white pw:transition-opacity pw:duration-[0.2s]">{t('Click here')}</a>.
        </div>
      </>
    )
  }

  return (
    <>
    <button
      onClick={onWalletClick}
      className={mainButtonClass}
    >
      {t('Connect Wallet')}
    </button>
    <button
      onClick={onPasskeyClick}
      className={mainButtonClass}
    >
      {t('Passkey')}
    </button>
</>
  )
}

export default WalletOptions
