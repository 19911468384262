const crosschainSupportToken = [
  {
    chainId: 8453,
    chainName: 'base',
    tokens: [
      {
        name: "Ethereum",
        address: "0x4200000000000000000000000000000000000006",
        symbol: "ETH",
        decimals: 18,
        chainId: 8453,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
      },
      {
        name: "Wrapped Ether",
        address: "0x4200000000000000000000000000000000000006",
        symbol: "WETH",
        decimals: 18,
        chainId: 8453,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
      },
      {
        name: "USD Coin",
        address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
        symbol: "USDC",
        decimals: 6,
        chainId: 8453,
        logoURI: "https://ethereum-optimism.github.io/data/USDC/logo.png",
        extensions: {
          bridgeInfo: {
            1: {
              tokenAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
            },
          },
        },
      },
      {
        chainId: 8453,
        address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
        name: "USD Base Coin",
        symbol: "USDbC",
        decimals: 6,
        logoURI: "https://ethereum-optimism.github.io/data/USDC/logo.png",
      },
      {
        name: "Dai Stablecoin",
        address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
        symbol: "DAI",
        decimals: 18,
        chainId: 8453,
        logoURI: "https://ethereum-optimism.github.io/data/DAI/logo.svg",
        extensions: {
          bridgeInfo: {
            1: {
              tokenAddress: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
            },
          },
        },
      },
      {
        name: "Balancer",
        address: "0x4158734D47Fc9692176B5085E0F52ee0Da5d47F1",
        symbol: "BAL",
        decimals: 18,
        chainId: 8453,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xba100000625a3754423978a60c9317c58a424e3D/logo.png",
        extensions: {
          bridgeInfo: {
            1: {
              tokenAddress: "0xba100000625a3754423978a60c9317c58a424e3D",
            },
          },
        },
      },
    ],
  },
  {
    chainId: 1,
    chainName: 'ethereum',
    tokens: [
      {
        name: "Ethereum",
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        symbol: "ETH",
        decimals: 18,
        chainId: 1,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
      },
      {
        name: "Wrapped Ether",
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        symbol: "WETH",
        decimals: 18,
        chainId: 1,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
        extensions: {
          bridgeInfo: {
            10: {
              tokenAddress: "0x4200000000000000000000000000000000000006",
            },
            56: {
              tokenAddress: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
            },
            137: {
              tokenAddress: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
            },
            8453: {
              tokenAddress: "0x4200000000000000000000000000000000000006",
            },
            42161: {
              tokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
            },
            42220: {
              tokenAddress: "0x2DEf4285787d58a2f811AF24755A8150622f4361",
            },
            43114: {
              tokenAddress: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
            },
          },
        },
      },
      {
        name: "USDCoin",
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        symbol: "USDC",
        decimals: 6,
        chainId: 1,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
        extensions: {
          bridgeInfo: {
            10: {
              tokenAddress: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
            },
            56: {
              tokenAddress: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
            },
            130: {
              tokenAddress: "0x078D782b760474a361dDA0AF3839290b0EF57AD6",
            },
            137: {
              tokenAddress: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
            },
            8453: {
              tokenAddress: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
            },
            42161: {
              tokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
            },
            42220: {
              tokenAddress: "0xcebA9300f2b948710d2653dD7B07f33A8B32118C",
            },
            43114: {
              tokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
            },
          },
        },
      },
      {
        name: "Dai Stablecoin",
        address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        symbol: "DAI",
        decimals: 18,
        chainId: 1,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
        extensions: {
          bridgeInfo: {
            10: {
              tokenAddress: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
            },
            56: {
              tokenAddress: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
            },
            130: {
              tokenAddress: "0x20CAb320A855b39F724131C69424240519573f81",
            },
            137: {
              tokenAddress: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
            },
            8453: {
              tokenAddress: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
            },
            42161: {
              tokenAddress: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
            },
            43114: {
              tokenAddress: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
            },
          },
        },
      },
      {
        name: "Wrapped BTC",
        address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
        symbol: "WBTC",
        decimals: 8,
        chainId: 1,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
        extensions: {
          bridgeInfo: {
            10: {
              tokenAddress: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
            },
            130: {
              tokenAddress: "0x927B51f251480a681271180DA4de28D44EC4AfB8",
            },
            137: {
              tokenAddress: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
            },
            42161: {
              tokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
            },
            43114: {
              tokenAddress: "0x50b7545627a5162F82A992c33b87aDc75187B218",
            },
          },
        },
      },
      {
        name: "Balancer",
        address: "0xba100000625a3754423978a60c9317c58a424e3D",
        symbol: "BAL",
        decimals: 18,
        chainId: 1,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xba100000625a3754423978a60c9317c58a424e3D/logo.png",
        extensions: {
          bridgeInfo: {
            10: {
              tokenAddress: "0xFE8B128bA8C78aabC59d4c64cEE7fF28e9379921",
            },
            130: {
              tokenAddress: "0x01625E26274Ed828Ac1d47694c97221b34a8ADdF",
            },
            137: {
              tokenAddress: "0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3",
            },
            8453: {
              tokenAddress: "0x4158734D47Fc9692176B5085E0F52ee0Da5d47F1",
            },
            42161: {
              tokenAddress: "0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8",
            },
          },
        },
      },
      {
        name: "UMA Voting Token v1",
        address: "0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828",
        symbol: "UMA",
        decimals: 18,
        chainId: 1,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828/logo.png",
        extensions: {
          bridgeInfo: {
            10: {
              tokenAddress: "0xE7798f023fC62146e8Aa1b36Da45fb70855a77Ea",
            },
            130: {
              tokenAddress: "0x478923278640a10A60951E379aFFb60772435f8C",
            },
            137: {
              tokenAddress: "0x3066818837c5e6eD6601bd5a91B0762877A6B731",
            },
            42161: {
              tokenAddress: "0xd693Ec944A85eeca4247eC1c3b130DCa9B0C3b22",
            },
            43114: {
              tokenAddress: "0x3Bd2B1c7ED8D396dbb98DED3aEbb41350a5b2339",
            },
          },
        },
      },
    ],
  },
  {
    chainId: 137,
    chainName: 'polygon',
    tokens: [
      {
        name: "Wrapped Ether",
        address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
        symbol: "WETH",
        decimals: 18,
        chainId: 137,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
        extensions: {
          bridgeInfo: {
            1: {
              tokenAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
            },
          },
        },
      },
      {
        name: "USDCoin",
        address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
        symbol: "USDC",
        decimals: 6,
        chainId: 137,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
      },
      {
        name: "USDCoin (PoS)",
        address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        symbol: "USDC.e",
        decimals: 6,
        chainId: 137,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
        extensions: {
          bridgeInfo: {
            1: {
              tokenAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
            },
          },
        },
      },
      {
        name: "Dai Stablecoin",
        address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
        symbol: "DAI",
        decimals: 18,
        chainId: 137,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
        extensions: {
          bridgeInfo: {
            1: {
              tokenAddress: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
            },
          },
        },
      },
      {
        name: "Wrapped BTC",
        address: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
        symbol: "WBTC",
        decimals: 8,
        chainId: 137,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
        extensions: {
          bridgeInfo: {
            1: {
              tokenAddress: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
            },
          },
        },
      },
      {
        name: "Balancer",
        address: "0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3",
        symbol: "BAL",
        decimals: 18,
        chainId: 137,
        logoURI:
          "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xba100000625a3754423978a60c9317c58a424e3D/logo.png",
        extensions: {
          bridgeInfo: {
            1: {
              tokenAddress: "0xba100000625a3754423978a60c9317c58a424e3D",
            },
          },
        },
      },
    ],
  },
]

export default crosschainSupportToken
