import gql from 'graphql-tag'

export const GET_ALL_SPLIT_PAY_CONTRACTS = gql`
  query getAllSplitPayContracts {
    getAllSplitPayContracts {
      id
      name
      address
      token_address
      white_list_address
      batch_size
      network
      currency
      currency_decimals
      date
    }
  }
`;