/* eslint-disable import/no-extraneous-dependencies */
import { http, useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';
import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors';
import { useCapabilities, useWriteContracts } from 'wagmi/experimental';
import contractAbi from '../../../../../constants/smartContract';
import { base, baseSepolia, sepolia, mainnet, polygon } from '@wagmi/core/chains'
import { WC_PROJECT_ID } from '../../../../../config'
import { useWagmiConfig } from '../../../../../myHooks/useWagmi'
import { getAccount, createConfig, getTransaction } from '@wagmi/core'
import { isProduction } from '../../../../../utils';
import { getCoinAddress, getCollectingFeeAddress, STABLE_COIN_ENUM } from '../../../../../myHooks/useWeb3Provider';

export const useCoinbaseTrans = () => {
  const wagmiConfig = useWagmiConfig();

  const { address, isConnected } = useAccount();
  const { connect } = useConnect();
  const { disconnect } = useDisconnect();
  const [isProcessing, setIsProcessing] = useState(false);
  const { signMessageAsync } = useSignMessage();

  // Configure `useWriteContracts` to call the mint function on the contract
  const { writeContractsAsync } = useWriteContracts();

  // Check for paymaster capabilities with `useCapabilities`
  const { data: availableCapabilities, isLoading } = useCapabilities({
    account: address,
  });

  const availableCapabilitiesRef = useRef(null);

  useEffect(() => {
    if (availableCapabilities) {
      availableCapabilitiesRef.current = availableCapabilities;
    }
  }, [availableCapabilities]);

  const handleApprove = async (value, currency, currentIssuer, tokenBalances = []) => {
    if (!isConnected) {
      await wagmiConfig.connectors[0].connect();
      await connect({ connector: wagmiConfig.connectors[0] })
    }

    setIsProcessing(true);
    const coinAddress = getCoinAddress(currency, currentIssuer.network, tokenBalances)

    let contractAddress;

    if (currentIssuer.coin_name === 'DAKS' || currentIssuer.coin_name === 'DEXA') {
      contractAddress = currentIssuer.contract || process.env.REACT_APP_SMART_CONTRACT_ADDRESS
    } else {
      contractAddress = getCollectingFeeAddress(currentIssuer.network);
    }

    const abi = [
      {
        "constant": false,
        inputs: [
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' }
        ],
        name: 'approve',
        outputs: [
          { name: '', type: 'bool' }
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
    ];

    try {
      console.log('before writeContracts')

      const capabilitiesForChain = availableCapabilitiesRef.current
        ? availableCapabilitiesRef.current[isProduction ? base.id : baseSepolia.id]
        : null;

      const dynamicCapabilities = capabilitiesForChain && capabilitiesForChain.paymasterService.supported
      ? {
          paymasterService: {
            url: process.env.REACT_APP_PAYMASTER_AND_BUNDLER_ENDPOINT,
          },
        }
      : {};

      console.log('dynamicCapabilities', dynamicCapabilities);

      let chainId = isProduction ? base.id : baseSepolia.id;

      if (currentIssuer.network === 'mainnet') {
        chainId = isProduction ? mainnet.id : sepolia.id;
      } else if (currentIssuer.network === 'mainnet-only') {
        chainId = mainnet.id;
      } else if (currentIssuer.network === 'polygon') {
        chainId = isProduction ? polygon.id : '80002'; // amoy
      }

      const result = await writeContractsAsync({
        contracts: [
          {
            address: coinAddress,
            abi,
            functionName: 'approve',
            args: [contractAddress, BigInt(value).toString()],
          },
        ],
        chainId,
        capabilities: dynamicCapabilities,
        options: {
          paymasterService: dynamicCapabilities.paymasterService,
        },
      });

      setIsProcessing(false);
      return result; // Return the hash instead of the full result
    } catch (error) {
      console.error('Approving failed:', error);
      setIsProcessing(false);

      return null;
    }
  };

  const handleSignMessage = async (message) => {
    console.log('address', address);

    console.log('isConnected', isConnected)

    if (!isConnected) {
      console.log('connect')
      console.log('wagmiConfig.connectors', wagmiConfig.connectors[0])
      await wagmiConfig.connectors[0].connect();
      await connect({ connector: wagmiConfig.connectors[0] })
    }

    try {
      console.log('before handleSignMessage')
      const result = await signMessageAsync({
        message,
      })

      setIsProcessing(false);
      return result; // Return the hash instead of the full result
    } catch (error) {
      console.error('Approving failed:', error);
      setIsProcessing(false);

      return null;
    }
  };

  const handleBuyToken = async (currencyAmount, coinEnum, currentIssuer, currency, buyDetails, tokenBalances = []) => {
    console.log('handleBuyToken');
    setIsProcessing(true);
    let contractAddress;

    if (currentIssuer.coin_name === 'DAKS' || currentIssuer.coin_name === 'DEXA') {
      contractAddress = currentIssuer.contract || process.env.REACT_APP_SMART_CONTRACT_ADDRESS
    } else {
      contractAddress = getCollectingFeeAddress(currentIssuer.network);
    }

    const capabilitiesForChain = availableCapabilitiesRef.current
      ? availableCapabilitiesRef.current[isProduction ? base.id : baseSepolia.id]
      : null;

    const dynamicCapabilities = capabilitiesForChain && capabilitiesForChain.paymasterService.supported
      ? {
          paymasterService: {
            url: process.env.REACT_APP_PAYMASTER_AND_BUNDLER_ENDPOINT,
          },
        }
      : {};

    try {
      console.log('dynamicCapabilities', dynamicCapabilities);

      const coinAddress = getCoinAddress(currency, currentIssuer.network, tokenBalances)
      console.log('coinAddress', coinAddress)
      const smartContractAddress = currentIssuer.contract || process.env.REACT_APP_SMART_CONTRACT_ADDRESS

      let chainId = isProduction ? base.id : baseSepolia.id;

      if (currentIssuer.network === 'mainnet') {
        chainId = isProduction ? mainnet.id : sepolia.id;
      } else if (currentIssuer.network === 'mainnet-only') {
        chainId = mainnet.id;
      } else if (currentIssuer.network === 'polygon') {
        chainId = isProduction ? polygon.id : '80002'; // amoy
      }

      if (currentIssuer.currencies.includes(currency)) {
        console.log('currencyAmount', BigInt(currencyAmount).toString())
        console.log('smartContractAddress', smartContractAddress)
        console.log('coinEnum', coinEnum)
        const result = await writeContractsAsync({
          contracts: [
            {
              address: contractAddress,
              abi: contractAbi.feeContractAbi,
              functionName: 'buyTokensWithERC20',
              args: [coinAddress, smartContractAddress, BigInt(currencyAmount).toString(), `${coinEnum}`],
            },
          ],
          chainId,
          capabilities: dynamicCapabilities,
          options: {
            paymasterService: dynamicCapabilities.paymasterService,
          },
        });

        setIsProcessing(false);
        return result;
      } else {
        console.log('send swapAndBuyCrowdsaleTokens', {
          currency,
          tokenIn: ['eth', 'pol'].includes(currency) ? '0x0000000000000000000000000000000000000000' : coinAddress,
          amountIn: BigInt(currencyAmount).toString(),
          tokenOut: getCoinAddress('usdc', currentIssuer.network),
          amountOutMin: 0,
          fee: buyDetails.swapFeePercentage * 1000000,
          crowdsaleContract: smartContractAddress,
          stableCoinType: STABLE_COIN_ENUM['usdc'],
        })

        const result = await writeContractsAsync({
          contracts: [
            {
              address: contractAddress,
              abi: contractAbi.feeContractAbi,
              functionName: 'swapAndBuyCrowdsaleTokens',
              args: [
                ['eth', 'pol'].includes(currency) ? '0x0000000000000000000000000000000000000000' : coinAddress,
                BigInt(currencyAmount).toString(),
                getCoinAddress('usdc', currentIssuer.network),
                '0',
                buyDetails.swapFeePercentage * 1000000,
                smartContractAddress,
                STABLE_COIN_ENUM['usdc'],
                '0x',
              ],
              value: ['eth', 'pol'].includes(currency) ? BigInt(currencyAmount).toString() : 0,
            },
          ],
          chainId,
          capabilities: dynamicCapabilities,
          options: {
            paymasterService: dynamicCapabilities.paymasterService,
          },
        });

        setIsProcessing(false);
        return result;
      }
    } catch (error) {
      console.error('Approving failed:', error);
      setIsProcessing(false);

      return null;
    }
  };

  // useEffect(() => {
  //   console.log('xxxxx')
  //   const fetchTrans = async () => {
  //     const transaction = await getTransaction(wagmiConfig, {
  //       hash: '0x60e447c6f4f58d62e0fd52e2dd080ea6abf1b0895104d2a146fad8deeea8db4c0000000000000000000000000000000000000000000000000000000000014a34',
  //       index: 0,
  //       chainId: BASE_SEPOLIA_CHAIN_ID,
  //     });

  //     console.log('transaction', transaction)
  //   }

  //   fetchTrans();
  // }, [wagmiConfig])

  return {
    isConnected,
    connect,
    disconnect,
    handleApprove,
    isProcessing,
    handleBuyToken,
    handleSignMessage,
  }
}

export default useCoinbaseTrans;
