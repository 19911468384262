/* eslint-disable import/no-extraneous-dependencies */
import { createCoinbaseWalletSDK } from '@coinbase/wallet-sdk';
import { useState, useCallback } from 'react';
import { createWalletClient, custom } from 'viem'
import { mainnet, sepolia, baseSepolia, base } from 'viem/chains'
import { useMetamaskAuth } from './useMetamaskAuth'
import { isProduction } from '../utils';

// Constants
const APP_NAME = 'Investhub'
const APP_LOGO_URL = 'https://app.investhub.io/dark-mode-investhub-logo.svg'
const APP_SUPPORTED_CHAIN_IDS = [1, 11155111, 8453, 84532]

// Initialize Coinbase Wallet SDK
const coinbaseWallet = createCoinbaseWalletSDK({
  appName: APP_NAME,
  appLogoUrl: APP_LOGO_URL,
  chainIds: APP_SUPPORTED_CHAIN_IDS,
})

// Initialize a Web3 Provider object
export const ethereum = coinbaseWallet.getProvider()

export const walletClient = createWalletClient({
  chain: isProduction ? base : baseSepolia,
  transport: custom(ethereum),
})

export const useCoinbaseAuth = (isRegister = false) => {
  const [address, setAddress] = useState(null);
  const [signature, setSignature] = useState(null);
  const { metamaskLogin, metamaskRegister, requestNonce } = useMetamaskAuth()
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const connectWallet = useCallback(async (signupData) => {
    try {
      setLoading(true);
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      const walletAddress = accounts[0];
      setAddress(walletAddress);

      const { data: nonceData } = await requestNonce({
        variables: { address: walletAddress }
      });

      // Create message to sign
      const message = `Welcome to Investhub.io!\n\nTo access features related to security tokens, simply click the "Sign" button—no username or password is required, and this action will not incur any gas fees.\n\nBy clicking "Sign," you agree to Investhub Genossenschaft LVC's Terms and Conditions and confirm that you have read and understood their Privacy Policy.\n\nURI:\napp.investhub.io\n\nNonce: ${nonceData.requestMetamaskNonce}`;

      const signature = await walletClient.signMessage({
        account: walletAddress,
        message: message,
      })

      setSignature(signature)
      setLoading(false);

      if (isRegister) {
        // Register with signature
        const { data } = await metamaskRegister({
          variables: {
            input: {
              address: walletAddress,
              accountType: (signupData || {}).accountType,
              referralModel: (signupData || {}).referralModel,
              loginProvider: 'coinbase',
              signature: signature
            }
          }
        });

        setLoading(false);
        return data;
      }

      return { address: walletAddress, signature };
    } catch (error) {
      console.error('Error connecting wallet:', error);
      setError(error)
      setLoading(false);
      ethereum.disconnect();

      throw error;
    }
  }, [isRegister, metamaskRegister, requestNonce])

  const loginWithSignature = useCallback(async (twoFaToken) => {
    try {
      if (!address || !signature) {
        throw new Error('No address. Connect the wallet first!');
      }
      console.log('signature', signature)

      const { data } = await metamaskLogin({
        variables: {
          input: {
            address,
            signature,
            twoFaToken,
            loginProvider: 'coinbase',
          }
        }
      });

      setLoading(false);
      return data;
    } catch (error) {
      console.error('Failed to connect wallet. Please try again.', error);
      setError(error)
      setLoading(false);
      throw error;
    }
  }, [address, signature, metamaskLogin]);

  return {
    address,
    connectWallet,
    loginWithSignature,
    loading,
    error,
  }
}

export default useCoinbaseAuth;
