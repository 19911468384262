import React, { useState, useRef } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

const BirthDayField = ({
  name,
  placeholder,
  label,
  icon,
  validator,
  customClassOnField,
  onFocus,
  onBlur,
  readOnly
}) => {
  const { control } = useFormContext();
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  const validateDay = (day, month, year) => {
    if (!day) return true;
    const dayNum = parseInt(day);
    if (Number.isNaN(dayNum)) return false;
    if (dayNum < 1 || dayNum > 31) return false;
    if (month) {
      const monthNum = parseInt(month);
      const daysInMonth = new Date(year || 2000, monthNum, 0).getDate();
      return dayNum <= daysInMonth;
    }
    return true;
  };

  const validateMonth = (month) => {
    if (!month) return true;
    const monthNum = parseInt(month);
    return !Number.isNaN(monthNum) && monthNum >= 1 && monthNum <= 12;
  };

  const validateYear = (year) => {
    if (!year) return true;
    const yearNum = parseInt(year);
    return !Number.isNaN(yearNum) && yearNum <= 2000;
  };

  const handleKeyPress = (e, type, nextRef, prevRef) => {
    const value = e.target.value;

    // Auto-advance to next field
    if (type === 'day' && value.length === 2 && nextRef.current) {
        nextRef.current.focus();
    } else if (type === 'month' && value.length === 2 && nextRef.current) {
        nextRef.current.focus();
    } else if (type === 'year' && value.length === 4 && nextRef.current) {
        nextRef.current.focus();
    }

    // Allow backspace to previous field
    if (e.key === 'Backspace' && value.length === 0 && prevRef && prevRef.current) {
        prevRef.current.focus();
    }
  };

  return (
    <div className="relative w-full field__wrapper">
      {label && <label htmlFor={name} className="mb-2 font-semibold">{label}</label>}

      <Controller
        name={name}
        control={control}
        rules={validator || {}}
        render={({ field, fieldState: { error } }) => {
          const dateValues = field.value ? field.value.split("-") : ["", "", ""];
          const [year, month, day] = dateValues;

          const handleDateChange = (type, value) => {
            let dates = [...dateValues];
            let newValue = value.replace(/\D/g, '');

            switch (type) {
              case "day":
                if (validateDay(newValue, dates[1], dates[0])) {
                  dates[2] = newValue;
                }
                break;
              case "month":
                if (validateMonth(newValue)) {
                  dates[1] = newValue;
                }
                break;
              case "year":
                if (validateYear(newValue)) {
                  dates[0] = newValue;
                }
                break;
              default:
                break;
            }

            // Pad with zeros only when moving to next field
            if (type === 'day' && dates[2].length === 2) {
              dates[2] = dates[2].padStart(2, '0');
            }
            if (type === 'month' && dates[1].length === 2) {
              dates[1] = dates[1].padStart(2, '0');
            }

            field.onChange(dates.join("-"));
          };

          return (
            <>
              <div className="relative">
                {icon && <span className="left-[6px] absolute inset-y-0 flex items-center">{icon}</span>}

                <fieldset className={`flex items-center space-x-1 ${customClassOnField}`}>
                  <input
                    ref={dayRef}
                    maxLength="2"
                    type="text"
                    placeholder="DD"
                    value={day}
                    onChange={(e) => handleDateChange("day", e.target.value)}
                    onKeyUp={(e) => handleKeyPress(e, 'day', monthRef, null)}
                    className="bg-transparent focus:bg-transparent focus:outline-none w-8 focus:text-white text-center"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    readOnly={readOnly}
                  />
                  <span className="text-gray-500">/</span>
                  <input
                    ref={monthRef}
                    maxLength="2"
                    type="text"
                    placeholder="MM"
                    value={month}
                    onChange={(e) => handleDateChange("month", e.target.value)}
                    onKeyUp={(e) => handleKeyPress(e, 'month', yearRef, dayRef)}
                    className="bg-transparent focus:bg-transparent focus:outline-none w-8 focus:text-white text-center"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    readOnly={readOnly}
                  />
                  <span className="text-gray-500">/</span>
                  <input
                    ref={yearRef}
                    maxLength="4"
                    type="text"
                    placeholder="YYYY"
                    value={year}
                    onChange={(e) => handleDateChange("year", e.target.value)}
                    onKeyUp={(e) => handleKeyPress(e, 'year', null, monthRef)}
                    className="bg-transparent focus:bg-transparent focus:outline-none w-14 focus:text-white text-center"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    readOnly={readOnly}
                  />
                </fieldset>
              </div>

              {error && (
                <span className="block relative pt-1 font-sans text-[13px] text-red-200 tracking-[-0.02em]">
                  {error.message}
                </span>
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default BirthDayField;
