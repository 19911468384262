import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import cn from "classnames"
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { FormProvider, useForm } from 'react-hook-form'
import Loader from '../../../components/shared/Loader'
import { useMe } from '../../../myHooks'
import { CREATE_MANAGE_UPGRADE, GET_USER_MANAGE_UPGRADE, GET_EVOLVE_ACCESS_TOKEN } from '../../../queriesAndMutations'
import Icons from '../../../components/shared/Icons'
import ManageUpgradeSubscribe from './ManageUpgradeSubscribe'
import { isProduction, isStaging, toaster } from '../../../utils'

import Heading from '../Heading'
import Separator from "../Separator"

const EVOLVE_URL = isProduction ? 'https://evolve.investhub.io' : isStaging ? 'https://dev-evolve.investhub.io' : 'http://localhost:3001'

const UpgradeSkeleton = () => (
  <>
    <div className="m-0 mb-8 font-sans font-semibold text-white text-xl text-start leading-7 tracking-[-0.02em]">
      <Skeleton width={240} height={16} className="!bg-gray-custom/25 opacity-25 !rounded-2xl" />
    </div>

    <div className="gap-5 max-767:gap-6 grid grid-cols-[1fr] max-767:grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mb-6">
      <Skeleton width={100} height={24} className="!bg-gray-custom/25 opacity-25 !rounded-2xl !w-full" />

      <Skeleton width={100} height={24} className="!bg-gray-custom/25 opacity-25 !rounded-2xl !w-full" />
    </div>
  </>
)

const Upgrade = () => {
  const { t } = useTranslation()

  const { data: { me } = {}, loading } = useMe({ fetchPolicy: 'network-only' })
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [manageUpgradeData, setManageUpgradeData] = useState({})

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [createManageUpgrade] = useMutation(CREATE_MANAGE_UPGRADE)
  const [getEvolveAccessToken] = useMutation(GET_EVOLVE_ACCESS_TOKEN);

  const {
    data: { getUserManageUpgrade: manageUpgrade } = {},
    loading: getUserManageUpgradeLoading,
  } = useQuery(GET_USER_MANAGE_UPGRADE)

  const onSubmit = (input) => {
    setIsSubmitting(true)
    createManageUpgrade({
      variables: {
        input
      }
    }).then((data) => {
      // eslint-disable-next-line no-debugger
      console.log(data)
      setManageUpgradeData({
        ...manageUpgradeData,
        id: data.data.createManageUpgrade.id,
        email: input.email,
      })
      setIsSubmitting(false)
    }).catch((error) => {
      // eslint-disable-next-line no-debugger
      console.error('Error:', error)
      setIsSubmitting(false)
    })
  }

  const onRedirectToEvolve = async () => {
    try {
      const { data } = await getEvolveAccessToken()

      const { volveAccessToken: token, expires } = data.getEvolveAccessToken;
      if (token) {
        window.open(`${EVOLVE_URL}/sso?token=${token}&expires=${expires}`, "_blank")
      } else {
        toaster.error("Can not get the token")
      }
    } catch (e) {
      console.log(e)
      toaster.error("Something went wrong")
    }
  }

  const manageUpgradeMethods = useForm({
    defaultValues: {
      email: '',
    },
  })

  useEffect(() => {
    if (me) {
      manageUpgradeMethods.setValue('email', me.email)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.stripe.com/v3/buy-button.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const stripeMembershipUpgrade = () => {
    return (
      <>
        <Separator />

        <div className="mb-1 font-sans font-semibold text-white text-base text-start leading-6 tracking-[-0.02em]">
          {t('settings.upgrade.stripe_title')}
        </div>
        <div className="mb-6 font-sans text-gray-lighter text-sm tracking-[-0.02em]">Manage your current subscription plan, billing information, and review usage statistics. Your subscription will automatically renew each billing cycle.</div>

        {me.membership_status !== 'active' ? (
          <div className="w-full">
            <stripe-buy-button
              buy-button-id={process.env.REACT_APP_MEMBERSHIP_BUY_BUTTON_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            client-reference-id={`membership_${me.id}`}
              customer-email={me.email}
            />
          </div>
        ) : (
          <div className="w-64">
            <div className="flex flex-col justify-center items-center bg-green-500/20 mb-6 px-6 py-4 rounded-2xl font-sans text-white text-center tracking-[-0.02em]">
              {t('settings.upgrade.stripe_title_active')}
            </div>
          </div>
        )}
      </>
    )
  }

  if (loading || getUserManageUpgradeLoading) return <UpgradeSkeleton />

  if ((manageUpgrade && manageUpgrade.status !== 'active') || manageUpgradeData.id) {
    return (
      <>
        <ManageUpgradeSubscribe data={manageUpgrade || manageUpgradeData} onNext={() => {}} />

        {stripeMembershipUpgrade()}
      </>
    )
  }

  if (manageUpgrade) {
    return (
      <>
        <div className="m-0 mb-8 font-sans font-semibold text-white text-xl text-start leading-7 tracking-[-0.02em]">
          {t('settings.upgrade.title_two')}
        </div>

        <div className="text-left">
          <button
            type="button"
            className="inline-flex justify-center items-center gap-4 bg-primary hover:bg-[#0148dd] hover:opacity-90 m-0 px-6 !rounded-3xl outline-none h-12 overflow-visible font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer"
            onClick={onRedirectToEvolve}
          >
            {t('settings.upgrade.manage_button')}
          </button>
        </div>

        {stripeMembershipUpgrade()}
      </>
    )
  }

  return (
    <>
      <Heading
        title="Upgrade"
      />

      <FormProvider {...manageUpgradeMethods}>
        <form onSubmit={manageUpgradeMethods.handleSubmit(onSubmit)} >
          <div className="mb-1 font-sans font-semibold text-white text-base text-start leading-6 tracking-[-0.02em]">Upgrade your account</div>
          <div className="mb-6 font-sans text-gray-lighter text-sm tracking-[-0.02em]">Upgrade to access premium features including advanced analytics, priority support, and exclusive content. Choose the plan that best fits your needs.</div>

          <div className="gap-5 max-767:gap-6 grid grid-cols-[1fr] max-767:grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mb-6">
            {/* Field email */}
            <div className="relative">
              <label className={cn(
                "flex flex-row flex-wrap gap-1 mb-3 text-[13px] leading-[1.2] tracking-[-0.02em] transition-colors duration-[0.2s] font-semibold font-sans",
                {
                  "text-white": isEmailFocused,
                  "text-gray-lighter": !isEmailFocused
                }
              )}>
                {t('settings.fields.email')}

                <span className="top-[1px] relative ml-[2px] cursor-pointer" data-tooltip-id="tooltip_email">
                  <Icons nameIcon='alert-circle' className="w-[14px] h-[14px]" />
                </span>

                <Tooltip id="tooltip_email" auto effect="solid" className="!inline-flex !justify-center !items-center !bg-white/10 !px-2 !py-[2px] !rounded-md font-sans !font-bold !text-white !text-xs">
                  We'll use this email for your subscription and notifications
                </Tooltip>
              </label>

              <div className="relative">
                <input
                  type="email"
                  {...manageUpgradeMethods.register('email')}
                  disabled={!!(me && me.email)}
                  className={cn(
                    "bg-black-dark opacity-100 !p-4 border-2 border-solid rounded-xl outline-none w-full min-w-0 font-sans font-normal text-white text-base text-start leading-6 transition-all duration-[0.2s] ease-linear",
                    {
                      "border-white/10": isEmailFocused,
                      "border-transparent": !isEmailFocused
                    }
                  )}
                  onFocus={() => setIsEmailFocused(true)}
                  onBlur={() => setIsEmailFocused(false)}
                />
              </div>
            </div>
          </div>

          <div className="text-left">
            <button
              type="submit"
              className="inline-flex justify-center items-center gap-4 bg-primary hover:bg-[#0148dd] hover:opacity-90 m-0 px-6 !rounded-3xl outline-none h-12 overflow-visible font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer"
            >
              {t('settings.upgrade.upgrade')}
              {isSubmitting ? <Loader className='scale-75' /> : '' }
            </button>
          </div>
        </form>
      </FormProvider>

      {stripeMembershipUpgrade()}
    </>
  )
}

export default Upgrade
