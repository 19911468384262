import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { UseIsMax1023 } from "../../../components/shared/MediaQueries";
import Icons from "../../../components/shared/Icons";
import { dropdownFilterVariants } from "../../../utils/animations";

const ViewModeToggle = ({ viewMode, onToggle }) => {
  const isMax1023 = UseIsMax1023();
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (selectedMode) => {
    onToggle(selectedMode);
    setIsOpen(false);
  };

  return (
    <>
      {!isMax1023 ? (
        <div className="flex gap-2">
          <button
            className={`inline-flex justify-center items-center gap-1  m-0 px-6 rounded-[40px] h-10 font-medium text-white cursor-pointer  outline-none normal-case leading-none transition-all duration-[0.2s] font-sans tracking-[-0.02em]  ${
              viewMode === 'combined'
                ? 'text-primary bg-primary/15'
                : 'bg-transparent text-white/70 hover:opacity-90 hover:text-white'
            }`}
            onClick={() => onToggle('combined')}
          >
            Combined
          </button>
          <button
            className={`inline-flex justify-center items-center gap-1  m-0 px-6 rounded-[40px] h-10 font-medium text-white cursor-pointer  outline-none normal-case leading-none transition-all duration-[0.2s] font-sans tracking-[-0.02em]  ${
              viewMode === 'by-wallet'
                ? 'text-primary bg-primary/15'
                : 'bg-transparent text-white/70 hover:opacity-90 hover:text-white'
            }`}
            onClick={() => onToggle('by-wallet')}
          >
            By Wallet
          </button>
        </div>
      ) : (
        <div className="relative w-full">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={`w-full block relative bg-none m-0 py-0 pr-0 pl-4 border-none rounded-xl font-sans h-10 font-semibold text-left text-sm leading-10 tracking-[-0.02em] transition-all duration-[0.2s] cursor-pointer select-none
            ${viewMode ? 'text-white !shadow-[#ffffff33_0_0_0_2px_inset]' : 'text-white/70 !shadow-[#ffffff33_0_0_0_2px_inset]'}`}
          >
            <div className="block z-50 text-nowrap overflow-hidden text-overflow-ellipsis">
              {viewMode === 'combined' ? 'Combined' : 'By Wallet'}
              <Icons
                nameIcon='chevron-down'
                className={`w-4 h-4 absolute right-4 top-3 ${isOpen ? 'rotate-180' : ''}`}
              />
            </div>
          </button>

          <AnimatePresence>
            {isOpen && (
              <motion.ul
                {...dropdownFilterVariants}
                className="top-full z-[9] absolute inset-x-0 bg-[#1e2025] shadow-[#ffffff33_0_0_0_2px_inset] mx-0 mt-0.5 mb-0 px-0 py-2 border-solid rounded-xl w-full overflow-hidden"
              >
                <li
                  className={`block border-0 bg-none m-0 px-4 py-2 min-h-[auto] font-semibold text-left text-sm
                  leading-6 tracking-[-0.02em] transition-all duration-[0.2s] cursor-pointer font-sans
                  ${viewMode === 'by-wallet' ? "text-[#0052ff]" : "text-white/70 hover:text-white"}`}
                  onClick={() => handleSelect('by-wallet')}
                >
                  By Wallet
                </li>
                <li
                  className={`block border-0 bg-none m-0 px-4 py-2 min-h-[auto] font-semibold text-left text-sm
                  leading-6 tracking-[-0.02em] transition-all duration-[0.2s] cursor-pointer font-sans
                  ${viewMode === 'combined' ? "text-[#0052ff]" : "text-white/70 hover:text-white"}`}
                  onClick={() => handleSelect('combined')}
                >
                  Combined
                </li>
              </motion.ul>
            )}
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

export default ViewModeToggle;
