/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useFormContext } from 'react-hook-form'
import { createRoot } from 'react-dom/client';
import cn from "classnames";
import Avatar from 'react-avatar';
// Shared
import Icons from "../../../../../components/shared/Icons";
import { Token, TokenSkeleton } from "../../../../../components/shared/Cards";

const ListTokens = ({ readOnly, currentIssuer, tokenBalances }) => {
  const { getValues, setValue, watch } = useFormContext()
  const allowedTokens = tokenBalances || [];
  const defaultToken = allowedTokens.find((token) => token.symbol.toLowerCase() === (getValues('currency')));
  const [currentToken, setCurrentToken] = useState(defaultToken);
  const [filteredTokens, setFilteredTokens] = useState([]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const searchInputRef = useRef(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const getNetworkImage = (network) => {
    try {
      const networks = {
        'polygon': require('../../../../../assets/images/tokenSymbol/polygon.png'),
        'base': require('../../../../../assets/images/tokenSymbol/base.svg'),
        'ethereum': require('../../../../../assets/images/tokenSymbol/ethereum.png'),
        'mainnet': require('../../../../../assets/images/tokenSymbol/ethereum.png'),
      };
      return networks[network] || null;
    } catch (err) {
      return null;
    }
  };

  const NetworkIcon = ({ network }) => {
    const imgSrc = getNetworkImage(network);

    if (!imgSrc) {
      return (
        <Avatar
          name={network}
          size="12"
          round={true}
          className="right-[-2px] bottom-[-2px] z-10 absolute"
        />
      );
    }

    return (
      <img
        src={imgSrc.default || imgSrc}
        alt={network}
        className="right-[-2px] bottom-[-2px] z-10 absolute rounded-full w-3 h-3"
        onError={(e) => {
          e.target.onerror = null;
          e.target.style.display = 'none';
          const avatarEl = document.createElement('div');
          avatarEl.className = "right-[-2px] bottom-[-2px] z-10 absolute";
          const avatar = document.createElement('div');
          avatarEl.appendChild(avatar);
          e.target.parentNode.appendChild(avatarEl);

          const root = createRoot(avatar);
          root.render(
            <Avatar
              name={network}
              size="12"
              round={true}
            />
          );
        }}
      />
    );
  };

  // const getImageUrl = (type, imageName) => {
  //   try {
  //     return require(`../../../../../assets/images/${type}/${imageName}`);
  //   } catch (err) {
  //     return require(`../../../../../assets/images/issuer/default-icon-day-v3.svg`)
  //   }
  // }

  // Watch for currency changes
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'currency') {
        setCurrentToken(allowedTokens.find((token) => token.symbol.toLowerCase() === (value.currency)));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, allowedTokens]);

  // Handle modal open/close
  const toggleModal = async (e) => {
    if (readOnly) return;
    if (e) e.preventDefault();

    if (!isOpenModal) {
      setIsOpenModal(true);
      setSearchTerm("");
      setFilteredTokens(allowedTokens);

      if (!initialLoadComplete) {
        setIsLoading(true)
        // Simulate initial load delay
        await new Promise(resolve => setTimeout(resolve, 800));
        setIsLoading(false);
        setInitialLoadComplete(true);
      }
    } else {
      setIsOpenModal(false);
      setSearchTerm("");
      setFilteredTokens([]);
    }
  };

  // Handle search with loading state
  useEffect(() => {
    const searchTokens = async () => {
      if (!isOpenModal) return;

      if (searchTerm.length > 0) {
        setIsLoading(true)
        await new Promise(resolve => setTimeout(resolve, 400));
      }

      const filtered = allowedTokens.filter((token) =>
        token.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        token.symbol.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredTokens(filtered);
      setIsLoading(false);
    };

    const debounceTimer = setTimeout(searchTokens, 300);
    return () => clearTimeout(debounceTimer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, isOpenModal]);

  // Handle ESC key
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsOpenModal(false);
      }
    };

    if (isOpenModal) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", handleKeyDown);
      // Add focus
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpenModal]);

  const handleTokenSelect = (token) => {
    setCurrentToken(token);
    setValue('currency', token.symbol.toLocaleLowerCase());
    toggleModal();
  };

  console.log('filteredTokens', filteredTokens)

  // Check if image is a placeholder
  const isPlaceholderImage = (imageName) => {
    return imageName === 'default-icon-day-v3.svg';
  };

  // Render token image or avatar
  const renderTokenImage = (token) => {
    if (!token) return null;

    if (isPlaceholderImage(token.image)) {
      return (
        <Avatar
          name={token.name || token.symbol}
          size="24"
          round={true}
          className="w-6 h-6"
        />
      );
    } else {
      return (
        <img
          // src={require(`../../../../../assets/images/tokenSymbol/${token.image}`)}
          src={token.image}
          alt={token.name}
          className="rounded-full w-6 h-6"
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = 'none';
            const avatarEl = document.createElement('div');
            e.target.parentNode.appendChild(avatarEl);

            const root = createRoot(avatarEl);
            root.render(
              <Avatar
                name={token.name || token.symbol}
                size="24"
                round={true}
                className="w-6 h-6"
              />
            );
          }}
        />
      );
    }
  };

  return (
    <>
      <div
        className="inline-flex relative justify-start items-center gap-1 bg-black-dark hover:opacity-90 px-4 py-2 rounded-full overflow-hidden font-sans text-white transition-all duration-[0.2s] backface-visible-hidden cursor-pointer"
        onClick={toggleModal}
      >
        {currentToken && (
          <>
            <span className="relative">
              {renderTokenImage(currentToken)}
              <NetworkIcon network={currentToken.network} />
            </span>
            {currentToken.symbol}
          </>
        )}

        {!currentToken && (
          <div>
            Select a token
          </div>
        )}
        <Icons nameIcon="chevron-down" className="w-4 h-4" />
      </div>

      <AnimatePresence>
        {isOpenModal && (
          <motion.div
            className="z-[52] fixed inset-0 p-6 max-767:p-0 overflow-auto scroll-smooth modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div
              className="fixed inset-0 bg-black-light opacity-75 modal__overlay"
              onClick={toggleModal}
            />

            <motion.div
              className="top-1/2 z-10 relative bg-black-dark shadow-[transparent_0_0_0_0,transparent_0_0_0_0,#1e2025_0_0_0_1px_inset,#00000054_0_32px_64px_-16px] m-auto rounded-3xl w-full max-w-[380px] modal__wrapper"
              initial={{ opacity: 0, y: "calc(-50% + 16px)" }}
              animate={{ opacity: 1, y: "-50%" }}
              exit={{ opacity: 0, y: "calc(-50% + 16px)" }}
              transition={{ duration: 0.2 }}
            >
              <button
                className="inline-flex top-5 right-6 z-[2] absolute justify-center items-center bg-[#0000] shadow-[inset_0_0_0_2px_#fff] hover:shadow-[inset_0_0_0_2px_#6F737E] rounded-[50%] w-8 h-8 text-white hover:text-[#6F737E] transition-all duration-[0.2s] cursor-pointer"
                onClick={toggleModal}
              >
                <Icons nameIcon="close" className="w-4 h-4" />
              </button>

              {/* Modal header */}
              <div className="relative flex items-center gap-3 pt-6 pr-[62px] pb-4 pl-6">
                <div className="block m-0 pb-4 w-full font-sans text-white text-lg text-left break-words leading-6">
                  Select a token
                </div>
              </div>

              {/* Modal content */}
              <div className="m-0 px-6 max-767:px-6 pt-0 pb-6 border-0 align-baseline">
                <div className="m-0 mb-3 p-0 border-0 align-baseline">
                  <div className="relative m-0 p-0 border-0 text-[#6F737E] align-baseline">
                    <Icons
                      nameIcon="search"
                      className="inline-flex top-2/4 left-3 z-[2] absolute justify-center items-center w-6 h-6 align-middle -translate-y-3 pointer-events-none"
                    />
                    <input
                      type="text"
                      ref={searchInputRef}
                      name="search_currency"
                      className="block bg-[initial] !py-0 !pr-4 !pl-12 border-[#72798a] border-2 border-solid rounded-xl outline-none w-full !h-11 font-sans font-medium text-white !text-sm leading-[1.71429] tracking-[-0.02em] transition-[border-color] duration-[0.2s] appearance-none placeholder-[#6F737E]"
                      placeholder="Type to search network..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>

                <div className="-mx-6 my-0 px-6 py-0 border-0 max-h-80 overflow-y-auto align-baseline scroll-smooth scrollbar-custom">
                  <div className="w-full h-4" />
                  <div className="m-0 p-0 border-0 font-sans font-bold text-[#6F73E] text-[#777e90] text-xs uppercase align-baseline">
                    Tokens
                  </div>

                  {/* Token List */}
                  {isLoading ? (
                    <div className="justify-center items-stretch gap-x-3 gap-y-3 grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto my-0 px-0 pt-3 pb-6 border-0">
                      {[1, 2, 3, 4].map((index) => (
                        <TokenSkeleton key={`token-skeleton-${index}`} />
                      ))}
                    </div>
                  ) : (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                      className="justify-center items-stretch gap-x-3 gap-y-3 grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto my-0 px-0 pt-3 pb-6 border-0"
                    >
                      {filteredTokens.length === 0 ? (
                        <div className="py-2 text-gray-lighter text-sm text-center">
                          No tokens found
                        </div>
                      ) : (
                        filteredTokens.map((token, index) => (
                          <Token
                            key={`token-${index}`}
                            // imgSrc={require(`../../../../../assets/images/tokenSymbol/${token.image}`)}
                            imgSrc={token.image}
                            tokenName={token.name}
                            tokenSymbol={token.symbol}
                            onClick={() => handleTokenSelect(token)}
                            networkIconElement={<NetworkIcon network={token.network} />}
                            balance={token.balance}
                          />
                        ))
                      )}
                    </motion.div>
                  )}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ListTokens;
