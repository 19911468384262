// src/pages/InstantInvestment/index.jsx

import React, { useState, useCallback, useEffect, useRef } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import cn from "classnames"
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_INVESTMENT, UPDATE_INVESTMENT, GET_LAST_INVESTMENT, SINGPASS_CALLBACK, UPDATE_USER, GET_TOKEN_BALANCES_BY_ADDRESS } from '../../queriesAndMutations';

import FormattedNumber from "../../components/shared/FormattedNumber";
import metamaskService from '../../services/metamask'
import PageTitle from "../../components/layouts/InstantInvestment/PageTitle";
// Inner
import {
  StepBuyAndInvest,
  StepShareHolderRegistration,
  StepShareHolderPersonality,
  StepBeforeInsufficientBalance,
  StepDetailBuyAndInvest,
  StepReviewProcessOrder,
} from "./Steps";
import StepTransitionButton from "./Steps/StepTransitionButton";
import issuers from "../../services/dummyDataIssuers";
import { toaster, isProduction } from "../../utils";
import { useMe } from '../../myHooks'

import Web3 from 'web3'
import { ethereum, walletClient } from '../../myHooks/useCoinbaseAuth';
// eslint-disable-next-line import/no-unresolved
import { useAppKit, useAppKitAccount, useAppKitProvider } from '@reown/appkit/react'
import { providers, Contract, BigNumber } from 'ethers'
import { useWeb3Provider, COIN_DECIMALS, getCoinAddress, getUniswapPath, FEE_SWAP, getCollectingFeeAddress, getNetworkName } from '../../myHooks/useWeb3Provider';
import Icons from "../../components/shared/Icons";
import smartContract from "../../constants/smartContract";
import Loader from "../../components/shared/Loader";
import useFeeSwap from "../../myHooks/useFeeSwap";
import SwapSteps from "./SwapSteps";
import { uniswapTokens } from '../../services/uniswapTokens';

const SkeletonLoading = () => (
  <div className='relative flex flex-row flex-wrap justify-start items-center gap-1 [&>span]:w-full'>
    <Skeleton width={100} height={20} className="!bg-gray-custom/25 opacity-25 !rounded-2xl !w-full" />
    <Skeleton width={100} height={20} className="!bg-gray-custom/25 opacity-25 !rounded-2xl !w-full" />
  </div>
)

const InstantInvestment = ({ setPageTitle }) => {
  useEffect(() => {
    setPageTitle('Get Tokens')
  }, [setPageTitle])

  const { t } = useTranslation();
  const [popupEnabled, setPopupEnabled] = useState(true);
  const [checkingPopups, setCheckingPopups] = useState(true);
  const [allChecked, setAllChecked] = useState(false);
  const [step, setStep] = useState("buy-and-invest");
  const [buyValue, setBuyValue] = useState(100);
  const [investValue, setInvestValue] = useState(100);
  const [isOpenSingPass, setIsOpenSingPass] = useState(false);
  const [prefillData, setPrefillData] = useState(null);
  const [currentInvestment, setCurrentInvestment] = useState(null);
  const [isInvestValueValid, setIsInvestValueValid] = useState(true);
  const [buyDetails, setBuyDetails] = useState({});

  const urlParams = new URLSearchParams(window.location.search);

  const issuerId = urlParams.get('issuer') || localStorage.getItem('issuer')
  const defaultIssuer = issuers.find((issuer) => issuer.id === +issuerId);
  const [currentIssuer, setCurrentIssuer] = useState(defaultIssuer || issuers[0]);
  const code = urlParams.get('code');
  const singpass = urlParams.get('singpass');
  const { data: { me: user } = {} } = useMe()
  const [tokenBalance, setTokenBalance] = useState(0)
  const haveKyc = useRef(false)
  const { walletProvider } = useAppKitProvider('eip155')
  const { address: wcAddress, isConnected: isWcConnected } = useAppKitAccount()
  const { open: openAppKitModal } = useAppKit()

  const { provider } = useWeb3Provider(currentIssuer.network, currentIssuer.id);
  const formMethods = useFormContext()
  const [singpassCallback] = useMutation(SINGPASS_CALLBACK);
  const [updateUser] = useMutation(UPDATE_USER)

  const { getFeeTier } = useFeeSwap(provider, currentIssuer.network);

  const [isDataReady, setIsDataReady] = useState(false);
  const [fetchingDataFailed, setFetchingDataFailed] = useState(false);

  const [fetchingRateAndPrice, setFetchingRateAndPrice] = useState(false);
  const [fetchingRateAndPriceFailed, setFetchingRateAndPriceFailed] = useState(false);

  const [numberFetchFailed, setNumberFetchFailed] = useState(false);
  const [rate, setRate] = useState(1);
  const [isFetchingSingpass, setIsFetchingSingpass] = useState(false);
  const tokenBalancesRef = useRef([]);
  const [tokenBalances, setTokenBalances] = useState([]);
  const [getTokenBalancesByAddress] = useLazyQuery(GET_TOKEN_BALANCES_BY_ADDRESS);
  const delayDebounceInvestChange = useRef(null);

  // Add new state for active tab
  const [activeTab, setActiveTab] = useState('invest'); // 'invest' or 'swap'
  const [predefinedSwapBuy, setPredefinedSwapBuy] = useState(null);

  const ButtonContent = ({ step, isInvestValueValid, fetchingRateAndPrice }) => (
    <AnimatePresence mode="wait">
      <motion.span
        key={`${step}-${isInvestValueValid}-${fetchingRateAndPrice}`}
        initial={{ opacity: 0, y: 4 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -4 }}
        transition={{ duration: 0.2 }}
        className="flex items-center gap-2"
      >
        {fetchingRateAndPrice ? (
          <>
            <Loader size="default" className="top-[4px] relative mr-1 !w-3 !h-3" />
            {t('investment.button.fetchingQuote', 'Fetching quote...')}
          </>
        ) : (
          step === "detail-buy-invest"
            ? "Reviewing the investment"
            : !isInvestValueValid
              ? "Insufficient balance"
              : "Continue"
        )}
      </motion.span>
    </AnimatePresence>
  );

  const setFormValues = (data) => {
    formMethods.setValue('status', 'PENDING');
    formMethods.setValue('first_name', data.first_name);
    formMethods.setValue('last_name', data.last_name);
    formMethods.setValue('birth_date', data.birth_date);
    formMethods.setValue('place_of_birth', data.place_of_birth);
    formMethods.setValue('nationality', data.nationality);
    formMethods.setValue('residential_address', data.residential_address);
    formMethods.setValue('city', data.city);
    formMethods.setValue('postal_code', data.postal_code);
    formMethods.setValue('country_of_residence', data.country_of_residence);
  }

  const defaultNextStepMap = {
    "buy-and-invest": "sharedholder-registration",
    "sharedholder-registration": "sharedholder-personal-info",
    "sharedholder-personal-info": "before-inssuficient-balance",
    "before-inssuficient-balance": "detail-buy-invest",
    "detail-buy-invest": "review-process-investment",
  }

  const [nextStepMap, setNextStepMap] = useState(defaultNextStepMap);

  const defaultPrevStepMap = {
    "sharedholder-registration": "buy-and-invest",
    "sharedholder-personal-info": "sharedholder-registration",
    "before-inssuficient-balance": "sharedholder-personal-info",
    "detail-buy-invest": "before-inssuficient-balance",
  }

  const [prevStepMap, setPrevStepMap] = useState(defaultPrevStepMap);

  const handleNextStep = () => {
    if (nextStepMap[step]) {
      setStep(nextStepMap[step]);
    }
  };

  const handlePrevStep = () => {
    if (prevStepMap[step]) {
      setStep(prevStepMap[step]);
    }
  };

  const handleInvestChange = (value) => {
    setInvestValue(+value);
    formMethods.setValue('token_amount', +value);

    // Add validation
    setIsInvestValueValid(+value > 0);
  };

  // Add checkbox change handler
  const handleCheckboxChange = (checked) => {
    setAllChecked(checked);
  };

  console.log('tokenBalance', tokenBalance);

  useEffect(() => {
    const handleSingpassCallback = async (receivedQueryParams) => {
      try {
        setIsFetchingSingpass(true);
          const { data } = await singpassCallback({
            variables: {
            input: {
              receivedQueryParams,
              nonce: localStorage.getItem('singpass_nonce'),
            }
          }
        });

        const { mappingData } = data.singpassCallback;

        console.log('mappingData', mappingData);

        if (mappingData) {
          setFormValues(mappingData);
          setPrefillData(mappingData);
        }

        if (localStorage.getItem('investmentData')) {
          const investmentData = JSON.parse(localStorage.getItem('investmentData'));
          console.log('investmentData', investmentData);
          handleInvestChange(investmentData.token_amount);
          formMethods.setValue('token_amount', investmentData.token_amount);
          formMethods.setValue('token_name', investmentData.token_name);
          formMethods.setValue('currency', investmentData.currency);
          setCurrentIssuer(issuers.find((issuer) => issuer.coin_name === investmentData.token_name));
          localStorage.removeItem('investmentData');
        }

        if (mappingData) {
          handleNextStep();
        }
      } catch (error) {
        console.error('Error fetching singpass callback:', error);
        toaster.error('Error fetching singpass callback');
      } finally {
        setIsFetchingSingpass(false);
      }
    }

    if (singpass) {
      // get url query params except singpass
      const receivedQueryParams = {};
      urlParams.forEach((value, key) => {
        if (key !== 'singpass') {
          receivedQueryParams[key] = value;
        }
      });

      console.log('receivedQueryParams', receivedQueryParams);

      // window.location.replace('/instant-investment');

      handleSingpassCallback(receivedQueryParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setIssuer = (issuer) => {
    if (issuer.network !== currentIssuer.network) {
      formMethods.setValue('currency', '');
      setIsInvestValueValid(false);
    }
    setCurrentIssuer(issuer);
    formMethods.setValue('token_name', issuer.coin_name);
  }

  console.log('getValues', formMethods.getValues())

  const [createInvestment, { loading }] = useMutation(CREATE_INVESTMENT);
  const [updateInvestment] = useMutation(UPDATE_INVESTMENT);
  const [getLastInvestment] = useLazyQuery(GET_LAST_INVESTMENT);

  const updateStepMap = useCallback((tokenBalance, tokenAmount) => {
    console.log('haveKyc', haveKyc);

    if (tokenBalance >= tokenAmount && haveKyc.current) {
      setNextStepMap({
        "buy-and-invest": "detail-buy-invest",
        "detail-buy-invest": "review-process-investment",
      })

      setPrevStepMap({
        "detail-buy-invest": "buy-and-invest",
        "review-process-investment": "detail-buy-invest",
      })
    } else if (tokenBalance < tokenAmount && haveKyc.current) {
      setNextStepMap({
        "buy-and-invest": "before-inssuficient-balance",
        "before-inssuficient-balance": "detail-buy-invest",
        "detail-buy-invest": "review-process-investment",
      })

      setPrevStepMap({
        "before-inssuficient-balance": "buy-and-invest",
        "detail-buy-invest": "before-inssuficient-balance",
        "review-process-investment": "detail-buy-invest",
      })
    } else if (tokenBalance >= tokenAmount && !haveKyc.current) {
      setNextStepMap({
        "buy-and-invest": "sharedholder-registration",
        "sharedholder-registration": "sharedholder-personal-info",
        "sharedholder-personal-info": "detail-buy-invest",
        "detail-buy-invest": "review-process-investment",
      })

      setPrevStepMap({
        "sharedholder-registration": "buy-and-invest",
        "sharedholder-personal-info": "sharedholder-registration",
        "detail-buy-invest": "sharedholder-personal-info",
      })
    } else {
      setNextStepMap(defaultNextStepMap);
      setPrevStepMap(defaultPrevStepMap);
    }
  }, [haveKyc, defaultNextStepMap, defaultPrevStepMap])

  const checkTokenBalance = useCallback(async (currency) => {
    if (!provider || !currency) {
      console.log('no provider')
      return 0
    }
    setIsDataReady(false);

    console.log('checkTokenBalance')
    let coinAddress

    let selectedCurrency = currency

    if (currentIssuer && ['DAKS', 'DEXA'].includes(currentIssuer.coin_name)) {
      console.log('mainnet-only')
      coinAddress = getCoinAddress(selectedCurrency, 'mainnet-only', tokenBalancesRef.current)
    } else {
      coinAddress = getCoinAddress(selectedCurrency, currentIssuer.network, tokenBalancesRef.current)

      if (!isProduction && currentIssuer.network === 'base') {
        selectedCurrency = 'usdc'
      }
    }

    const abi = [
      {
        constant: true,
        inputs: [{ name: "_owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{ name: "", type: "uint8" }],
        payable: false,
        type: "function",
      },
    ];

    const web3 = new Web3(provider);

    console.log('selectedCurrency', selectedCurrency)

    if (['eth', 'pol'].includes(selectedCurrency)) {
      const balance = await web3.eth.getBalance(user.wallet_address)

      console.log('balance', balance)

      const balanceValue = +Number(balance) / (10 ** COIN_DECIMALS[selectedCurrency])

      console.log('balanceValue', balanceValue)

      setTokenBalance(+balanceValue.toFixed(6))

      updateStepMap(balanceValue, formMethods.getValues('amount'));

      await new Promise(resolve => setTimeout(resolve, 2000));

      setIsDataReady(true);
      console.log('isDataReady set true 4')

      return +balanceValue.toFixed(6);
    }

    const contract = new web3.eth.Contract(abi, coinAddress)

    try {
      const balance = await contract.methods.balanceOf(user.wallet_address).call()

      console.log('balance', balance)

      const tokenDecimals = COIN_DECIMALS[selectedCurrency] || +Number(await contract.methods.decimals().call());

      console.log('tokenDecimals', tokenDecimals)

      const balanceValue = +Number(balance) / (10 ** tokenDecimals)

      console.log('selectedCurrency', selectedCurrency)
      console.log('balanceValue', balanceValue)
      console.log('tokenDecimals', tokenDecimals)
      setTokenBalance(balanceValue);

      updateStepMap(balanceValue, formMethods.getValues('amount'));

      await new Promise(resolve => setTimeout(resolve, 2000));

      setIsDataReady(true);
      console.log('isDataReady set true 4')

      return balanceValue;
    } catch (error) {
      console.log(error);
      setFetchingDataFailed(true);
      setTokenBalance(0);
      updateStepMap(0, formMethods.getValues('amount'));

      await new Promise(resolve => setTimeout(resolve, 2000));
      setIsDataReady(true);

      return 0;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, formMethods, isWcConnected, provider, currentIssuer])

  const calculateFees = useCallback(async (currency, tokenAmount, balance) => {
    console.log('calculateFees')
    if (!provider || !currency) return;

    setIsDataReady(false);

    const web3 = new Web3(provider);

    const fee = FEE_SWAP;
    const networkFeeAmount = 0; // 0.25%

    console.log('calculateFees currentIssuer.currencies', currentIssuer.currencies)
    console.log('calculateFees currency', currency)
    console.log('calculateFees tokenAmount', tokenAmount)

    if (currentIssuer.currencies.includes(currency)) {
      // const tokenAmount = formMethods.getValues('token_amount');
      const totalAmount = tokenAmount / (1 - fee);
      const uiFee = totalAmount - tokenAmount;

      setBuyDetails({
        currency,
        totalAmount,
        feePercentage: fee,
        uiFee,
        networkFeeAmount: 0,
      })

      console.log('calculateFees buyDetails', {
        currency,
        totalAmount,
        feePercentage: fee,
        uiFee,
        networkFeeAmount: 0,
      })

      setBuyValue(+totalAmount.toFixed(6));
      formMethods.setValue('amount', +totalAmount);

      console.log('calculateFees totalAmount', totalAmount)
      console.log('calculateFees tokenBalance', tokenBalance)

      const balance = await checkTokenBalance(currency);

      updateStepMap(balance || tokenBalance, totalAmount);

      setIsInvestValueValid(+totalAmount > 0);

      await new Promise(resolve => setTimeout(resolve, 2000));

      setIsDataReady(true);
      return
    }

    const ethersProvider = new providers.Web3Provider(provider);
    await ethersProvider.send('eth_requestAccounts', []); // Request account access
    const signer = ethersProvider.getSigner();

    const collectingFeeAddress = getCollectingFeeAddress(currentIssuer.network);

    const feeContract = new web3.eth.Contract(smartContract.feeContractAbi, collectingFeeAddress);

    console.log('calculateFees tokenBalances', tokenBalancesRef.current);

    const tokenInAddress = getCoinAddress(currency, currentIssuer.network, tokenBalancesRef.current);
    const tokenOutAddress = getCoinAddress('usdc', currentIssuer.network, tokenBalancesRef.current);

    console.log('calculateFees tokenInAddress', tokenInAddress);
    console.log('calculateFees tokenOutAddress', tokenOutAddress);
    console.log('calculateFees signer', signer);
    console.log('calculateFees tokenAmount', tokenAmount);

    const feeTier = await getFeeTier(tokenInAddress, tokenOutAddress, signer, currentIssuer.network);

    console.log('calculateFees feeTier', feeTier);

    if (!feeTier) {
      setBuyDetails({
        currency,
        totalAmount: 0,
        feePercentage: 0,
        uiFee: 0,
        networkFeeAmount: 0,
        swapFeePercentage: 0,
        swapFee: 0,
      })

      toaster.error('No liquidity available for this token pair.');

      setIsDataReady(true);

      setIsInvestValueValid(false);
      return;
    }

    // const data = await feeContract.methods
    //   .getSwapAndBuyQuoteExactInput(tokenInAddress, BigInt(+buyValue * 10 ** COIN_DECIMALS[currency]).toString(), tokenOutAddress, feeTier)
    //   .call();

    const amountOut = BigInt(+tokenAmount * 10 ** COIN_DECIMALS['usdc']).toString()

    console.log('calculateFees amountOut', amountOut)
    const { amountIn, gasEstimate } = await feeContract.methods
      .getSwapAndBuyQuoteExactOutput(tokenInAddress, amountOut, tokenOutAddress, feeTier, '0x')
      .call();

    console.log('calculateFees amountIn', amountIn)
    console.log('calculateFees gasEstimate', gasEstimate)

    let tokenInDecimals = COIN_DECIMALS[currency];

    if (!tokenInDecimals) {
      const tokenContract = await new web3.eth.Contract(smartContract.token, tokenInAddress);

      tokenInDecimals = await tokenContract.methods.decimals().call();

      tokenInDecimals = +Number(tokenInDecimals);

      console.log('tokenInDecimals', tokenInDecimals)
    }

    const amountInValue = +(amountIn.toString()) / (10 ** tokenInDecimals);
    const totalAmount = amountInValue / (1 - fee);
    const uiFee = totalAmount - tokenAmount;

    setBuyValue(+totalAmount.toFixed(8));
    formMethods.setValue('amount', +totalAmount.toFixed(8));

    console.log('calculateFees totalAmount', totalAmount)
    console.log('calculateFees tokenBalance', balance || tokenBalance)

    setIsInvestValueValid(+totalAmount <= (balance || tokenBalance));

    setBuyDetails({
      currency,
      totalAmount,
      feePercentage: fee,
      uiFee,
      networkFeeAmount: 0,
      swapFeePercentage: +feeTier / 1000000,
      swapFee: totalAmount * (+feeTier / 1000000),
    })

    setBuyValue(totalAmount);
    formMethods.setValue('amount', totalAmount);

    updateStepMap(balance || tokenBalance, totalAmount);

    setIsDataReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyValue, currentIssuer, provider, tokenBalance]);

  const fetchRateAndPriceAktionariat = async (amount, currency) => {
    try {
      if (fetchingRateAndPrice || !currency) {
        return
      }
      setFetchingRateAndPrice(true);
      await new Promise(resolve => setTimeout(resolve, 2000));

      console.log('fetchRate')
      const brokerbotAddress = currentIssuer.brokerbotAddress;
      const ethersProvider = new providers.Web3Provider(provider)

      const brokerbotContract = new Contract(brokerbotAddress, smartContract.brokerbotABI, ethersProvider);
      console.log('investValue', amount)
      // Now using investValue directly instead of calculating from buyValue
      const numberOfShares = BigNumber.from(Math.floor(amount).toLocaleString());

      const rateContract = await brokerbotContract.getPrice();
      const rateConverted = +(rateContract.toString()) / 10 ** COIN_DECIMALS.zchf

      const priceInBase = await brokerbotContract.getBuyPrice(numberOfShares);

      const paymentHubAddress = currentIssuer.paymentHub;

      const paymentHubContract = new Contract(paymentHubAddress, smartContract.paymenthubABI, ethersProvider);

      console.log('priceInBase', priceInBase)

      const currentCurrency = currency || formMethods.getValues('currency');

      if (currentCurrency === 'zchf') {
        const zchfValue = priceInBase / 10 ** COIN_DECIMALS.zchf;
        console.log('priceInBase zchf', zchfValue)
        formMethods.setValue('amount', zchfValue);
        setBuyValue(zchfValue);
      } else {
        const path = getUniswapPath();

        console.log('path', path)

        const priceInERC20 = await paymentHubContract.getPriceInERC20(priceInBase.toString(), path);

        console.log('priceInERC20', priceInERC20)
        const tokenValue = priceInERC20 / 10 ** COIN_DECIMALS.usdc;

        // formMethods.setValue('amount', priceInERC20 / 10 ** COIN_DECIMALS.usdc);
        formMethods.setValue('amount', tokenValue);
        console.log('priceInERC20 formatted', priceInERC20 / 10 ** COIN_DECIMALS.usdc)
        setBuyValue(tokenValue);
      }

      console.log('rateContract', rateContract.toString());

      setRate(rateConverted);
      setFetchingRateAndPrice(false);
      setIsDataReady(true);
    } catch (error) {
      console.log(error);
      setFetchingRateAndPriceFailed(true);
      setFetchingRateAndPrice(false);
    }
  };

  // Add popup check function
  const checkPopupEnabled = useCallback(async () => {
    try {
      setCheckingPopups(true);
      // Try to open a small popup window
      const popup = window.open('about:blank', '_blank', 'width=1,height=1');

      if (!popup || popup.closed || typeof popup.closed === 'undefined') {
        setPopupEnabled(false);
      } else {
        setPopupEnabled(true);
        popup.close();
      }
    } catch (error) {
      setPopupEnabled(false);
    } finally {
      setCheckingPopups(false);
    }
  }, []);

  useEffect(() => {
    checkPopupEnabled();
  }, [checkPopupEnabled]);

  useEffect(() => {
    if (currentIssuer && ['DAKS', 'DEXA'].includes(currentIssuer.coin_name)) {
      setTimeout(() => {
        fetchRateAndPriceAktionariat(formMethods.getValues('token_amount'));
      }, 2000);
    } else {
      handleInvestChange(formMethods.getValues('token_amount'));
      // setIsDataReady(false);
      // setTimeout(() => {
      //   checkTokenBalance(formMethods.getValues('currency'));
      // }, 2000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, currentIssuer]);

  useEffect(() => {
    const fetchInvestment = async (code) => {
      setIsDataReady(false);
      const { data: investment } = await getLastInvestment();

      console.log('investment', investment);

      const investmentData = investment.getLastInvestment;

      if (!investmentData) {
        console.log('isDataReady set true 1')
        setIsDataReady(true);
        return
      }

      setFormValues(investmentData);

      if (code && !singpass) {
        handleInvestChange(investmentData.token_amount);
        formMethods.setValue('token_amount', investmentData.token_amount);
        formMethods.setValue('token_name', investmentData.token_name);
        formMethods.setValue('currency', investmentData.currency);
        setCurrentInvestment(investment.getLastInvestment);
        setCurrentIssuer(issuers.find((issuer) => issuer.coin_name === investmentData.token_name));
        setStep('before-inssuficient-balance');
        console.log('isDataReady set true 2')
        setIsDataReady(true);

        return;
      }

      if (investmentData.first_name && investmentData.last_name &&
        investmentData.birth_date && investmentData.place_of_birth &&
        investmentData.nationality && investmentData.residential_address &&
        investmentData.city && investmentData.postal_code &&
        investmentData.country_of_residence
      ) {
        haveKyc.current = true;
      }

      await checkTokenBalance(formMethods.getValues('currency'));
      console.log('isDataReady set true 3')
      setIsDataReady(true);
    }

    if (provider) {
      fetchInvestment(code);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, code, getLastInvestment, singpass]);

  useEffect(() => {
    if (!provider) {
      return () => {}
    }

    const subscription = formMethods.watch(async (value, { name }) => {
      if (name === 'currency') {
        console.log('currency changed', value.currency)

        clearTimeout(delayDebounceInvestChange.current);

        delayDebounceInvestChange.current = setTimeout(async () => {
          console.log('token_amount changed 2', value.token_amount)
          if (currentIssuer && ['DAKS', 'DEXA'].includes(currentIssuer.coin_name)) {
            fetchRateAndPriceAktionariat(value.token_amount, value.currency);
          } else {
            calculateFees(value.currency, value.token_amount);
          }
        }, 1000);
      } else if (name === 'amount') {
        updateStepMap(tokenBalance, value.amount);
      } else if (name === 'token_amount') {
        console.log('token_amount changed', value.token_amount)

        clearTimeout(delayDebounceInvestChange.current);

        delayDebounceInvestChange.current = setTimeout(async () => {
          console.log('token_amount changed 2', value.token_amount)
          if (currentIssuer && ['DAKS', 'DEXA'].includes(currentIssuer.coin_name)) {
            fetchRateAndPriceAktionariat(value.token_amount, value.currency);
          } else {
            calculateFees(value.currency, value.token_amount);
          }
        }, 1000);
      }
    });

    return () => subscription.unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods, checkTokenBalance, tokenBalance, updateStepMap, provider, updateUser]);

  // set form currency by user currency
  useEffect(() => {
    const fetchTokenBalances = async () => {
      if (user && currentIssuer) {
        console.log('fetchTokenBalances')
        const network = currentIssuer.network === 'mainnet-only' ? 'mainnet' : currentIssuer.network;

        const currentChainIndex = (+metamaskService.getChainIdByName(network)).toString();
        const chainIndexes = [currentChainIndex];

        const { data: tokenBalancesData } = await getTokenBalancesByAddress({
          variables: { address: user.wallet_address, chainIndexes }
        });

        // Get tokens from tokenBalancesData with price > 0
        const balanceTokens = tokenBalancesData.getTokenBalancesByAddress.tokenAssets
          .filter(balance => balance.tokenPrice > 0)
          .map(balance => {
            // Find matching token in uniswapTokens instead of dummyDataToken
            const token = uniswapTokens.find(token =>
              token.symbol.toLowerCase() === balance.symbol.toLowerCase() &&
              token.chainId.toString() === currentChainIndex
            );
            return {
              ...balance,
              name: token ? token.name : balance.symbol.toLowerCase(),
              image: token ? token.logoURI : 'default-icon-day-v3.svg',
              network: getNetworkName(balance.chainIndex).toLowerCase()
            };
          });

        // Get tokens from uniswapTokens for current network
        const networkTokens = uniswapTokens
          .filter(token => token.chainId.toString() === currentChainIndex)
          .map(token => {
            // Try to find balance data for this token
            const balanceData = balanceTokens.find(b =>
              b.symbol.toLowerCase() === token.symbol.toLowerCase()
            );

            return {
              chainIndex: chainIndexes[0],
              symbol: token.symbol,
              image: token.logoURI,
              name: token.name,
              decimals: token.decimals,
              // Use balance data if found, otherwise set defaults
              balance: balanceData ? balanceData.balance : '0',
              tokenPrice: balanceData ? balanceData.tokenPrice : '0',
              tokenAddress: token.address,
              // Include other balance properties with defaults
              tokenType: balanceData ? balanceData.tokenType : '1',
              isRiskToken: balanceData ? balanceData.isRiskToken : false,
              transferAmount: balanceData ? balanceData.transferAmount : '0',
              availableAmount: balanceData ? balanceData.availableAmount : '0',
              rawBalance: balanceData ? balanceData.rawBalance : '',
              address: balanceData ? balanceData.address : user.wallet_address,
              network: getNetworkName(balanceData ? balanceData.chainIndex : currentChainIndex).toLowerCase()
            };
          });

        // Combine both arrays, removing duplicates by symbol
        const combinedTokens = [...networkTokens];

        // balanceTokens.forEach(balanceToken => {
        //   const exists = combinedTokens.some(token =>
        //     token.symbol.toLowerCase() === balanceToken.symbol.toLowerCase()
        //   );
        //   if (!exists) {
        //     combinedTokens.push(balanceToken);
        //   }
        // });

        console.log('combinedTokens', combinedTokens)

        const sortedTokens = combinedTokens.sort((a, b) => b.balance - a.balance);

        tokenBalancesRef.current = sortedTokens;
        setTokenBalances(sortedTokens);
      }
    }

    fetchTokenBalances();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentIssuer]);

  useEffect(() => {
    if (fetchingDataFailed && numberFetchFailed < 3) {
      setFetchingDataFailed(false);
      setNumberFetchFailed(prev => prev + 1);

      setTimeout(() => {
        checkTokenBalance(formMethods.getValues('currency'));
      }, 2000);
    }

    if (fetchingRateAndPriceFailed && numberFetchFailed < 3) {
      setFetchingRateAndPriceFailed(false);
      setNumberFetchFailed(prev => prev + 1);
      toaster.info('Refetching data...');

      setTimeout(() => {
        fetchRateAndPriceAktionariat(formMethods.getValues('token_amount'), formMethods.getValues('currency'));
      }, 2000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingDataFailed, fetchingRateAndPriceFailed, numberFetchFailed]);

  const onSubmit = async (data) => {
    console.log('data', data)
    console.log('step', step)

    let investment;

    console.log('nextStepMap[step]', nextStepMap[step])

    if (step === 'detail-buy-invest') {
      checkTokenBalance(data.currency)
    }

    switch (nextStepMap[step]) {
      case 'detail-buy-invest':
        try {
          const formData = {
            amount: buyValue,
            token_amount: investValue,
            currency: data.currency,
            token_name: data.token_name || 'ENC',
            status: 'PENDING',
            first_name: data.first_name,
            last_name: data.last_name,
            birth_date: data.birth_date || '15 August 1989',
            place_of_birth: data.place_of_birth,
            nationality: data.nationality,
            residential_address: data.residential_address,
            residential_address_two: data.residential_address_two,
            city: data.city,
            postal_code: data.postal_code,
            country_of_residence: data.country_of_residence
          };

          if (currentInvestment) {
            const { data: investmentData } = await updateInvestment({
              variables: {
                id: +currentInvestment.id,
                input: formData
              }
            });

            investment = investmentData.updateInvestment;
          } else {
            const { data: investmentData } = await createInvestment({
              variables: {
                input: {
                  ...formData,
                  whitelist_contract_address: currentIssuer.whitelistAddress,
                  network: currentIssuer.network
                }
              }
            });

            investment = investmentData.createInvestment;
          }

          setCurrentInvestment(investment);

          if (step === 'buy-and-invest' || step === 'sharedholder-personal-info') {
            handleNextStep();
          }
        } catch (error) {
          console.error('Investment creation failed:', error);

          toaster.error(error.message);
        }
        break;
      case 'review-process-investment':
        handleNextStep();

        break;
      default:
        handleNextStep();
        break;
    }

    return investment;
  };

  if (isFetchingSingpass) {
    return (
      <div className="mx-auto w-full max-w-[382px]">
        <SkeletonLoading />
      </div>
    )
  }

  // Add this before the return statement
  if (checkingPopups) {
    return (
      <div className="mx-auto w-full max-w-[382px]">
        <SkeletonLoading />
      </div>
    );
  }

  // Add popup warning message when needed
  const renderPopupWarning = () => {
    if (!popupEnabled) {
      return (
        <div className="mx-auto w-full max-w-[460px]">
          <div className="before:top-0 before:left-0 before:absolute relative bg-red-500/20 before:bg-[100%] mb-4 p-4 py-6 before:border-[2px] before:border-red-500 before:border-solid rounded-2xl before:rounded-[inherit] before:outline-none before:w-full before:h-full font-sans font-medium text-red-500 text-center before:content-[''] leading-5 tracking-[-0.02em] before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none">
            Popups are currently blocked. To proceed with investment: <br />
            <span className="block">1. Enable popups for this website in your browser settings</span>
            <span className="block">2. Refresh this page after enabling popups</span>
          </div>
        </div>
      );
    }
    return null;
  };

  // TabButton component
  const TabButton = ({ isActive, onClick, children }) => (
    <button
      onClick={onClick}
      className={cn(
        "inline-flex justify-center items-center text-xs font-sans tracking-[-0.02em] transition-colors duration-[0.2s] font-semibold px-4 h-8 relative z-10",
        isActive ? 'text-white' : 'text-gray-custom hover:opacity-90'
      )}
    >
      {children}
    </button>
  );

  // Add before the return statement
  const renderTabsWithBack = () => {
    // Check for active / deactivate button back for each step
    const showBackButton = [
      "sharedholder-registration",
      "sharedholder-personal-info",
      "before-inssuficient-balance",
    ].includes(step);

    return (
      <div className="flex justify-between items-center mb-2 w-full">
        <div className="inline-flex relative justify-start items-center">
          <motion.div
            className="z-0 absolute bg-black-light rounded-[40px] h-8"
            initial={false}
            animate={{
              x: activeTab === 'invest' ? 0 : '100%',
              width: '50%'
            }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30
            }}
          />

          <TabButton
            isActive={activeTab === 'invest'}
            onClick={() => setActiveTab('invest')}
          >
            {t('investment.tabs.invest', 'Invest')}
          </TabButton>

          <TabButton
            isActive={activeTab === 'swap'}
            onClick={() => setActiveTab('swap')}
          >
            {t('investment.tabs.swap', 'Swap')}
          </TabButton>
        </div>

        {showBackButton && (
          <button
            onClick={handlePrevStep}
            className="inline-flex z-10 relative justify-center items-center bg-black-light hover:bg-gray-custom hover:opacity-90 px-4 rounded-[40px] h-8 font-sans font-semibold text-gray-custom hover:text-white text-xs tracking-[-0.02em] transition-colors duration-[0.2s]"
          >
            <Icons
              nameIcon="chevron-left"
              className="mr-1 w-3 h-3"
            />
            <span className="inline-block">Back</span>
          </button>
        )}
      </div>
    )
  }

  console.log('isDataReady', isDataReady)

  return (
    <>
      {/* Render warning if not-allowed block-popup */}
      {renderPopupWarning()}

      {/* Add tabs with back-button */}
      {renderTabsWithBack()}

      {/* Wrap existing form in conditional rendering */}
      {activeTab === 'invest' ? (
        <>
          <PageTitle
            title={t('investment.title')}
            description={
              step === "sharedholder-registration"
                ? t('investment.description.sharedholder-registration')
                : step === "sharedholder-personal-info"
                ? t('investment.description.sharedholder-personal-info')
                : ""
            }
          />

          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}
            className="w-full"
          >
            {step === "buy-and-invest" && (
              <StepBuyAndInvest
                isLogin={true}
                handleInvestChange={handleInvestChange}
                buyValue={buyValue}
                investValue={investValue}
                setCurrentIssuer={setIssuer}
                currentIssuer={currentIssuer}
                balanceAfterTopup={tokenBalance}
                tokenBalances={tokenBalances}
                setActiveTab={setActiveTab}
                setPredefinedSwapBuy={setPredefinedSwapBuy}
                // fetchingData={!isDataReady}
                // fetchingRateAndPrice={fetchingRateAndPrice}
              />
            )}

            {step === "sharedholder-registration" && (
              <StepShareHolderRegistration
                isLogin={true}
                prefillData={prefillData}
              />
            )}

            {step === "sharedholder-personal-info" && (
              <StepShareHolderPersonality
                isLogin={true}
                prefillData={prefillData}
              />
            )}

            {step === "before-inssuficient-balance" && (
              <StepBeforeInsufficientBalance
                isLogin={true}
                onNext={handleNextStep}
                onSubmit={onSubmit}
                currentInvestment={currentInvestment}
                moneriumCode={code && !singpass ? code : null}
                currentIssuer={currentIssuer}
                tokenBalances={tokenBalances}
              />
            )}

            {step === "detail-buy-invest" && (
              <StepDetailBuyAndInvest
                isLogin={true}
                handleInvestChange={handleInvestChange}
                buyValue={buyValue}
                investValue={investValue}
                setCurrentIssuer={setIssuer}
                currentIssuer={currentIssuer}
                balanceAfterTopup={tokenBalance}
                tokenBalances={tokenBalances}
                onCheckboxChange={handleCheckboxChange}
                buyDetails={buyDetails}
              />
            )}

            {step === "review-process-investment" &&
              <StepReviewProcessOrder
                currentInvestment={currentInvestment}
                currentIssuer={currentIssuer}
                setStep={setStep}
                buyDetails={buyDetails}
                tokenBalances={tokenBalances}
              />
            }

            {step !== "before-inssuficient-balance" && step !== "review-process-investment" && (
              <StepTransitionButton
                onSubmit={formMethods.handleSubmit(onSubmit)}
                disabled={
                  !isDataReady ||
                  fetchingRateAndPrice ||
                  (step === 'detail-buy-invest' && !allChecked) ||
                  !isInvestValueValid ||
                  !popupEnabled
                }
                isLoading={!isDataReady || fetchingRateAndPrice}
              >
                <ButtonContent
                  step={step}
                  isInvestValueValid={isInvestValueValid}
                  fetchingRateAndPrice={fetchingRateAndPrice}
                />
              </StepTransitionButton>
            )}

            {(step === "sharedholder-registration" || step === "sharedholder-personal-info") && prefillData && (
              <button
                type="button"
                onClick={() => {
                  window.location.href = '/instant-investment';
                }}
                className="inline-flex justify-center items-center bg-white hover:bg-[#ffffffab] hover:opacity-90 m-0 mt-3 px-6 !rounded-[40px] outline-none w-full !h-14 overflow-visible font-medium text-[100%] text-black normal-case leading-none transition-colors duration-[0.2s] cursor-pointer"
              >
                Cancel
              </button>
            )}
          </form>
        </>
      ) : (
        <div className="mx-auto w-full max-w-[460px]">
          <SwapSteps predefinedSwapBuy={predefinedSwapBuy} />
        </div>
      )}
    </>
  );
};

export default InstantInvestment;
