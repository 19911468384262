import polygonImg from 'cryptocurrency-icons/svg/color/matic.svg'
import placeholderImage from "../assets/images/issuer/default-icon-day-v3.svg"
import metamask from "../assets/images/metamask.svg"
import walletconnect from "../assets/images/walletconnect.svg"
import coinbase from "../assets/images/coinbase.svg"
import ethImg from "cryptocurrency-icons/svg/color/eth.svg"
import ibanDetail from "../assets/images/IBAN/iban-detail.png"
import ibanMoveAccount from "../assets/images/IBAN/move-other-account.png"
import ibanNumber from "../assets/images/IBAN/iban-number.png"
import EUReImg from "cryptocurrency-icons/svg/color/eur.svg"
import USDCImg from "cryptocurrency-icons/svg/color/usdc.svg"
import approveOne from "../assets/images/approve/approve-1.png"
import approveTwo from "../assets/images/approve/approve-2.png"
import approveThree from "../assets/images/approve/approve-3.png"
import voltOne from "../assets/images/dummyVoltio/step-1.jpeg"
import voltTwo from "../assets/images/dummyVoltio/step-2.jpeg"
import voltThree from "../assets/images/dummyVoltio/step-3.jpeg"
import voltFour from "../assets/images/dummyVoltio/step-4.jpeg"
import voltFive from "../assets/images/dummyVoltio/step-5.jpeg"
import voltSix from "../assets/images/dummyVoltio/step-6.jpeg"
import voltSeven from "../assets/images/dummyVoltio/Step-7.jpeg"
import voltEight from "../assets/images/dummyVoltio/Step-8.jpeg"
import rampOne from "../assets/images/dummyrampnetwork/1.png"
import rampTwo from "../assets/images/dummyrampnetwork/2.png"
import rampThree from "../assets/images/dummyrampnetwork/3.png"
import rampFour from "../assets/images/dummyrampnetwork/4.png"
import singpassLogoFullcolours from "../assets/images/Singpass/singpass_logo_fullcolours.svg"
import singpassLogoWhite from "../assets/images/Singpass/singpass_logo_white.svg"
import investhubCircle from "../assets/images/logo-investhub-small.svg"

export const SINGPASS_ASSETS_IMAGES = {
  fullColours: singpassLogoFullcolours,
  logoWhite: singpassLogoWhite
}

export const INVESTHUB_IMAGES = {
  logoCircle: investhubCircle,
}

export const NETWORK_IMAGES = {
  polygon: polygonImg,
  ethereum: ethImg,
  eure: EUReImg,
  usdc: USDCImg,
  defaultToken: placeholderImage,
}

export const IBAN_IMAGES = {
  ibanDetail,
  ibanMoveAccount,
  ibanNumber,
}

export const SETTING_WALLET_OPTIONS = [
  {
    id: 'metamask',
    name: 'MetaMask',
    icon: metamask,
    isActive: true,
  },
  {
    id: 'walletconnect',
    name: 'WalletConnect',
    icon: walletconnect,
    isActive: false,
  },
  {
    id: 'coinbase',
    name: 'Coinbase',
    icon: coinbase,
    isActive: false,
  },
]

export const APPROVE_IMAGES = {
  processOne: approveOne,
  processTwo: approveTwo,
  processThree: approveThree,
}

export const DUMMY_VOLT_IMAGES = {
  stepOne: voltOne,
  stepTwo: voltTwo,
  stepThree: voltThree,
  stepFour: voltFour,
  stepFive: voltFive,
  stepSix: voltSix,
  stepSeven: voltSeven,
  stepEight: voltEight,
}

export const DUMMY_RAMP_NETWORK_IMAGES = {
  stepOne: rampOne,
  stepTwo: rampTwo,
  stepThree: rampThree,
  stepFour: rampFour,
}
