import React from 'react';
import { motion } from 'framer-motion';
import Avatar from 'react-avatar';
import { createRoot } from 'react-dom/client';

const AssetsTable = ({ assets, getNetworkName }) => {
  const AssetImage = ({ icon, name }) => (
    <div className="flex-shrink-0 rounded-full w-6 h-6">
      {icon && !String(icon).includes('default-icon-day-v3.svg') ? (
        <img
          src={typeof icon === 'string' ? icon : (icon.default || icon)}
          alt={name}
          className="rounded-full w-full h-full object-cover"
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = 'none';
            const avatar = document.createElement('div');
            e.target.parentNode.appendChild(avatar);
            const root = createRoot(avatar);
            root.render(
              <Avatar
                name={name}
                size="24"
                round={true}
                className="w-full h-full object-cover"
              />
            );
          }}
        />
      ) : (
        <Avatar
          name={name}
          size="24"
          round={true}
          className="w-full h-full object-cover"
        />
      )}
    </div>
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="overflow-x-auto assets"
    >
      <table className="w-full">
        <thead>
          <tr className="border-white/10 border-b-2 border-solid font-sans text-left">
            <th className="p-4 w-[250px] text-white/70 text-ellipsis tracking-[-0.02em]">Name</th>
            <th className="p-4 w-[200px] text-white/70 text-ellipsis tracking-[-0.02em]">Balance</th>
            <th className="p-4 w-[200px] text-white/70 text-ellipsis tracking-[-0.02em]">Price</th>
            <th className="p-4 text-white/70 text-ellipsis tracking-[-0.02em]">Allocation</th>
          </tr>
        </thead>
        <tbody>
          {assets.map((asset) => (
            <motion.tr
              key={asset.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className={`font-sans ${!asset.hasPrice ? 'opacity-50' : ''}`}
            >
              <td className="px-4 py-3 text-white/70 text-left align-middle">
                <div className="group flex items-center gap-4 w-full">
                  <AssetImage icon={asset.icon} name={asset.name} />
                  <div className='flex-1'>
                    <div className="font-semibold text-[13px] text-white truncate tracking-[-0.01em]">
                      {asset.name}
                      {asset.wallet_count > 1 && (
                        <span className="ml-2 text-white/50 text-xs">
                          ({asset.wallet_count} wallets)
                        </span>
                      )}
                    </div>
                    <div className="text-[13px] truncate tracking-[-0.02em]">
                      {asset.symbol} {asset.chain_index && `• ${getNetworkName(asset.chain_index)}`}
                    </div>
                  </div>
                </div>
              </td>
              <td className="px-4 py-3 text-white/70 text-left align-middle">
                <motion.div
                  key={asset.balance.amount}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  className="font-semibold text-[13px] text-white truncate tracking-[-0.01em]"
                >
                  {asset.hasPrice ? `$${asset.balance.amount.toFixed(2)}` : 'No price data'}
                </motion.div>
                <div className="text-[13px] truncate tracking-[-0.02em]">{asset.balance.units}</div>
              </td>
              <td className="px-4 py-3 text-white/70 text-left align-middle">
                <motion.div
                  key={asset.price.amount}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  className="font-semibold text-[13px] text-white truncate tracking-[-0.01em]"
                >
                  ${asset.price.amount.toFixed(6)}
                </motion.div>
                {asset.price.change !== 0 && (
                  <div className={`text-[13px] truncate tracking-[-0.02em] ${
                    asset.price.change > 0 ? 'text-green-500' : 'text-red-500'}`
                  }>
                    {asset.price.change > 0 ? '+' : ''}{asset.price.change}%
                  </div>
                )}
              </td>
              <td className="px-4 py-3 min-w-[300px] text-white/70 text-left align-middle">
                <motion.div
                  key={asset.allocation}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  className="flex items-center gap-3"
                >
                  <div className="w-16 font-semibold text-[13px] text-white tracking-[-0.01em]">
                    {asset.allocation.toFixed(2)}%
                  </div>
                  <div className="flex-1 bg-white/10 rounded-full h-2 overflow-hidden">
                    <motion.div
                      initial={{ width: 0 }}
                      animate={{ width: `${asset.allocation}%` }}
                      transition={{ duration: 0.5, ease: "easeOut" }}
                      className="bg-blue-500 rounded-full h-full"
                    />
                  </div>
                </motion.div>
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
    </motion.div>
  );
};

export default AssetsTable;
