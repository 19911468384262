import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import cn from 'classnames'
import ListTokens from "./ListTokens";
import FormattedNumber from "../../../../components/shared/FormattedNumber"
import styles from "./Style.module.scss"

const FieldBuy = ({ isLogin, value, onChange, balanceAfterTopup, readOnly, currentIssuer, tokenBalances }) => {
  const [isFocused, setIsFocused] = useState(false);
  const { register, setValue, getValues, watch } = useFormContext();
  const allowedTokens = tokenBalances || [];
  const [currentToken, setCurrentToken] = useState(allowedTokens.find((token) => token.symbol.toLowerCase() === (getValues('currency'))));

  console.log('fieldBuy tokenBalances', tokenBalances)
  console.log('fieldBuy getValues(currency)', getValues('currency'))
  console.log('fieldBuy currentToken', currentToken)
  console.log('fieldBuy tokenBalance', balanceAfterTopup)

  // Format displayed value
  const displayValue = typeof value === 'number' ? value : parseFloat(value || 0);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'currency') {
        console.log('fieldBuy value.currency', value.currency)
        console.log('fieldBuy allowedTokens', allowedTokens)
        setCurrentToken(allowedTokens.find((token) => token.symbol.toLowerCase() === (value.currency)));
      }
    });

    return () => subscription.unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, allowedTokens]);

  return (
    <div
      className={`${styles.wrapper} relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${
        isFocused ? "before:border-white/20" : "before:border-transparent"
      }`}
    >
      <div className="font-sans text-[13px] text-gray-custom leading-[1.2] tracking-[-0.02em]">
        Buy
      </div>

      <div className="flex flex-row flex-wrap justify-between items-center mt-2">
        <div
          className={`flex flex-1 ${
            isLogin ? "flex-col flex-wrap justify-start items-start" : ""
          }`}
        >
          <input
            name="amount"
            type="number"
            step="any"
            className={cn(
              'field__buy relative flex-auto bg-transparent opacity-100 p-0 border-none w-0 max-h-11 text-4xl text-ellipsis text-left text-white placeholder:text-[#6F737E] transition-opacity duration-[250ms] overflow-hidden pointer-events-auto ease-[ease-in-out] outline-none',
              isLogin ? "w-full" : ""
            )}
            placeholder="0"
            defaultValue={value}
            readOnly={true}
            value={value}
            // onChange={(e) => onChange(Number(e.target.value) || 0)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            {...register('amount')}
            // onChange={(event) => {
            //   register('amount').onChange(event);
            //   // setValue('token_amount', Number(event.target.value));
            //   onChange(Number(event.target.value) || 0);
            // }}
          />

          {isLogin && currentToken && (
            <div className="mt-[6px] font-sans text-[13px] text-white/70 leading-[1.2] tracking-[-0.02em]">
              <FormattedNumber value={displayValue} decimals={6} /> {currentToken.symbol.toUpperCase()}
            </div>
          )}
        </div>

        <div
          className={cn(
            isLogin ? "flex flex-col justify-start items-end" : ""
          )}
        >
          <ListTokens readOnly={readOnly} currentIssuer={currentIssuer} tokenBalances={tokenBalances} />

          {isLogin && currentToken && (
            <div className="mt-[6px] w-full font-sans text-[13px] text-white/70 text-right leading-[1.2] tracking-[-0.02em]">
              Balance: &nbsp;
              {balanceAfterTopup && (
                <>
                  <FormattedNumber value={balanceAfterTopup} decimals={6} /> {currentToken.symbol.toUpperCase()}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="m-0 min-w-0 font-bold text-[#6F737E] text-sm tracking-[-0.01em]">
        {/* 100 EURO */}
      </div>
    </div>
  );
};

export default FieldBuy;
